import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Subject } from 'rxjs/Subject';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { Client, Frame, Message } from 'stompjs';
import { CONSTANTS } from '../shared/constants/endpoints.constants';
import { DataExtract } from '../shared/constants/helper';
import { NotificationList } from './authentication.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private badgeNotification = new Subject<NotificationList>();
  newBadgeNotificationReceived$ = this.badgeNotification.asObservable();
  stompClient: Client;

  constructor(private http: HttpClient, private dataExtract: DataExtract) {}

  login(params: object) {
    const url = CONSTANTS.ENDPOINTS.LOGIN;
    return this.http.post(url, params).map(this.dataExtract.extractData);
  }

  requestForgetPassword(params: object) {
    const url = CONSTANTS.ENDPOINTS.RESETPASSREQ;
    return this.http.post(url, params).map(this.dataExtract.extractData);
  }

  finishForgetPassword(params: object) {
    const url = CONSTANTS.ENDPOINTS.RESETPASSFINISH;
    return this.http.post(url, params).map(this.dataExtract.extractData);
  }

  getPermissions() {
    const url = CONSTANTS.ENDPOINTS.USERS + '/permissions';
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  notificationCredentials(params: object) {
    return this.http.post(CONSTANTS.ENDPOINTS.USER_CREDENTIALS, params).map(this.dataExtract.extractData);
  }

  getNotifications(page, size) {
    const params = new HttpParams().append('page', page).append('size', size);
    const url = CONSTANTS.ENDPOINTS.USERNOTIFICATION;
    return this.http.get(url, { params: params }).map(this.dataExtract.extractData);
  }

  initializeWebSocketConnection(userId: number) {
    this.stompClient = Stomp.over(new SockJS(CONSTANTS.ENDPOINTS.WEBSOCKETNOTIFICATION));
    this.stompClient.connect('', '', (frame: Frame) => {
      this.stompClient.subscribe('/user/' + userId + '/queue/notify', (message: Message) => {
        this.onMessage(message);
      });
    });
  }

  private onMessage(message: Message) {
    this.badgeNotification.next(JSON.parse(message.body));
  }

  updateUserPassword(params: object) {
    const url = CONSTANTS.ENDPOINTS.USERS + '/changePassword';
    return this.http.put(url, params).map(this.dataExtract.extractData);
  }

  updateUserNotificationAsRead(id) {
    return this.http.put(CONSTANTS.ENDPOINTS.USERNOTIFICATION + '/' + id + '/read', null).map(this.dataExtract.extractData);
  }

  markAllUserNotificationAsRead() {
    return this.http.put(CONSTANTS.ENDPOINTS.USERNOTIFICATION + '/readAll', null).map(this.dataExtract.extractData);
  }
}
