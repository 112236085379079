import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs/Subscription';
import {
  CheckInsuranceType,
  PatientInsuranceMessage,
  SingleAttorneySettings,
  SingleCompanySettings,
  SingleInsuranceCarrierGroupSettings,
  SingleInsuranceTypeSettings,
  SingleInsuredRelationshipSettings,
  SingleStateSettings
} from '../../shared/constants/common.constant';
import { Button, DatePickerConstant, MultipleDropDownConst } from '../../shared/constants/message.constants';
import { SuccessMsg } from '../../shared/constants/success.constants';
import {
  Attorney,
  Insurance,
  InsuranceCarrier,
  InsuranceCarrierGroup,
  InsuranceTypeList,
  InsuredRelationship,
  PatientInsuranceInformation,
  State
} from '../case-management.model';
import { CaseManagementService } from '../case-management.service';

@Component({
  selector: 'app-primary-insurance-create',
  templateUrl: './primary-insurance-create.component.html'
})
export class PrimaryInsuranceCreateComponent implements OnInit, OnDestroy {
  saveButton = Button.save;
  cancelButton = Button.cancel;
  @Input() insuranceDetail: Insurance;
  @Input() stateList: State[];
  @Input() attorneyList: Attorney[];
  @Input() insuranceCarrierGroupList: InsuranceCarrierGroup[];
  @Input() insuredRelationshipList: InsuredRelationship[];
  @Input() existingInsuranceList: Insurance[];
  @Output() notifyAfterAddUpdate: EventEmitter<Insurance> = new EventEmitter<Insurance>();
  @Output() closeModal: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  singleCompanySettings = SingleCompanySettings;
  singleInsuranceTypeSettings = SingleInsuranceTypeSettings;
  singleStateSettings = SingleStateSettings;
  singleAttorneySettings = SingleAttorneySettings;
  checkInsuranceType = CheckInsuranceType;
  singleInsuranceCarrierGroupSettings = SingleInsuranceCarrierGroupSettings;
  singleInsuredRelationshipSettings = SingleInsuredRelationshipSettings;
  companyList: InsuranceCarrier[] = [];
  insuranceTypeList: InsuranceTypeList[] = [];
  isPolicyClaimNumberExist = false;
  policyClaimNumberBackup: string = null;
  duplicatePolicyNumber = SuccessMsg.duplicatePolicyNumber;
  patientInsuranceMessage = PatientInsuranceMessage;
  selectInsuranceCarrierGroup = MultipleDropDownConst.selectInsuranceCarrierGroup;
  selectInsuranceType = MultipleDropDownConst.selectInsuranceType;
  selectInsuranceCarrier = MultipleDropDownConst.selectCompany;
  selectState = MultipleDropDownConst.selectState;
  selectAttorney = MultipleDropDownConst.selectAttorney;
  selectInsuredRelationship = MultipleDropDownConst.selectInsuredRelationship;
  subscriptionManager = new Subscription();
  bsConfig: Partial<BsDatepickerConfig> = DatePickerConstant.bsConfig;

  constructor(private caseService: CaseManagementService) {}

  ngOnInit() {
    if (!this.insuranceDetail.insuranceCarrierGroupId) {
      this.insuranceDetail = new Insurance();
      this.policyClaimNumberBackup = null;
    } else {
      if (this.insuranceDetail.patientInsuranceInformation !== null) {
        if (this.insuranceDetail.patientInsuranceInformation.dateOfAccident !== null) {
          setTimeout(() => {
            this.insuranceDetail.patientInsuranceInformation.dateOfAccident = new Date(
              this.insuranceDetail.patientInsuranceInformation.dateOfAccident
            );
          });
        }
      }
      if (this.insuranceDetail.policyNumber) {
        this.policyClaimNumberBackup = _.clone(this.insuranceDetail.policyNumber);
      }
      this.getInsuranceTypeByInsuranceCarrierGroupId(this.insuranceDetail.insuranceCarrierGroupId, true);
    }
  }

  onSelectInsuranceType(event) {
    this.insuranceDetail.insuranceTypeId = event.id;
    this.insuranceDetail.insuranceTypeName = event.name;
    this.checkConditionForInsuranceType();
  }

  onDeSelectInsuranceType() {
    this.removeInsuranceType();
    this.removePatientInsuranceInformation();
    this.removeInsuranceCarrier();
  }

  addPrimaryInsurance() {
    this.notifyAfterAddUpdate.emit(this.insuranceDetail);
  }

  closeAddInsuranceModal() {
    this.closeModal.emit(true);
  }

  getInsuranceCarrierByInsuranceCarrierGroupIdAndInsruanceTypeId(insuranceCarrierGroupId, insuranceTyepId, isUpdateFirstTime) {
    this.subscriptionManager.add(
      this.caseService.getInsuranceCarrierByInsruanceTypeId(insuranceCarrierGroupId, insuranceTyepId).subscribe(res => {
        this.companyList = res;
        if (!isUpdateFirstTime) {
          this.removeInsuranceCarrier();
        }
        if (this.companyList.length === 1) {
          this.insuranceDetail.insuranceCarrierId = this.companyList[0].id;
          this.insuranceDetail.insuranceCarrierName = this.companyList[0].insuranceName;
        }
      })
    );
  }

  onSelectInsuranceCarrierGroup(event) {
    this.insuranceDetail.insuranceCarrierGroupId = event.id;
    this.insuranceDetail.insuranceCarrierGroupName = event.name;
    this.removeInsuranceType();
    this.removeInsuranceCarrier();
    this.removePatientInsuranceInformation();
    this.getInsuranceTypeByInsuranceCarrierGroupId(this.insuranceDetail.insuranceCarrierGroupId, false);
  }

  onDeSelectInsuranceCarrierGroup() {
    this.removeInsuranceCarrierGroup();
    this.removeInsuranceCarrier();
    this.removeInsuranceType();
    this.removePatientInsuranceInformation();
  }

  getInsuranceTypeByInsuranceCarrierGroupId(insuranceCarrierGroupId, isUpdateFirstTime) {
    if (insuranceCarrierGroupId !== null) {
      this.subscriptionManager.add(
        this.caseService.getInsuranceTypeByInsuranceCarrierGroupId(insuranceCarrierGroupId).subscribe(res => {
          this.insuranceTypeList = res;
          if (isUpdateFirstTime) {
            if (this.insuranceDetail.insuranceTypeId) {
              this.getInsuranceCarrierByInsuranceCarrierGroupIdAndInsruanceTypeId(
                this.insuranceDetail.insuranceCarrierGroupId,
                this.insuranceDetail.insuranceTypeId,
                true
              );
            }
          } else {
            if (this.insuranceTypeList.length === 1) {
              this.insuranceDetail.insuranceTypeId = this.insuranceTypeList[0].id;
              this.insuranceDetail.insuranceTypeName = this.insuranceTypeList[0].name;
              this.checkConditionForInsuranceType();
            }
          }
        })
      );
    }
  }

  checkConditionForInsuranceType() {
    this.getInsuranceCarrierByInsuranceCarrierGroupIdAndInsruanceTypeId(
      this.insuranceDetail.insuranceCarrierGroupId,
      this.insuranceDetail.insuranceTypeId,
      false
    );
    if (this.checkInsuranceType.indexOf(this.insuranceDetail.insuranceTypeName) > -1) {
      this.insuranceDetail.patientInsuranceInformation = new PatientInsuranceInformation();
    } else {
      this.removePatientInsuranceInformation();
    }
  }

  onSelectInsuranceCarrier(event) {
    this.insuranceDetail.insuranceCarrierId = event.id;
    this.insuranceDetail.insuranceCarrierName = event.insuranceName;
  }

  removeInsuranceCarrierGroup() {
    this.insuranceDetail.insuranceCarrierGroupId = null;
    this.insuranceDetail.insuranceCarrierGroupName = null;
  }

  removeInsuranceCarrier() {
    this.insuranceDetail.insuranceCarrierId = null;
    this.insuranceDetail.insuranceCarrierName = null;
  }

  removePatientInsuranceInformation() {
    this.insuranceDetail.patientInsuranceInformation = null;
  }

  onSelectState(event) {
    this.insuranceDetail.patientInsuranceInformation.stateId = event.id;
    this.insuranceDetail.patientInsuranceInformation.stateName = event.name;
  }

  onDeSelectState() {
    this.insuranceDetail.patientInsuranceInformation.stateId = null;
    this.insuranceDetail.patientInsuranceInformation.stateName = null;
  }

  onSelectAttorney(event) {
    this.insuranceDetail.patientInsuranceInformation.attorneyId = event.id;
    this.insuranceDetail.patientInsuranceInformation.attorneyName = event.name;
  }

  onDeSelectAttorney() {
    this.insuranceDetail.patientInsuranceInformation.attorneyId = null;
    this.insuranceDetail.patientInsuranceInformation.attorneyName = null;
  }

  onSelectInsuredRelationship(event) {
    this.insuranceDetail.insuredRelationshipId = event.id;
    this.insuranceDetail.insuredRelationshipName = event.name;
  }

  onDeSelectInsuredRelationship() {
    this.insuranceDetail.insuredRelationshipId = null;
    this.insuranceDetail.insuredRelationshipName = null;
  }

  removeInsuranceType() {
    this.insuranceDetail.insuranceTypeId = null;
    this.insuranceDetail.insuranceTypeName = null;
  }

  checkPolicyClaimNumber() {
    if (this.insuranceDetail.policyNumber && this.insuranceDetail.policyNumber !== this.policyClaimNumberBackup) {
      if (this.existingInsuranceList.find(existingInsurance => existingInsurance.policyNumber === this.insuranceDetail.policyNumber)) {
        this.isPolicyClaimNumberExist = true;
      } else {
        this.isPolicyClaimNumberExist = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
