import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AnmCaseDiagnosisCode } from '../case-management.model';
import { CaseManagementService } from '../case-management.service';

@Component({
  selector: 'app-diagnosis-code',
  templateUrl: './diagnosis-code.component.html'
})
export class DiagnosisCodeComponent implements OnInit, OnDestroy {
  private _anmCaseDiagnosisCodes: AnmCaseDiagnosisCode[] = [];
  subscriptionManager = new Subscription();
  searchDiagnosisCode: string;
  diagnosisCodeList: AnmCaseDiagnosisCode[] = [];
  selectedDiagnosisCodeList: AnmCaseDiagnosisCode[] = [];
  searchSubscribe$ = new Subject<string>();
  debounceTime = 1000;
  charLength = 3;

  constructor(private caseService: CaseManagementService) {}

  ngOnInit() {
    this.searchSubscribe$.pipe(debounceTime(this.debounceTime), distinctUntilChanged()).subscribe(name => {
      if (name !== null && name.length >= this.charLength) {
        this.getDiagnosisCodeByCodeOrName(name);
      }
    });
  }

  getDiagnosisCodeByCodeOrName(name) {
    this.subscriptionManager.add(
      this.caseService.getDiagnosisCodeByCodeOrName(name).subscribe(res => {
        this.diagnosisCodeList = res;
      })
    );
  }

  onSelectDiagnosisCode(event) {
    this.anmCaseDiagnosisCodes.push(event);
  }

  onDeSelectDiagnosisCode(event) {
    this.anmCaseDiagnosisCodes.splice(this.anmCaseDiagnosisCodes.indexOf(event.value.diagnosisCodeId), 1);
  }

  @Input()
  set anmCaseDiagnosisCodes(diagnosisCodeList: AnmCaseDiagnosisCode[]) {
    this._anmCaseDiagnosisCodes = diagnosisCodeList;
    this.selectedDiagnosisCodeList = _.clone(diagnosisCodeList);
  }

  get anmCaseDiagnosisCodes() {
    return this._anmCaseDiagnosisCodes;
  }

  clear() {
    const length = this.anmCaseDiagnosisCodes.length;
    for (let i = 0; i < length; i++) {
      this.anmCaseDiagnosisCodes.splice(this.anmCaseDiagnosisCodes.indexOf(this.anmCaseDiagnosisCodes[i]), 1);
    }
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
