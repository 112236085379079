import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttorneyLayoutComponent } from '../layouts/attorney-layout/attorney-layout.component';
import { PageTitle } from '../shared/constants/message.constants';
import { Permission } from '../shared/constants/permission.constant';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { CaseCreateComponent } from './case-create/case-create.component';
import { CaseListComponent } from './case-list/case-list.component';

const routes: Routes = [
  {
    path: '',
    component: AttorneyLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'case-list',
        component: CaseListComponent,
        data: { header: PageTitle.case_list, pageTitle: PageTitle.case_list, permission: Permission.view_case }
      },
      {
        path: 'case-create',
        component: CaseCreateComponent,
        data: { header: PageTitle.case_create, pageTitle: PageTitle.case_create, permission: Permission.add_case }
      },
      {
        path: 'case-edit/:caseId',
        component: CaseCreateComponent,
        data: { header: PageTitle.case_update, pageTitle: PageTitle.case_update, permission: Permission.update_case }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CaseManagementRoutingModule {}
