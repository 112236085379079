<form class="form-horizontal" #addPrimaryInsuranceForm="ngForm">
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-11 col-md-11">
        <div class="form-group">
          <div class="row">
            <label class="col-lg-4 col-md-4 col-sm-3 col-form-label text-right"
              >{{ patientInsuranceMessage.carrierGroup }}
              <small class="mandatory"></small>
            </label>
            <div class="col-lg-7 col-md-8 col-sm-9">
              <ng-select
                [items]="insuranceCarrierGroupList"
                bindLabel="name"
                bindValue="id"
                (change)="onSelectInsuranceCarrierGroup($event)"
                (clear)="onDeSelectInsuranceCarrierGroup()"
                name="insuranceCarrierGroup"
                #insuranceCarrierGroup="ngModel"
                placeholder="{{ selectInsuranceCarrierGroup }}"
                [(ngModel)]="insuranceDetail.insuranceCarrierGroupId"
                required
              >
              </ng-select>
              <app-err-msg [control]="insuranceCarrierGroup" fieldName="{{ patientInsuranceMessage.carrierGroup }}"></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="insuranceDetail.insuranceCarrierGroupId">
          <div class="row">
            <label class="col-lg-4 col-md-4 col-sm-3 col-form-label text-right">{{ patientInsuranceMessage.payorType }} </label>
            <div class="col-lg-7 col-md-8 col-sm-9">
              <ng-select
                [items]="insuranceTypeList"
                bindLabel="name"
                bindValue="id"
                (change)="onSelectInsuranceType($event)"
                (clear)="onDeSelectInsuranceType()"
                name="insuranceType"
                placeholder="{{ selectInsuranceType }}"
                [(ngModel)]="insuranceDetail.insuranceTypeId"
              >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="form-group" *ngIf="insuranceDetail.insuranceTypeId">
          <div class="row">
            <label class="col-lg-4 col-md-4 col-sm-3 col-form-label text-right">{{ patientInsuranceMessage.payorName }} </label>
            <div class="col-lg-7 col-md-8 col-sm-9">
              <ng-select
                [items]="companyList"
                bindLabel="insuranceName"
                bindValue="id"
                (change)="onSelectInsuranceCarrier($event)"
                (clear)="removeInsuranceCarrier()"
                name="companyList"
                placeholder="{{ selectInsuranceCarrier }}"
                [(ngModel)]="insuranceDetail.insuranceCarrierId"
              >
                <ng-template ng-label-tmp let-item="item">
                  <span>{{ item | insuranceCarrierAddress }}</span>
                </ng-template>

                <ng-template ng-option-tmp let-item="item">
                  <span>{{ item | insuranceCarrierAddress }}</span>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-lg-4 col-md-4 col-sm-3 col-form-label text-right">{{ patientInsuranceMessage.policyClaimNumber }} </label>
            <div class="col-lg-7 col-md-8 col-sm-9">
              <input
                type="text"
                class="form-control"
                name="policyNumber"
                #policyNumber="ngModel"
                [(ngModel)]="insuranceDetail.policyNumber"
                maxLength="24"
                (blur)="checkPolicyClaimNumber()"
              />
              <app-err-msg [control]="policyNumber" fieldName="Policy Number"></app-err-msg>
              <p *ngIf="isPolicyClaimNumberExist" class="text-center label-danger">{{ duplicatePolicyNumber }}</p>
            </div>
          </div>
        </div>
        <div *ngIf="insuranceDetail.patientInsuranceInformation != null">
          <div class="form-group">
            <div class="row">
              <label class="col-lg-4 col-md-4 col-sm-3 col-form-label text-right">{{ patientInsuranceMessage.dateOfAccident }} </label>
              <div class="col-lg-7 col-md-8 col-sm-9">
                <input
                  type="text"
                  class="form-control w-100"
                  name="dateOfAccident"
                  bsDatepicker
                  [(ngModel)]="insuranceDetail.patientInsuranceInformation.dateOfAccident"
                  autocomplete="off"
                  [bsConfig]="bsConfig"
                />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-lg-4 col-md-4 col-sm-3 col-form-label text-right">{{ patientInsuranceMessage.stateOfAccedent }} </label>
              <div class="col-lg-7 col-md-8 col-sm-9">
                <ng-select
                  [items]="stateList"
                  bindLabel="name"
                  bindValue="id"
                  (change)="onSelectState($event)"
                  (clear)="onDeSelectState()"
                  name="stateOfAccident"
                  placeholder="{{ selectState }}"
                  [(ngModel)]="insuranceDetail.patientInsuranceInformation.stateId"
                >
                </ng-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row">
              <label class="col-lg-4 col-md-4 col-sm-3 col-form-label text-right">{{ patientInsuranceMessage.attorney }} </label>
              <div class="col-lg-7 col-md-8 col-sm-9">
                <ng-select
                  [items]="attorneyList"
                  bindLabel="name"
                  bindValue="id"
                  (change)="onSelectAttorney($event)"
                  (clear)="onDeSelectAttorney()"
                  name="attorney"
                  placeholder="{{ selectAttorney }}"
                  [(ngModel)]="insuranceDetail.patientInsuranceInformation.attorneyId"
                >
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-lg-4 col-md-4 col-sm-3 col-form-label text-right"
              >{{ patientInsuranceMessage.insuredRelationship }}
              <small class="mandatory"></small>
            </label>
            <div class="col-lg-7 col-md-8 col-sm-9">
              <ng-select
                [items]="insuredRelationshipList"
                bindLabel="name"
                bindValue="id"
                (change)="onSelectInsuredRelationship($event)"
                (clear)="onDeSelectInsuredRelationship()"
                name="insuredRelationship"
                placeholder="{{ selectInsuredRelationship }}"
                [(ngModel)]="insuranceDetail.insuredRelationshipId"
                #insuredRelationship="ngModel"
                required
              >
              </ng-select>
              <app-err-msg [control]="insuredRelationship" fieldName="{{ patientInsuranceMessage.insuredRelationship }}"></app-err-msg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      [disabled]="addPrimaryInsuranceForm.form.invalid || isPolicyClaimNumberExist"
      class="btn btn-sm btn-success"
      data-toggle="modal"
      data-dismiss="modal"
      (click)="addPrimaryInsurance()"
    >
      {{ saveButton }}
    </button>
    <button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-dismiss="modal" (click)="closeAddInsuranceModal()">
      {{ cancelButton }}
    </button>
  </div>
</form>
