import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Insurance, State, Attorney, InsuranceTypeList, InsuredRelationship, InsuranceCarrierGroup, CarrierCategory, InsuranceCarrier } from '../case-management.model';
import { PatientInsuranceTabConst } from '../../shared/constants/common.constant';

@Component({
  selector: 'app-patient-insurance-carrier-tab',
  templateUrl: './patient-insurance-carrier-tab.component.html'
})
export class PatientInsuranceCarrierTabComponent implements OnInit {

  @Input() insuranceDetail: Insurance;
  @Input() stateList: State[];
  @Input() attorneyList: Attorney[];
  @Input() insuranceTypeList: InsuranceTypeList[];
  @Input() insuredRelationshipList: InsuredRelationship[];
  @Input() isAdd: Boolean;
  @Input() existingInsuranceList: Insurance[];
  @Input() insuranceCarrierGroupList: InsuranceCarrierGroup[];
  @Input() carrierCategoryList: CarrierCategory[];
  @Output() notifyAfterAddUpdate: EventEmitter<Insurance> = new EventEmitter<Insurance>();
  @Output() closeModal: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() notifyAfterNewAddInsuranceGroup: EventEmitter<Insurance> = new EventEmitter<Insurance>();

  addInsuranceCarrierDetail: InsuranceCarrier = new InsuranceCarrier();
  patientInsuranceTabConst = PatientInsuranceTabConst.patientInsuranceTab;
  addInsuranceCarrierTabConst = PatientInsuranceTabConst.addInsuranceCaeeierTab;
  addPatientInsuranceInformationConst = PatientInsuranceTabConst.addPatientInsuranceInformation;
  updatePatientInsuranceInformationConst = PatientInsuranceTabConst.updatePatientInsuranceInformation;
  addInsuranceCarrierInformationConst = PatientInsuranceTabConst.addInsuranceCarrierInformation;
  addPatientInsuranceConst = PatientInsuranceTabConst.addPatientInsurance;
  updatePatientInsuranceConst = PatientInsuranceTabConst.updatePatientInsurance;
  tab = PatientInsuranceTabConst.patientInsuranceTab;

  constructor() { }

  ngOnInit() {
    if (this.isAdd) {
      this.showModal();
    } else {
      setTimeout(() => {
        this.showModal();
      }, 800);
    }
  }

  showTab(tab) {
    this.tab = tab;
  }

  showModal() {
    document.getElementById('openPatientInsuranceUpdateModalButton').click();
  }

  closePatientInsuranceModal() {
    this.closeModal.emit(true);
  }

  notifyAfterPatientInsuranceAddUpdate(event) {
    this.notifyAfterAddUpdate.emit(event);
  }

  getAllInsuranceCarrierGroup(event) {
    this.notifyAfterNewAddInsuranceGroup.emit(event);
  }
}
