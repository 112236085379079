<div class="row border-bottom">
  <nav class="navbar navbar-static-top white-bg" role="navigation">
    <div class="navbar-header innerNavHeader">
      <a class="navbar-minimalize minimalize-styl-2 btn btn-primary toggleButton" (click)="toggleSidebar()">
        <em class="fa fa-bars"></em>
      </a>
      <span>{{ headerTitle }}</span>
    </div>
  </nav>
</div>
