import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { NotificationList, NotificationPage } from '../../authentication/authentication.model';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NotificationWithPage } from '../../shared/constants/common.constant';
import { Button } from '../../shared/constants/message.constants';
import { SharedService } from '../../shared/services/shared.service';
import { StorageService } from '../../shared/services/storage.service';

@Component({
  selector: 'app-notification-sidepanel',
  templateUrl: './notification-sidepanel.component.html'
})
export class NotificationSidepanelComponent implements OnInit, OnDestroy {
  notificationWithPage = NotificationWithPage;
  loadMoreButton = Button.loadMore;
  subscriptionManager = new Subscription();
  notificationIbox = 'notificationIbox';
  notificationPage: NotificationPage = new NotificationPage();

  @Output() notificationCount = new EventEmitter();

  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly authService: AuthenticationService,
    private readonly sharedService: SharedService
  ) {}

  ngOnInit() {
    this.notificationPage = this.storageService.getObject(this.notificationWithPage);
    this.storeAndEmitNotificationCount();
    this.subscribeToNotification();
  }

  subscribeToNotification() {
    this.subscriptionManager.add(
      this.authService.newBadgeNotificationReceived$.subscribe(notification => {
        this.notificationPage.notificationList.splice(0, 0, notification);
        this.notificationPage.unReadCount = this.notificationPage.unReadCount + 1;
        this.storeAndEmitNotificationCount();
      })
    );
  }

  getNotifications(event) {
    event.stopPropagation();
    this.sharedService.showIndividualLoader(this.notificationIbox);
    this.notificationPage.pageNumber = this.notificationPage.pageNumber + 1;
    this.subscriptionManager.add(
      this.authService.getNotifications(this.notificationPage.pageNumber, this.notificationPage.size).subscribe(res => {
        this.sharedService.removeIndividualLoader(this.notificationIbox);
        if (res.userNotifications !== null && res.userNotifications.length > 0) {
          if (res.userNotifications.length < 10) {
            this.notificationPage.isMoreNotification = false;
          }
          this.notificationPage.notificationList = this.notificationPage.notificationList.concat(res.userNotifications);
        } else {
          this.notificationPage.isMoreNotification = false;
        }
        this.storeAndEmitNotificationCount();
      })
    );
  }

  updateUserNotificationAsRead(notification: NotificationList) {
    if (!notification.isRead) {
      this.sharedService.showIndividualLoader(this.notificationIbox);
      this.subscriptionManager.add(
        this.authService.updateUserNotificationAsRead(notification.id).subscribe(res => {
          this.sharedService.removeIndividualLoader(this.notificationIbox);
          notification.isRead = true;
          this.notificationPage.unReadCount = this.notificationPage.unReadCount - 1;
          this.storeAndEmitNotificationCount();
        })
      );
    }
  }

  markAllUserNotificationAsRead() {
    this.sharedService.showIndividualLoader(this.notificationIbox);
    this.subscriptionManager.add(
      this.authService.markAllUserNotificationAsRead().subscribe(res => {
        this.sharedService.removeIndividualLoader(this.notificationIbox);
        this.notificationPage.notificationList
          .filter(notification => notification.isRead === false)
          .forEach(notification => {
            notification.isRead = true;
          });
        this.notificationPage.unReadCount = 0;
        this.storeAndEmitNotificationCount();
      })
    );
  }

  storeAndEmitNotificationCount() {
    this.storageService.setObject(this.notificationWithPage, this.notificationPage);
    this.notificationCount.emit(this.notificationPage.unReadCount);
  }

  ngOnDestroy() {
    this.storageService.setObject(this.notificationWithPage, this.notificationPage);
    this.subscriptionManager.unsubscribe();
  }
}
