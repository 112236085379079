<div class="ibox-title sub-header">
  <div class="row">
    <div class="col-sm-7 child-left">
      Patient Insurance Information
    </div>
    <div class="col-sm-5">
      <div class="row">
        <div class="col-sm-7 regionMultiselect">
          <angular2-multiselect
            [data]="remainingPatientInsuranceList"
            name="selectedInsurance"
            [(ngModel)]="tempPatientInsuranceList"
            [settings]="multipleInsuranceSettings"
            (onSelect)="onSelectRemainingPatientInsurance($event)"
            *ngIf="isDragEnable && remainingPatientInsuranceList.length > 0"
          >
            <c-badge>
              <ng-template let-item="item">
                <label>{{ item | patientInsurance }}</label>
              </ng-template>
            </c-badge>
            <c-item>
              <ng-template let-item="item">
                <label class="custom-template-dropdown">{{ item | patientInsurance }}</label>
              </ng-template>
            </c-item>
          </angular2-multiselect>
        </div>
        <div class="col-sm-5">
          <button
            class="btn btn-success btn-sm float-right mb-0"
            type="button"
            (click)="openAddUpdatePatientInformationDetailModal(null, null)"
          >
            {{ addPatientInsurance }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ibox-content clearfix">
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <table
        class="table table-striped table-hover m-b-40"
        aria-describedby="Patient Insurance List"
        *ngIf="isDragEnable ? selectedInsuranceList?.length > 0 : patientInsurances?.length > 0"
      >
        <thead class="tableHeader">
          <tr>
            <th scope="col">No</th>
            <th scope="col">{{ patientInsuranceMessage?.policyClaimNumber }}</th>
            <th scope="col">{{ patientInsuranceMessage?.payorType }}</th>
            <th scope="col">{{ patientInsuranceMessage?.carrierGroup }}</th>
            <th scope="col">{{ patientInsuranceMessage?.payorName }}</th>
            <th scope="col">{{ patientInsuranceMessage?.dateOfAccident }}</th>
            <th scope="col">{{ patientInsuranceMessage?.stateOfAccedent }}</th>
            <th scope="col">{{ patientInsuranceMessage?.attorney }}</th>
            <th scope="col">{{ patientInsuranceMessage?.insuredRelationship }}</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody dnd-sortable-container [sortableData]="selectedInsuranceList">
          <tr
            *ngFor="let insurance of isDragEnable ? selectedInsuranceList : patientInsurances; let i = index"
            dnd-droppable
            dnd-sortable
            [sortableIndex]="i"
            [dragEnabled]="isDragEnable"
            [dragData]="insurance"
          >
            <td>
              <span *ngIf="isDragEnable"> <em class="fa fa-bars"></em> </span>{{ i + 1 }}
            </td>
            <td>{{ insurance?.policyNumber }}</td>
            <td>{{ insurance?.insuranceTypeName }}</td>
            <td>{{ insurance?.insuranceCarrierGroupName }}</td>
            <td>{{ insurance?.insuranceCarrierName }}</td>
            <td>{{ insurance?.patientInsuranceInformation?.dateOfAccident | date: monthDateAndHour }}</td>
            <td>{{ insurance?.patientInsuranceInformation?.stateName | notAssigned }}</td>
            <td>{{ insurance?.patientInsuranceInformation?.attorneyName | notAssigned }}</td>
            <td>{{ insurance?.insuredRelationshipName }}</td>
            <td>
              <button class="btn btn-success btn-circle" type="button" (click)="openAddUpdatePatientInformationDetailModal(insurance, i)">
                <em class="fa fa-pencil"></em>
              </button>
              <button class="btn btn-danger btn-circle" type="button" (click)="removeSelectedInsurance(insurance, i)">
                <em class="fa fa-times"></em>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="text-center" *ngIf="isDragEnable ? selectedInsuranceList.length === 0 : patientInsurances.length === 0">
        <h3 class="m-b-xxs">No data found!</h3>
        <small>No Patient Insurance here.</small>
      </div>
    </div>
  </div>
</div>

<app-patient-insurance-carrier-tab
  [insuranceDetail]="addUpdatePatientInsuranceDetail"
  [existingInsuranceList]="patientInsurances"
  [stateList]="stateList"
  [attorneyList]="attorneyList"
  [insuranceTypeList]="insuranceTypeList"
  [insuredRelationshipList]="insuredRelationshipList"
  [isAdd]="isAdd"
  [insuranceCarrierGroupList]="insuranceCarrierGroupList"
  [carrierCategoryList]="carrierCategoryList"
  (notifyAfterAddUpdate)="notifyAfterPatientInsuranceAddUpdate($event)"
  (closeModal)="closeModal()"
  (notifyAfterNewAddInsuranceGroup)="getInsuranceCarrierGroup()"
  *ngIf="openAddUpdatePatientInformationModal"
></app-patient-insurance-carrier-tab>
