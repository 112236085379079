import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getDurationOfProcedure'
})
export class GetDurationOfProcedurePipe implements PipeTransform {

  transform(dateOfSurgery: Date, durationOfProcedure: Date): Date {
    const tempDateOfSurgery = new Date(dateOfSurgery);
    const tempDurationOfProcedure = new Date(durationOfProcedure);
    tempDurationOfProcedure.setHours(tempDurationOfProcedure.getHours() - tempDateOfSurgery.getHours(),
      tempDurationOfProcedure.getMinutes() - tempDateOfSurgery.getMinutes());
    return tempDurationOfProcedure;
  }

}
