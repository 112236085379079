import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findById'
})
export class FindByIdPipe implements PipeTransform {

  transform(id: number, value: any, property: string): any {
    return value.find(object => object.id === id)[property];
  }

}
