import { Pipe, PipeTransform } from '@angular/core';
import { Insurance } from '../../case-management/case-management.model';

@Pipe({
  name: 'patientInsurance'
})
export class PatientInsurancePipe implements PipeTransform {

  transform(insurance: Insurance): string {
    let combineString = '';
    if (insurance) {
      if (insurance.policyNumber) {
        combineString += insurance.policyNumber;
      }
      if (insurance.insuranceCarrierGroupName) {
        combineString += (combineString ? '|' : '') + insurance.insuranceCarrierGroupName;
      }
      if (insurance.insuranceTypeName) {
        combineString += (combineString ? '|' : '') + insurance.insuranceTypeName;
      }
    }
    return combineString;
  }

}
