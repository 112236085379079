import { DefaultInsuredRelationship } from '../shared/constants/common.constant';

export class Case {
  constructor(
    public id?: number,
    public surgeryDate?: string,
    public surgeryTime?: string,
    public surgeryAmpm?: string,
    public dateOfSurgery: Date = new Date(),
    public dateOfSurgeryString?: string,
    public actualDateOfSurgery: Date = new Date(),
    public durationOfProcedure?: string,
    public daylight?: boolean,
    public procedure?: number,
    public procedureDescription?: string,
    public dxDescription?: string,
    public hospitalId?: number,
    public doctorId: number = null,
    public anmCaseProcedureTypes: AnmCaseProcedureTypes[] = [],
    public anmCaseModalities: AnmCaseModalities[] = [],
    public anmCaseChangedModalities: AnmCaseChangedModalities[] = [],
    public anmCasePatientInsurances: AnmCasePatientInsurance[] = [],
    public anmCaseDiagnosisCodes: AnmCaseDiagnosisCode[] = [],
    public patient?: Patient,
    public hospitalName?: string,
    public doctorName?: string,
    public readerId?: number,
    public techId?: number,
    public status?: string,
    public caseNo?: number,
    public readerName?: string,
    public techName?: string,
    public otherModality?: string,
    public patientInTime: Date = null,
    public patientOutTime: Date = null,
    public iomStartTime: Date = null,
    public iomStopTime: Date = null,
    public traineeTechId?: number,
    public traineeTechName?: string,
    public competenceId: number = null,
    public competenceName?: string,
    public travelTime?: string,
    public delayTime?: string,
    public patientInOut = null,
    public dateOfAdmission?: string,
    public isScheduledEmergency = false,
    public technicalPrecertRequest?: PrecertAttachment,
    public professionalPrecertRequest?: PrecertAttachment,
    public technicalPrecertResponse?: PrecertAttachment,
    public professionalPrecertResponse?: PrecertAttachment,
    public primaryInsuranceTypeId?: number,
    public medicalRecordNo?: string,
    public precertNumber?: string,
    public isPreCert?: boolean,
    public isApproved?: boolean,
    public operatingRoomId?: number,
    public operatingRoomName?: string,
    public roomNumber?: string,
    public cancelReason?: string,
    public isTBD: boolean = false,
    public note?: string,
    public patientEmploymentStatusId: number = null,
    public patientEmploymentStatusName?: string,
    public regionName?: string,
    public practiceId: number = null,
    public practiceName?: string,
    public locationId: number = null,
    public locationName?: string,
    public timezoneId: number = null,
    public timezoneWithOffset?: string,
    public responsiblePartyId?: number,
    public authorizationNumber?: string,
    public caseStatus?: string,
    public patientStatus?: string,
    public payerStatus?: string,
    public medicalQuestionResponses = new MedicalQuestionResponses(),
    public justificationQuestions = new JustificationQuestions()
  ) {}
}

export class MedicalQuestionResponses {
  constructor(
    public isBornPremature: string = null,
    public bornPrematureExplanation?: string,
    public isTakingMedication: string = null,
    public takingMedicationExplanation?: string,
    public isHavingCoughOrCold: string = null,
    public havingAllergies: string = null,
    public explanationForAllergy?: string,
    public isAnyProcedure: string = null,
    public procedureExplanation?: string,
    public anyComplicationOrConcern?: string
  ) {}
}

export class JustificationQuestions {
  constructor(
    public clinicalDiagnosis?: string,
    public justificationForDental?: string,
    public dispositionOfChild?: string,
    public justificationForAnesthesia?: string,
    public goal?: string
  ) {}
}
export class AddUser {
  constructor(
    public firstName?: string,
    public lastName?: string,
    public phoneMobile?: string,
    public phoneHome?: string,
    public phoneWork: string = null,
    public address1?: string,
    public address2: string = null,
    public city?: string,
    public state?: object,
    public postalCode?: string,
    public email?: string,
    public employmentType: string = null,
    public workDays = [],
    public activated = true,
    public authorityList = [],
    public competenceList = [],
    public callRegionId?: string,
    public cnmiCertified: boolean = false,
    public contractor?: boolean
  ) {}
}

export class AnmCaseProcedureTypes {
  constructor(public id?: number, public anmCaseId?: number, public procedureTypeId?: number, public name?: string) {}
}

export class ResponsibleParty {
  constructor(public id?: number, public name?: string) {}
}

export class AnmCaseModalities {
  constructor(
    public id?: number,
    public anmCaseId?: number,
    public modalitieId?: number,
    public name?: string,
    public selected?: boolean
  ) {}
}

export class AnmCaseChangedModalities {
  constructor(
    public id?: number,
    public anmCaseId?: number,
    public changedModalityId?: number,
    public note?: string,
    public dataRecovery: string = 'NONE',
    public name?: string,
    public isDataRecovery?: boolean,
    public selected?: boolean,
    public isChangedInModality?: boolean
  ) {}
}

export class CaseHospitalDoctor {
  constructor(public id: number, public name: string, public timezoneOffset: string, public itemName?: string) {}
}

export class HospitalDoctorListDto {
  constructor(
    public id: number,
    public itemName?: string,
    public name?: string,
    public timezoneOffset?: string,
    public hospitalTimezoneWithOffset?: string
  ) {}
}

export class SelectedModalitieList {
  constructor(public modalitieId?: number, public itemName?: string, public selected?: boolean) {}
}

export class RegionListCase {
  constructor(public id?: number, public name?: string) {}
}

export class CaseReaderList {
  constructor(public id?: number, public name?: string) {}
}

export class CaseTechByRegion {
  constructor(public id?: number, public name?: string, public regionName?: string) {}
}

export class CaseTechByRegionForMultipleDropDown {
  constructor(public regionName?: string, public tech: CaseTechByRegion[] = []) {}
}

export class CaseProcedureInformation {
  constructor(
    public caseId?: number,
    public procedure?: string,
    public dxDescription?: string,
    public competenceId: number = null,
    public competenceName?: string,
    public anmCaseModalities: AnmCaseModalities[] = []
  ) {}
}

export class MonitoringSupplies {
  constructor(
    public id?: number,
    public partId?: number,
    public anmCaseId?: number,
    public name?: string,
    public quantity?: number,
    public selected?: boolean
  ) {}
}

export class AddAttachement {
  constructor(public multipartFiles?: any, public tags: string[] = []) {}
}

export class CaseStatusChangeDto {
  constructor(public id?: number, public status?: string) {}
}

export class CaseByDateAndRegionDto {
  constructor(public regions: RegionListCase[] = [], public currentDate: Date = new Date(), public date?: string) {}
}

export class Competence {
  constructor(public id?: number, public name?: string, public description?: string, public isActive?: boolean) {}
}

export class CaseModalityInformation {
  constructor(
    public caseId?: number,
    public otherModality?: string,
    public anmCaseChangedModalities: AnmCaseChangedModalities[] = [],
    public selectOtherModality = false,
    public isChangedInModality?: boolean
  ) {}
}

export class CaseProcedureTypeInformation {
  constructor(public caseId?: number, public anmCaseProcedureTypes: AnmCaseProcedureTypes[] = []) {}
}

export class CaseProcedureInformationWithType {
  constructor(
    public caseId?: number,
    public procedure?: string,
    public dxDescription?: string,
    public anmCaseProcedureTypes: AnmCaseProcedureTypes[] = [],
    public patientInOut = null
  ) {}
}

export class CalendarViewCaseTech {
  constructor(public id?: number, public name?: string, public itemName?: string) {}
}

export class AssignCaseTech {
  constructor(
    public caseId?: number,
    public hospitalId?: number,
    public techId?: number,
    public traineeTechId?: number,
    public name?: string
  ) {}
}

export class AssignCaseExcludeTechReason {
  constructor(
    public surgeonExName?: string,
    public hospitalExName?: string,
    public hospitalReason?: string,
    public surgeonReason?: string
  ) {}
}

export class AssignTechToCase {
  constructor(public caseId?: number, public techId?: number) {}
}

export class AssignTraineeTechToCase {
  constructor(public caseId?: number, public traineeTechId?: number) {}
}

export class FileAttachments {
  constructor(
    public id?: number,
    public fileName?: string,
    public fileType?: string,
    public filePath?: string,
    public tagName?: string,
    public tagId?: number,
    public anmCaseId: number = null,
    public multipartFiles?: any
  ) {}
}

export class TagList {
  constructor(public id?: number, public name?: string) {}
}

export class AnmCasePatientInsurance {
  constructor(
    public id?: number,
    public type?: string,
    public anmCaseId?: number,
    public patientInsuranceId?: number,
    public patientInsuranceDTO: Insurance = new Insurance(),
    public sequence?: number,
    public policyNumber?: string
  ) {}
}

export class AnmCaseDiagnosisCode {
  constructor(
    public id?: number,
    public anmCaseId?: number,
    public diagnosisCodeId?: number,
    public diagnosisCodeValue?: string,
    public name?: string,
    public selected?: boolean,
    public displayName?: string
  ) {}
}

export class CaseAssignDiagnosisCode {
  constructor(public caseId?: number, public anmCaseDiagnosisCodes: AnmCaseDiagnosisCode[] = []) {}
}

export class ProcedureInformationDetail {
  constructor(
    public anmCaseId?: number,
    public diagnosisCodes: AnmCaseDiagnosisCode[] = [],
    public patientInOut?: string,
    public procedure?: string,
    public procudureType: AnmCaseProcedureTypes[] = []
  ) {}
}

export class PrecertAttachment {
  constructor(public attachmentId?: number, public fileName?: string, public filePath?: string) {}
}

export class CallRegion {
  constructor(public id?: number, public itemName?: string, public selected?: boolean, public name?: string) {}
}

export class Patient {
  constructor(
    public id?: number,
    public email?: string,
    public firstName?: string,
    public lastName?: string,
    public middleName?: string,
    public address1?: string,
    public address2?: string,
    public city?: string,
    public stateId?: string,
    public postal?: string,
    public phone?: string,
    public workPhone?: string,
    public homePhone?: string,
    public dateOfBirth?: string,
    public ssn?: string,
    public gender: string = 'NONE',
    public maritalStatus: string = 'NONE',
    public employmentStatus?: string,
    public patientInsurances: Insurance[] = [],
    public insuredRelationship?: string,
    public patientEmploymentStatusId = null,
    public height?: string,
    public weight?: string,
    public guardianName?: string,
    public guardianNumber?: string
  ) {}
}

export class Insurance {
  constructor(
    public id: number = null,
    public policyNumber = null,
    public primary: boolean = false,
    public disable = false,
    public insuranceTypeId?: number,
    public insuranceCarrierId?: number,
    public carrierList: InsuranceCarrier[] = [],
    public insuranceTypeName?: string,
    public insuranceCarrierName?: string,
    public payorTypeList: InsuranceTypeList[] = [],
    public insuranceCarrierGroupId?: number,
    public insuranceCarrierGroupName?: string,
    public insuredRelationshipId = DefaultInsuredRelationship.id,
    public insuredRelationshipName = DefaultInsuredRelationship.name,
    public patientInsuranceInformation: PatientInsuranceInformation = null
  ) {}
}

export class State {
  id: number;
  name: string;
  code: string;
}

export class PatientInsuranceInformation {
  constructor(
    public id?: number,
    public dateOfAccident: Date = null,
    public stateId: number = null,
    public attorneyId: number = null,
    public patientInsuranceId?: number,
    public stateName?: string,
    public attorneyName?: string
  ) {}
}

export class InsuranceTypeList {
  constructor(public id?: number, public name?: string) {}
}

export class InsuranceCarrier {
  constructor(
    public id?: number,
    public insuranceName?: string,
    public carrierNumber?: number,
    public address1?: string,
    public address2?: string,
    public city?: string,
    public stateId: number = null,
    public postalCode?: number,
    public web?: string,
    public phone1?: string,
    public phone2?: string,
    public fax?: string,
    public insuranceTypeId: number = null,
    public insuranceTypeName?: string,
    public insuranceCarrierGroupId?: number,
    public insuranceCarrierGroupName?: string,
    public stateName?: string,
    public isVerified: boolean = false,
    public carrierCategoryId?: number,
    public carrierCategoryName?: string
  ) {}
}

export class Attorney {
  constructor(
    public id?: number,
    public name?: string,
    public email?: string,
    public phone?: string,
    public fax?: string,
    public webAddress?: string,
    public address1?: string,
    public address2?: string,
    public city?: string,
    public stateId: number = 0,
    public zipcode?: string,
    public contactPersonFirstName?: string,
    public contactPersonLastName?: string,
    public contactPersonEmail?: string,
    public contactPersonPhone?: string
  ) {}
}

export class SearchFilter {
  constructor(
    public caseId = '',
    public fromDate = '',
    public toDate = '',
    public patientFirstName = '',
    public patientLastName = '',
    public status = [],
    public procedureIds = [],
    public practiceId: number = null,
    public locationIds = []
  ) {}
}

export class CaseOperatingRoomList {
  constructor(public id?: number, public name?: string, public phone?: string, public note?: string, public hospitalId?: number) {}
}

export class SearchDTO {
  constructor(public id?: number, public name?: string, public itemName?: string, public selected?: boolean) {}
}

export class PatientEmploymentStatus {
  constructor(public id?: number, public name?: string) {}
}

export class InsuranceCarrierGroup extends SearchDTO {
  constructor() {
    super();
  }
}

export class InsuredRelationship extends SearchDTO {
  constructor() {
    super();
  }
}

export class CarrierCategory extends SearchDTO {
  constructor() {
    super();
  }
}

export class Tag {
  constructor(public id?: number, public name?: string, public required?: boolean) {}
}

export class UnassignCases {
  constructor(
    public id?: number,
    public dateOfSurgery: Date = new Date(),
    public procedure?: string,
    public hospitalId?: number,
    public doctorId?: number,
    public patientId?: number,
    public hospitalName?: string,
    public doctorName?: string,
    public patientName?: string,
    public status?: string,
    public caseNo?: number,
    public competenceId?: number,
    public competenceName?: string
  ) {}
}

export class AnmCaseList extends UnassignCases {
  constructor(
    public techId?: number,
    public techName?: string,
    public delayTime?: string,
    public isScheduledEmergency?: boolean,
    public isPreCert?: boolean,
    public isApproved?: boolean,
    public physicianReport?: string,
    public operatingRoomName?: string
  ) {
    super();
  }
}

export class TimeZone {
  constructor(
    public id?: number,
    public offset?: string,
    public offsetTime?: string,
    public name?: string,
    public abbreviateName?: string,
    public timezoneWithOffset?: string
  ) {}
}
