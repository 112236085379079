import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Case } from '../../case-management/case-management.model';

@Injectable()
export class DataService {

  private systemId = new BehaviorSubject<string>('');
  currentSystemId = this.systemId.asObservable();

  private caseDetail = new BehaviorSubject<Case>(new Case());
  currentcaseDetail = this.caseDetail.asObservable();

  constructor() { }
  changeSystemId(systemId: string) {
    this.systemId.next(systemId);
  }

  changecaseDetail(caseDetail) {
    this.caseDetail.next(caseDetail);
  }

}
