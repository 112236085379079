import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AddUser } from '../../case-management/case-management.model';
import { CaseManagementService } from '../../case-management/case-management.service';
import { Button } from '../../shared/constants/message.constants';
import { Permission } from '../../shared/constants/permission.constant';
import { SharedService } from '../../shared/services/shared.service';
import { StorageService } from '../../shared/services/storage.service';
import { UtillService } from '../../shared/services/utill.service';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html'
})
export class TopNavbarComponent implements OnInit, OnDestroy {
  add_case = Permission.add_case;
  view_case = Permission.view_case;
  add_patient = Permission.add_patient;
  view_patient = Permission.view_patient;
  view_schedularDashboard = Permission.view_schedularDashboard;
  view_billingDashboard = Permission.view_billingDashboard;
  view_attorney = Permission.view_attorney;
  saveButton = Button.save;
  cancelButton = Button.cancel;
  updateOncallScheduleModalValue = false;
  subscriptionManager = new Subscription();
  openMenu = false;
  profileDetail: AddUser;
  updatePasswordModal = false;
  isTutorialModal = false;
  notificationCount = 0;

  constructor(
    private router: Router,
    private utillService: UtillService,
    private sharedService: SharedService,
    private authService: AuthenticationService,
    private storageService: StorageService,
    private caseService: CaseManagementService
  ) {}

  ngOnInit() {
    this.profileDetail = this.storageService.getAccountData();
    if (this.profileDetail === null) {
      this.getProfileInfo();
    } else {
      this.trimUserName();
    }
  }

  trimUserName() {
    if (this.profileDetail) {
      this.profileDetail.firstName = this.profileDetail.firstName.replace(/\s/g, '');
      this.profileDetail.lastName = this.profileDetail.lastName.replace(/\s/g, '');
    }
  }

  getProfileInfo() {
    this.subscriptionManager.add(
      this.caseService.getUserDetail().subscribe(res => {
        this.profileDetail = res;
        this.storageService.setAccountData(this.profileDetail);
        this.profileDetail = this.storageService.getAccountData();
        this.trimUserName();
      })
    );
  }

  getPermission(permission) {
    return this.utillService.getPermissions(permission);
  }

  logout() {
    this.sharedService.logout();
    this.storageService.clearLocalStorage();
    this.router.navigate(['/']);
  }

  openUpdatePasswordModal() {
    if (this.updatePasswordModal) {
      this.updatePasswordModal = false;
      this.sharedService.closeFileModal('updatePassword');
    } else {
      this.updatePasswordModal = true;
    }
  }

  openTutorialModal() {
    if (this.isTutorialModal) {
      this.isTutorialModal = false;
    } else {
      this.isTutorialModal = true;
    }
  }

  updateNotificationCount(count: number) {
    this.notificationCount = count;
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
