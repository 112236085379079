import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import {
  InsuranceCarrierMessage,
  PatientInsuranceMessage,
  SingleInsuranceTypeSettings,
  SingleStateSettings,
  TypeAheadDropDownLimit,
  ZipCode
} from '../../shared/constants/common.constant';
import { Button, MultipleDropDownConst } from '../../shared/constants/message.constants';
import { SuccessMsg } from '../../shared/constants/success.constants';
import { SharedService } from '../../shared/services/shared.service';
import { CarrierCategory, InsuranceCarrier, InsuranceTypeList, State } from '../case-management.model';
import { CaseManagementService } from '../case-management.service';

@Component({
  selector: 'app-insurance-carrier-create-modal',
  templateUrl: './insurance-carrier-create-modal.component.html'
})
export class InsuranceCarrierCreateModalComponent implements OnInit, OnDestroy {
  @Input() stateList: State[];
  insuranceTypeList: InsuranceTypeList[];
  @Input() isRequiredInformation: boolean;
  @Input() addInsuranceCarrierDetail: InsuranceCarrier;
  carrierCategoryList: CarrierCategory[];
  @Output() closeModal: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() notifyAfterNewAddInsuranceGroup: EventEmitter<Boolean> = new EventEmitter<Boolean>();
  @Output() notifyAfterAddUpdateInsuranceCarrier: EventEmitter<InsuranceCarrier> = new EventEmitter<InsuranceCarrier>();

  saveButton = Button.save;
  cancelButton = Button.cancel;
  singleInsuranceTypeSettings = SingleInsuranceTypeSettings;
  singleStateSettings = SingleStateSettings;
  selectedInsuranceType: InsuranceTypeList[] = [];
  selectedState: State[] = [];
  insuranceCarrierGroupNameList: string[] = [];
  typeAheadDropDownLimit = TypeAheadDropDownLimit;
  zipCode = ZipCode;
  selectState = MultipleDropDownConst.selectState;
  selectInsuranceType = MultipleDropDownConst.selectInsuranceType;
  selectCarrierCategory = MultipleDropDownConst.selectCarrierCategory;
  patientInsuranceMessage = PatientInsuranceMessage;
  insuranceCarrierMessage = InsuranceCarrierMessage;
  subscriptionManager = new Subscription();

  constructor(private caseService: CaseManagementService, private sharedService: SharedService) {}

  ngOnInit() {
    this.getAllInsuranceCarrierGroup();
    this.getInsuranceType();
    this.getAllCarrierCategory();
  }

  addInsuranceCarrier(form) {
    this.sharedService.showLoader();
    if (this.addInsuranceCarrierDetail.id) {
      this.addInsuranceCarrierDetail.isVerified = true;
      this.subscriptionManager.add(
        this.caseService.updateInsuranceCarrier(this.addInsuranceCarrierDetail).subscribe(
          res => {
            this.checkAndAddInsuranceCarrierGroup(res, form);
            this.sharedService.successToaster(SuccessMsg.insuranceCarrierUpdate);
          },
          error => {
            this.sharedService.removeLoader();
          }
        )
      );
    } else {
      this.subscriptionManager.add(
        this.caseService.createInsuranceCarrier(this.addInsuranceCarrierDetail).subscribe(
          res => {
            this.checkAndAddInsuranceCarrierGroup(res, form);
            this.notifyAfterAddUpdateInsuranceCarrier.emit(res);
            this.sharedService.successToaster(SuccessMsg.insuranceCarrierCreate);
          },
          error => {
            this.sharedService.removeLoader();
          }
        )
      );
    }
  }

  closeAddInsuranceCarrierModal() {
    this.closeModal.emit(true);
  }

  // Get Insurance Type
  getInsuranceType() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getInsuranceType().subscribe(res => {
        this.sharedService.removeLoader();
        this.insuranceTypeList = res;
      })
    );
  }

  // Get all carrier categorys
  getAllCarrierCategory() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getCarrierCategory().subscribe(res => {
        this.sharedService.removeLoader();
        this.carrierCategoryList = res;
      })
    );
  }

  getAllInsuranceCarrierGroup() {
    this.subscriptionManager.add(
      this.caseService.getAllInsuranceCarrierGroupName().subscribe(res => {
        this.sharedService.removeLoader();
        this.insuranceCarrierGroupNameList = res;
      })
    );
  }

  onSelectState(event) {
    this.addInsuranceCarrierDetail.stateId = event.id;
    this.addInsuranceCarrierDetail.stateName = event.name;
  }

  onDeSelectState() {
    this.addInsuranceCarrierDetail.stateId = null;
    this.addInsuranceCarrierDetail.stateName = null;
  }

  onSelectInsuranceType(event) {
    this.addInsuranceCarrierDetail.insuranceTypeId = event.id;
    this.addInsuranceCarrierDetail.insuranceTypeName = event.name;
  }

  onDeSelectInsuranceType() {
    this.addInsuranceCarrierDetail.insuranceTypeId = null;
    this.addInsuranceCarrierDetail.insuranceTypeName = null;
  }

  onSelectCarrierCategory(event) {
    this.addInsuranceCarrierDetail.carrierCategoryId = event.id;
    this.addInsuranceCarrierDetail.carrierCategoryName = event.name;
  }

  onDeSelectCarrierCategory() {
    this.addInsuranceCarrierDetail.carrierCategoryId = null;
    this.addInsuranceCarrierDetail.carrierCategoryName = null;
  }

  checkAndAddInsuranceCarrierGroup(res, form: FormGroup) {
    if (!(this.insuranceCarrierGroupNameList.indexOf(this.addInsuranceCarrierDetail.insuranceCarrierGroupName) > -1)) {
      this.insuranceCarrierGroupNameList.push(this.addInsuranceCarrierDetail.insuranceCarrierGroupName);
      this.notifyAfterNewAddInsuranceGroup.emit(true);
    }
    this.addInsuranceCarrierDetail.id = res.id;
    this.notifyAfterAddUpdateInsuranceCarrier.emit(this.addInsuranceCarrierDetail);
    if (this.isRequiredInformation) {
      document.getElementById('cancelButton').click();
    } else {
      form.reset();
      this.addInsuranceCarrierDetail = new InsuranceCarrier();
    }
    this.sharedService.removeLoader();
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
