import { Pipe, PipeTransform } from '@angular/core';
import { ShowCaseStatusList, CompletedStatus } from '../constants/common.constant';

@Pipe({
  name: 'showCaseStatus'
})
export class ShowCaseStatusPipe implements PipeTransform {

  transform(value: string): string {
    if (value) {
      if (ShowCaseStatusList.indexOf(value) > -1) {
        return value;
      } else {
        return CompletedStatus;
      }
    }
    return 'NA';
  }

}
