<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <span class="slider-titlebar slider-title">
      Welcome to ZinniaX!
    </span>
    <carousel [interval]="false">
      <slide *ngFor="let slide of tutorialData">
        <h3>{{slide.heading}}</h3>
        <p [innerHTML]="slide.description"></p>
        <img [src]="slide.img" alt="first slide" class="carousel-image">
      </slide>
    </carousel>
    <div class="modal-footer">
      <button class="btn btn-success btn-sm" data-toggle="modal" data-dismiss="modal" type="button" (click)="closeTutorialModal()">
        Got it!</button>
    </div>
  </div>
</div>
