import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild
} from '@angular/router';
import { SharedService } from './shared.service';
import { UtillService } from './utill.service';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AuthGuardService {

  constructor(
    private sharedService: SharedService,
    private utillService: UtillService,
    private router: Router
  ) { }

  canActivate(): boolean {
    if (this.sharedService.isLoggedIn()) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }

}


@Injectable()
export class NonAuthGuardService {

  constructor(
    private sharedService: SharedService,
    private router: Router
  ) { }


  canActivate(): boolean {
    if (this.sharedService.isLoggedIn() === false) {
      return true;
    }
    this.router.navigate(['/']);
    return false;
  }

}


@Injectable()
export class AdminGuardService implements CanActivateChild {

  constructor(
    private utillService: UtillService,
    private router: Router
  ) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.utillService.getPermissions(route.data['permission'])) {
      return true;
    } else {
      this.router.navigate(['/unauthorise']);
      return false;
    }
  }
}
