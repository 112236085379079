export class TableConfig {
  public static items_per_page = 25;
  public static default_sort_order = 'asc';
  public static default_sort_order_desc = 'desc';
  public static default_sort_by = 'name';
  public static sort_by_firstname = 'firstName';
  public static sort_by_teamName = 'teamName';
  public static sortByDescription = 'description';
  public static sort_by_city = 'city';
  public static sort_by_state = 'state';
  public static activePage = 1;
  public static sort_by_dateOfSurgery = 'dateOfSurgery';
  public static pageSizeOptions = [25, 50, 100, 150, 200];
}
