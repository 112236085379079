<body class="white-bg">
  <div class="logo margintop-20 img-responsive middle-box text-center inline-block animated fadeInDown">
    <img src="../../../../assets/img/logo/Zinniax-Logo.png" />
  </div>
  <div class="middle-box text-center loginscreen animated fadeInDown">
    <h2 class="font-size-15em">404</h2>
    <h2>
      <strong>
        You've come to a blank wall.
      </strong>
    </h2>
    <h3>
      The page
      <em>{{ router.url }}</em> was not found.
    </h3>
    <button type="submit" class="btn btn-primary margintop-30" (click)="goBack()">Go Back</button>
  </div>
</body>
