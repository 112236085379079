<div class="row border-bottom white-bg">
  <div class="innerNavHeader row">
    <div class="col-xs-10 col-sm-10 no-padding-space">
      <p class="m-b-none m-t-xs">{{ headerTitle }}</p>
    </div>
    <div class="col-xs-2 col-sm-2 no-padding-space">
      <button type="button" tooltip="Filter" placement="bottom" class="btn btn-link btn-sm float-right" (click)="toggleFilters()">
        <em class="fa fa-filter right-sidebar-icon"></em>
      </button>
      <button type="button" class="btn btn-success btn-sm miniHeaderButton" routerLink="/case-create" *ngIf="getPermission(add_case)">
        {{ addButton }}
      </button>
    </div>
  </div>
</div>
<div class="wrapper wrapper-content myWrapper">
  <div class="row">
    <div class="col-sm-12 mt-2" [ngClass]="{ 'p-r-300': showFiltersSection }">
      <div class="ibox float-e-margins m-l-xs m-r-xs" id="ibox">
        <div class="ibox-content no-borders table-responsive" id="no-more-tables">
          <app-spinner></app-spinner>
          <table
            class="table table-striped table-hover no-margin-space"
            [mfData]="cases"
            #mf="mfDataTable"
            [(mfSortBy)]="sortBy"
            [(mfSortOrder)]="sortOrder"
            (mfSortOrderChange)="onSortOrder($event)"
            *ngIf="cases?.length"
            aria-describedby="Case List"
          >
            <thead class="tableHeader">
              <tr>
                <th scope="col">
                  <a>
                    <span>
                      <mfDefaultSorter by="caseNo">Case #</mfDefaultSorter>
                    </span>
                  </a>
                </th>
                <th scope="col">
                  <a>
                    <span>
                      <mfDefaultSorter by="dateOfSurgery">Date Of Surgery</mfDefaultSorter>
                    </span>
                  </a>
                </th>
                <th scope="col">
                  <a>
                    <span>
                      <mfDefaultSorter by="patientName">Patient Name</mfDefaultSorter>
                    </span>
                  </a>
                </th>
                <th scope="col">
                  <a>
                    <span>
                      <mfDefaultSorter by="practiceName">Practice</mfDefaultSorter>
                    </span>
                  </a>
                </th>
                <th scope="col">
                  <a>
                    <span> <mfDefaultSorter by="location.address1">location</mfDefaultSorter></span>
                  </a>
                </th>
                <th scope="col">
                  <a>
                    <span>
                      <mfDefaultSorter by="procedure">Procedure</mfDefaultSorter>
                    </span>
                  </a>
                </th>
                <th scope="col">
                  <a>
                    <span>
                      <mfDefaultSorter by="status">Status</mfDefaultSorter>
                    </span>
                  </a>
                </th>
                <th scope="col">
                  <a>
                    <span>Action</span>
                  </a>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let case of mf?.data; let i = index">
                <td data-title="Case #">
                  {{ case?.caseNo }}
                </td>
                <td data-title="Date Of Surgery">{{ case?.dateOfSurgery | tbdTime: case?.isTBD }}</td>
                <td data-title="Patient Name">
                  {{ case?.patientName | notAssigned }}
                </td>
                <td data-title="Practice">
                  {{ case?.practiceName }}
                </td>
                <td data-title="Location">
                  {{ case | location }}
                </td>
                <td data-title="Procedure">{{ getProcedureName(case?.procedure) }}</td>
                <td data-title="Status">
                  <span class="fs-13" [ngClass]="case?.status | caseStatusColor">{{ getCaseStatus(case) }}</span>
                </td>
                <td data-title="Action">
                  <div class="btn-group dropdown" *ngIf="getPermission(update_case) && case?.status">
                    <button data-toggle="dropdown" class="btn btn-success btn-xs dropdown-toggle no-margin-space" aria-expanded="false">
                      Action
                    </button>
                    <ul class="dropdown-menu c-menu">
                      <li>
                        <a routerLink="/case-edit/{{ case?.id }}" *ngIf="getPermission(update_case) && case?.status"> {{ editButton }}</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="10">
                  <div class="row">
                    <div class="col-sm-4 col-md-4 col-xs-12">
                      <div class="results-label">
                        <span>Showing {{ fromPage }} to {{ toPage }} of {{ totalItems }} entries</span>
                      </div>
                    </div>
                    <div class="ccol-sm-8 col-md-8 col-xs-12">
                      <div class="row">
                        <div class="col pr-2 d-flex flex-row-reverse">
                          <pagination
                            appItemsPerPage
                            (pageChanged)="handlePaginationEvent($event)"
                            [itemsPerPage]="itemsPerPage"
                            [totalItems]="totalItems"
                            [rotate]="true"
                            [maxSize]="5"
                            [(ngModel)]="activePage"
                            [boundaryLinks]="true"
                            [itemsPerPageOptions]="itemPerPageOptions"
                            (itemsPerPageChanged)="handlePageOptionsChange($event)"
                            [directionLinks]="false"
                            firstText="&laquo;"
                            lastText="&raquo;"
                          >
                          </pagination>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
          <div class="ibox-content no-borders text-center" *ngIf="!cases?.length">
            <h3 class="m-b-xxs">No data found!</h3>
            <small>No Cases here.</small>
          </div>
        </div>
      </div>
    </div>

    <div id="filterRightSidePanel" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2" [hidden]="!showFiltersSection">
      <div class="filter-dialog" role="sidebar">
        <div class="modal-content">
          <div class="modal-header align-items-center">
            <h3>Filters</h3>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeFiltersSection()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form #searchCaseFilterForm="ngForm">
            <div class="modal-body">
              <div class="col-sm-12">
                <div class="col-md-12 no-padding-space">
                  <div class="form-group">
                    <label class="col-form-label">Procedure date </label>
                    <div>
                      <input
                        class="form-control dateRange"
                        #drp="bsDaterangepicker"
                        autocomplete="off"
                        bsDaterangepicker
                        [(ngModel)]="bsRangeValue"
                        name="bsRangeValue"
                        [bsConfig]="bsConfig"
                        placeholder="Date range"
                        placement="left"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-sm-12">
                  <label class="row col-form-label">
                    Patient First Name
                  </label>
                  <div class="row">
                    <input type="text" class="form-control" name="patientNameFilter" [(ngModel)]="searchFilter.patientFirstName" />
                  </div>
                </div>
                <div class="col-sm-12">
                  <label class="row col-form-label">
                    Patient Last Name
                  </label>
                  <div class="row">
                    <input type="text" class="form-control" name="patientNameFilter" [(ngModel)]="searchFilter.patientLastName" />
                  </div>
                </div>
                <div class="col-md-12 no-padding-space">
                  <div class="form-group">
                    <label class="col-form-label">Practice </label>
                    <div>
                      <select
                        class="form-control"
                        name="pracitces"
                        [(ngModel)]="searchFilter.practiceId"
                        [disabled]="getPermission(surgeon_call)"
                        (change)="getLocationsByPracticeId(searchFilter.practiceId)"
                      >
                        <option [ngValue]="null">Select Practice</option>
                        <option *ngFor="let practice of practiceList" [ngValue]="practice?.id">{{ practice?.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 no-padding-space">
                  <div class="form-group">
                    <label class="col-form-label">Location </label>
                    <div>
                      <angular2-multiselect
                        [data]="locationList"
                        name="locations"
                        [(ngModel)]="selectedLocation"
                        [settings]="locationSelect"
                        (onDeSelectAll)="selectedLocation = []"
                      >
                        <c-badge>
                          <ng-template let-item="item">
                            <label>{{ item | location }}</label>
                          </ng-template>
                        </c-badge>
                        <c-item>
                          <ng-template let-item="item">
                            <span class="custom-template-dropdown font-weight-bold">{{ item | location }}</span>
                          </ng-template>
                        </c-item>
                      </angular2-multiselect>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 no-padding-space">
                  <div class="form-group">
                    <label class="col-form-label">Case # </label>
                    <div>
                      <input class="form-control" type="number" name="caseId" [(ngModel)]="searchFilter.caseId" min="0" />
                    </div>
                  </div>
                </div>

                <div class="col-md-12 no-padding-space">
                  <div class="form-group">
                    <label class="col-form-label">Procedure Type </label>
                    <div>
                      <angular2-multiselect
                        [data]="procedures"
                        name="procedures"
                        [(ngModel)]="selectedProcedures"
                        [settings]="procedureMultiSelect"
                        (onDeSelectAll)="selectedProcedures = []"
                      >
                      </angular2-multiselect>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 no-padding-space">
                  <div class="form-group">
                    <label class="col-form-label">Case Status </label>
                    <div>
                      <select class="form-control" name="status" #statusInput="ngModel" [(ngModel)]="filterstatus">
                        <option value="">Select Status</option>
                        <option *ngFor="let status of caseStatus" [ngValue]="status?.name">{{ status?.name }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-sm btn-success m-b-0" (click)="searchCaseFilter(defaultPage)">{{ searchButton }}</button>
              <button class="btn btn-sm btn-white m-b-0" (click)="resetFilter()">{{ resetButton }}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
