<body class="top-navigation pace-done">
  <div id="wrapper">
    <div id="page-wrapper" class="white-bg welcomeHeight">
      <div class="row border-bottom white-bg">
        <app-top-navbar></app-top-navbar>
      </div>

      <body class="pace-done middlePart" id="middle">
        <div id="wrapper">
          <div class="white-bg dashbard-1">
            <div class="row white-bg dashboard-header" style="text-align:center;">
              <h3>Welcome to ZinniaX</h3>
            </div>
            <app-footer></app-footer>
          </div>
        </div>
      </body>
    </div>
  </div>
</body>