import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'location'
})
export class LocationPipe implements PipeTransform {
  transform(location): unknown {
    let tempString = '';
    if (location) {
      if (location.address1) {
        tempString += location.address1 ? location.address1 : '';
      }
      if (location.address2) {
        tempString += tempString ? ', ' + location.address2 : '';
      }
      if (location.city) {
        tempString += tempString ? ', ' + location.city : '';
      }
      if (location.stateName) {
        tempString += tempString ? ', ' + location.stateName : '';
      }
      if (location.zipCode) {
        tempString += tempString ? ', ' + location.zipCode : '';
      }
    }
    return tempString;
  }
}
