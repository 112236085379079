import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment-timezone';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs/Observable';
import { ERRORCODES } from './constants/error.constant';
import { SharedService } from './services/shared.service';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private sharedService: SharedService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.sharedService.getUserData('id_token');
    let authRequest = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    if (authToken) {
      authRequest = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken).set('User-Timezone', moment.tz.guess())
      });
    } else {
      authRequest = req.clone();
    }
    return next
      .handle(authRequest)
      .do(event => {
        if (event instanceof HttpResponse) {
          this.requestSuccess(event);
        }
      })
      .catch((error: HttpErrorResponse) => {
        if (
          error.error.applicationErrorCode !== undefined &&
          error.error.applicationErrorCode !== null &&
          error.error.applicationErrorCode in ERRORCODES
        ) {
          this.sharedService.dangerToaster(ERRORCODES[error.error.applicationErrorCode]);
          if (error.error.applicationErrorCode === 1000) {
            this.router.navigate(['login']);
          }
        } else {
          this.sharedService.dangerToaster(error.message);
        }
        return Observable.throw(error);
      });
  }

  private requestSuccess(res: HttpResponse<any>) {
    return res;
  }
}
