import { Pipe, PipeTransform } from '@angular/core';
import { UpdateCaseStatusList } from '../constants/common.constant';

@Pipe({
  name: 'confirmedStatus'
})
export class ConfirmedStatusPipe implements PipeTransform {

  transform(value: string): boolean {
    if (value && UpdateCaseStatusList.indexOf(value) > -1) {
      return true;
    }
    return false;
  }

}
