import { Component, OnInit, EventEmitter, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../authentication.service';
import { SuccessMsg } from '../../shared/constants/success.constants';
import { Button } from '../../shared/constants/message.constants';
import { Subscription } from 'rxjs/Subscription';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})

export class ChangePasswordComponent implements OnInit, OnDestroy {
  saveButton = Button.save;
  cancelButton = Button.cancel;
  @Output() openUpdatePasswordModal = new EventEmitter();
  oldPassword: string;
  password: string;
  confirmPassword: string;
  subscriptionManager = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
  }

  updateUserPassword() {
    const passwordObj = {
      oldPassword: this.oldPassword,
      password: this.password
    };
    this.subscriptionManager.add(this.authService.updateUserPassword(passwordObj).subscribe((res) => {
      this.sharedService.successToaster(SuccessMsg.userPasswordUpdate);
      this.openUpdatePasswordModal.next();
    }, (err) => {
      this.openUpdatePasswordModal.next();
    }));
  }

  callParentOpenModal() {
    this.openUpdatePasswordModal.next();
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }

}
