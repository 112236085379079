import { Attribute, Directive, ElementRef, forwardRef, HostListener, Input } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appIsNumber]'
})
export class IsNumberDirective {
  private el: HTMLInputElement;
  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent>event;

    if (
      [46, 8, 9, 27, 13].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }

    // Ensure that it is a number
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
}

@Directive({
  selector: '[appEmailValidate][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }]
})
export class EmailValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validateEmailFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validateEmailFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      // tslint:disable-next-line:max-line-length
      if (
        c.value.match(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
        )
      ) {
        return null;
      } else {
        return {
          appEmailValidate: true
        };
      }
    }
  };
}

@Directive({
  selector: '[appPhoneValidate][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneValidator, multi: true }]
})
export class PhoneValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validatePhoneFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validatePhoneFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      if (c.value.match(/^[89]\d{8}$/) || c.value.match(/^[6]\d{8}$/)) {
        return null;
      } else {
        return {
          appPhoneValidate: true
        };
      }
    }
  };
}

@Directive({
  selector: '[appPasswordValidate][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidator, multi: true }]
})
export class PasswordValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validatePasswordFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validatePasswordFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      if (c.value.match(/^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/)) {
        return null;
      } else {
        return {
          appPasswordValidate: true
        };
      }
    }
  };
}

@Directive({
  selector: '[appEqualvalidate][formControlName],[formControl],[ngModel]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualValidator),
      multi: true
    }
  ]
})
export class EqualValidator implements Validator {
  constructor(@Attribute('appEqualvalidate') public appEqualvalidate: string) {}

  validate(abControl: AbstractControl): { [key: string]: any } {
    // Get self value.
    const val = abControl.value;
    // Get control value.
    const cValue = abControl.root.get(this.appEqualvalidate);
    // value not equal
    if (cValue && val !== cValue.value) {
      return {
        appEqualvalidate: false
      };
    }

    return null;
  }
}

@Directive({
  selector: '[appWebValidate][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: WebValidator, multi: true }]
})
export class WebValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validateWebFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validateWebFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      // tslint:disable-next-line:max-line-length
      if (
        c.value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})/
        )
      ) {
        return null;
      } else {
        return {
          appWebValidate: true
        };
      }
    }
  };
}

@Directive({
  selector: '[appAlphaNumeric][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: AlphaNumericValidator, multi: true }]
})
export class AlphaNumericValidator implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = validateAlphaNumericFactory();
  }
  validate(c: FormControl) {
    return this.validator(c);
  }
}

// validation function
function validateAlphaNumericFactory(): ValidatorFn {
  return (c: AbstractControl) => {
    if (c.value) {
      if (c.value.match(/^\s*([0-9a-zA-Z]*)\s*$/)) {
        return null;
      } else {
        return {
          appAlphaNumeric: true
        };
      }
    }
  };
}

@Directive({
  selector: '[appInputRestriction]'
})
export class InputRestrictionDirective {
  inputElement: ElementRef;

  @Input('appInputRestriction') appInputRestriction: string;
  arabicRegex = '[\u0600-\u06FF]';

  constructor(el: ElementRef) {
    this.inputElement = el;
  }

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    if (this.appInputRestriction === 'integer') {
      this.integerOnly(event);
    } else if (this.appInputRestriction === 'noSpecialChars') {
      this.noSpecialChars(event);
    }
  }

  integerOnly(event) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    if (
      [46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && e.ctrlKey === true)
    ) {
      // let it happen, don't do anything
      return;
    }
    if (['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].indexOf(e.key) === -1) {
      e.preventDefault();
    }
  }

  noSpecialChars(event) {
    const e = <KeyboardEvent>event;
    if (e.key === 'Tab' || e.key === 'TAB') {
      return;
    }
    let k;
    k = event.keyCode; // k = event.charCode;  (Both can be used)
    if ((k > 64 && k < 91) || (k > 96 && k < 123) || k === 8 || k === 32 || (k >= 48 && k <= 57)) {
      return;
    }
    const ch = String.fromCharCode(e.keyCode);
    const regEx = new RegExp(this.arabicRegex);
    if (regEx.test(ch)) {
      return;
    }
    e.preventDefault();
  }

  @HostListener('paste', ['$event']) onPaste(event) {
    let regex;
    if (this.appInputRestriction === 'integer') {
      regex = /[0-9]/g;
    } else if (this.appInputRestriction === 'noSpecialChars') {
      regex = /[a-zA-Z0-9\u0600-\u06FF]/g;
    }
    const e = <ClipboardEvent>event;
    const pasteData = e.clipboardData.getData('text/plain');
    let m;
    let matches = 0;
    while ((m = regex.exec(pasteData)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }
      // The result can be accessed through the `m`-variable.
      m.forEach((match, groupIndex) => {
        matches++;
      });
    }
    if (matches === pasteData.length) {
      return;
    } else {
      e.preventDefault();
    }
  }
}
