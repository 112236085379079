export const ERRORCODES = {
  1000: 'Session Expired',
  1001: 'Invalid email or password',
  1002: 'Email address is not registerd',
  1003: '"Try again...","Something went wrong"',
  1005: 'Bad request',
  1006: 'Access denied',
  1007: 'Session timeout',
  1008: 'Email already exist',
  1009: 'Usergroup is already exist',
  1010: 'Permissions are not updated',
  1011: 'Parent region not found',
  1012: 'Region already exist',
  1013: 'User already available in usergroup',
  1014: 'No Call Region Found',
  1015: 'No User Group Found',
  1016: 'No Competency Found',
  1017: 'User Already Assigned In Other Team',
  1018: 'Team Already Assign To Region',
  1019: 'System already exist',
  1020: 'User Already Addes In Team',
  1021: 'Entity already exist with same Id',
  1028: 'Hospital not found for this region',
  1031: 'Vendor Can Not Delete',
  1032: 'Session is expired',
  1033: 'Location is not found',
  1034: 'Hospital is not found',
  1035: 'User is not active',
  1036: 'Operating room is not found',
  1037: 'Old password is wrong',
  1049: 'Unauthorize Person'
};
