<ng-select
  [items]="diagnosisCodeList"
  [typeahead]="searchSubscribe$"
  [multiple]="true"
  bindLabel="name"
  name="diagnosisCode"
  [(ngModel)]="selectedDiagnosisCodeList"
  placeholder="Search Diagnosis Code Here"
  [closeOnSelect]="false"
  (add)="onSelectDiagnosisCode($event)"
  (remove)="onDeSelectDiagnosisCode($event)"
  (clear)="clear()"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-label">{{ item?.diagnosisCodeValue }}</span>
    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <label class="custom-template-dropdown"> {{ item?.diagnosisCodeValue }}</label>
  </ng-template>
</ng-select>
