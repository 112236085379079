import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-welcome-layout',
  templateUrl: './welcome-layout.component.html'
})
export class WelcomeLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
