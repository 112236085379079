import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'makeDurationOfProcedure'
})
export class MakeDurationOfProcedurePipe implements PipeTransform {

  transform(dateOfSurgery: Date, durationOfProcedure: Date): Date {
    const tempDateOfSurgery = new Date(dateOfSurgery);
    let tempDurationOfProcedure = new Date(durationOfProcedure);
    tempDurationOfProcedure.setHours(tempDurationOfProcedure.getHours() % 12 || 12);
    if (tempDateOfSurgery.getDate() === tempDurationOfProcedure.getDate()) {
        tempDurationOfProcedure.setHours(tempDurationOfProcedure.getHours() + tempDateOfSurgery.getHours(),
        tempDurationOfProcedure.getMinutes() + tempDateOfSurgery.getMinutes());
    } else if (tempDateOfSurgery.getDate() < tempDurationOfProcedure.getDate()) {
        tempDurationOfProcedure = new Date(tempDurationOfProcedure.setDate(
          tempDurationOfProcedure.getDate() - (tempDurationOfProcedure.getDate() - tempDateOfSurgery.getDate())));
        tempDurationOfProcedure.setHours(tempDurationOfProcedure.getHours() + tempDateOfSurgery.getHours(),
        tempDurationOfProcedure.getMinutes() + tempDateOfSurgery.getMinutes());
    } else if (tempDateOfSurgery.getDate() > tempDurationOfProcedure.getDate()) {
        tempDurationOfProcedure = new Date(tempDurationOfProcedure.setDate(
          tempDurationOfProcedure.getDate() + (tempDateOfSurgery.getDate() - tempDurationOfProcedure.getDate())));
        tempDurationOfProcedure.setHours(tempDurationOfProcedure.getHours() + tempDateOfSurgery.getHours(),
        tempDurationOfProcedure.getMinutes() + tempDateOfSurgery.getMinutes());
    }
    return tempDurationOfProcedure;
  }

}
