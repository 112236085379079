import { DatePipe, Location } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { BsDatepickerConfig, BsDatepickerDirective } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs/Subscription';
import {
  AMPMDropDown,
  CancelCaseStatus,
  CheckInsuranceType,
  DATE_OF_ADMISSION_TITLE,
  intervalTime,
  MedicalQuestions,
  MMDDYYYY,
  monthDateAndHour,
  MultipleInsuranceSettings,
  OtherTagId,
  PatientInOutUnknown,
  PatientInsuranceMessage,
  YYYYMMDD,
  ZipCode
} from '../../shared/constants/common.constant';
import { Button, DatePickerConstant, InsuranceTypeConst, MultipleDropDownConst } from '../../shared/constants/message.constants';
import { Permission } from '../../shared/constants/permission.constant';
import { AlertWarningMessage, SuccessMsg } from '../../shared/constants/success.constants';
import { SharedService } from '../../shared/services/shared.service';
import { UtillService } from '../../shared/services/utill.service';
import {
  AnmCaseDiagnosisCode,
  AnmCasePatientInsurance,
  AnmCaseProcedureTypes,
  Attorney,
  CarrierCategory,
  Case,
  CaseOperatingRoomList,
  FileAttachments,
  Insurance,
  InsuranceCarrier,
  InsuranceCarrierGroup,
  InsuranceTypeList,
  InsuredRelationship,
  JustificationQuestions,
  MedicalQuestionResponses,
  Patient,
  PatientEmploymentStatus,
  ResponsibleParty,
  SearchDTO,
  State,
  TimeZone
} from '../case-management.model';
import { CaseManagementService } from '../case-management.service';

@Component({
  selector: 'app-case-create',
  templateUrl: './case-create.component.html'
})
export class CaseCreateComponent implements OnInit, OnDestroy {
  headerTitle: string;
  saveButton = Button.save;
  updateButton = Button.update;
  saveAndEmailButton = Button.saveAndEmail;
  cancelButton = Button.cancel;
  confirmButton = Button.confirm;
  editButton = Button.edit;
  addPatientInsurance = Button.addPatientInsurance;
  stateList: State[] = [];
  bsConfig: Partial<BsDatepickerConfig> = DatePickerConstant.bsConfig;
  addPatientInsuranceDetail: AnmCasePatientInsurance;
  selectedInsuranceType: InsuranceTypeList[] = [];
  selectedCompanyAddPrimaryInsurance: InsuranceCarrier[] = [];
  primaryInsuranceType: string = InsuranceTypeConst.primary;
  secondaryInsuranceType: string = InsuranceTypeConst.secondary;
  caseDetail: Case;
  foundPatientList: Patient[] = [];
  patientFirstName: string;
  patientLastName: string;
  foundedSelectedPatient: Patient;
  unknownPatient: Patient;
  caseId: Number;
  updateCase = false;
  unKnownPatientBoolean = false;
  success = false;
  checkInsuranceType = CheckInsuranceType;
  attorneyList: Attorney[] = [];
  diagnosisCodeList: AnmCaseDiagnosisCode[] = [];
  selectedStateOfAccident: State[] = [];
  selectedAttorney: Attorney[] = [];
  surgeon_call = Permission.surgeon_call;
  hospital_call = Permission.hospital_call;
  operatingRoomList: CaseOperatingRoomList[] = [];
  selectedOperatingRoom: CaseOperatingRoomList[] = [];
  actualDateOfSurgeryDate: Date;
  dateOfSurgeryTBD = false;
  isCreateAnotherCase = false;
  timeAMPMdropDown = AMPMDropDown;
  dateOfSurgeryList = intervalTime;
  estTimeDurationList = intervalTime;
  monthDateAndHour = monthDateAndHour;
  selectOperatingRoom = MultipleDropDownConst.selectOperatingRoom;
  selectPractice = MultipleDropDownConst.selectPractice;
  actualDateOfSurgeryTimeDropDown: string;
  actualDateOfSurgeryTimeAM_PMDropDown: string;
  patientEmploymentStatusList: PatientEmploymentStatus[] = [];
  selectedInsuranceList: Insurance[] = [];
  anmCasePatientInsurance: AnmCasePatientInsurance;
  caseFileUploadList: FileAttachments[] = [];
  tagIdList: string[] = [];
  zipCode = ZipCode;
  formData: FormData = new FormData();
  practiceList: SearchDTO[] = [];
  subscriptionManager = new Subscription();
  @ViewChild(BsDatepickerDirective) datepicker: BsDatepickerDirective;
  @ViewChild('dp') dp: BsDatepickerDirective;
  patientInOutUnknown = PatientInOutUnknown;
  cancelCaseStatus = CancelCaseStatus;
  caseStatus: string;
  dateOfAdmission: Date;
  dateOfAdmissionTitle = DATE_OF_ADMISSION_TITLE;
  oldDate: Date = null;
  carrierCategoryList: CarrierCategory[] = [];
  carrierCompanyList = {};
  carrierInsuranceTypeList = {};
  companyList: InsuranceCarrier[] = [];
  insuranceTypeList: InsuranceTypeList[] = [];
  patientInsuranceMessage = PatientInsuranceMessage;
  selectInsuranceCarrierGroup = MultipleDropDownConst.selectInsuranceCarrierGroup;
  selectInsuranceType = MultipleDropDownConst.selectInsuranceType;
  selectInsuranceCarrier = MultipleDropDownConst.selectCompany;
  selectInsuredRelationship = MultipleDropDownConst.selectInsuredRelationship;
  insuranceCarrierGroupList: InsuranceCarrierGroup[] = [];
  addInsuranceCarrierDetail: InsuranceCarrier = new InsuranceCarrier();
  insuredRelationshipList: InsuredRelationship[] = [];
  locationList = [];
  responsiblePartyList: ResponsibleParty[] = [];
  procedures: AnmCaseProcedureTypes[] = [];
  isInsuranceCarrierPanel = false;
  currentlyNotDisplay = false;
  remainingPatientInsuranceList: Insurance[] = [];
  tempPatientInsuranceList: Insurance[] = [];
  multipleInsuranceSettings = MultipleInsuranceSettings;

  timezoneList: TimeZone[] = [];
  fullTimezoneOffset: string;
  isDayLight = false;
  MMDDYYYY = MMDDYYYY;
  dateFormat = DatePickerConstant.dateFormate;
  rowLineNo = '3';
  maxLength = '3000';
  medicalQuestions = MedicalQuestions;

  constructor(
    private caseService: CaseManagementService,
    private sharedService: SharedService,
    private utillService: UtillService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private location: Location
  ) {}

  async ngOnInit() {
    this.headerTitle = this.utillService.getHeaderTitle();
    this.caseDetail = new Case();
    this.caseDetail.patient = new Patient();
    this.caseDetail.medicalQuestionResponses = new MedicalQuestionResponses();
    this.caseDetail.justificationQuestions = new JustificationQuestions();
    this.selectedCompanyAddPrimaryInsurance = [];
    this.selectedInsuranceType = [];
    this.addPatientInsuranceDetail = new AnmCasePatientInsurance();
    this.getStates();
    this.getPractices();
    this.getPatientEmploymentStatusList();
    this.subscriptionManager.add(
      this.route.params.subscribe(res => {
        if (res['caseId']) {
          this.caseId = res.caseId;
          this.updateCase = true;
          this.getCaseById(this.caseId);
        } else {
          this.success = true;
        }
      })
    );
    this.utillService.reuserRoute(this.router);
    this.getInsuranceCarrierGroup();
    this.getInsuredRelationship();
    this.getProcedureTypes();
    this.getResponsibleParty();
    if (this.getPermission(this.surgeon_call)) {
      this.getPracticeByLocationScheduler();
    }
    this.caseDetail.durationOfProcedure = intervalTime[8].time;
  }

  getPracticeByLocationScheduler() {
    this.subscriptionManager.add(
      this.caseService.getPracticeByLocationScheduler().subscribe((res: SearchDTO) => {
        this.caseDetail.practiceId = res.id;
        if (this.caseDetail.practiceId && this.practiceList.length) {
          this.caseDetail.practiceName = this.practiceList.find(practice => practice.id === this.caseDetail.practiceId).name;
        }
        this.getLocationsByPracticeId(res.id);
      })
    );
  }

  getPermission(permission) {
    return this.utillService.getPermissions(permission);
  }

  toggleSidebar(): void {
    this.utillService.toggleSidebar();
  }

  getStates() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getStates().subscribe(
        res => {
          this.sharedService.removeLoader();
          this.stateList = res;
        },
        () => {
          this.sharedService.removeLoader();
        }
      )
    );
  }

  getCaseById(caseId) {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getCaseById(caseId).subscribe(
        res => {
          this.caseDetail = res;
          this.caseStatus = this.caseDetail.status;
          this.caseDetail.medicalQuestionResponses = this.caseDetail.medicalQuestionResponses
            ? this.caseDetail.medicalQuestionResponses
            : new MedicalQuestionResponses();
          this.caseDetail.justificationQuestions = this.caseDetail.justificationQuestions
            ? this.caseDetail.justificationQuestions
            : new JustificationQuestions();
          this.getLocationsByPracticeId(this.caseDetail.practiceId);
          this.caseDetail.procedure = Number(this.caseDetail.procedure);
          // use in future
          // if (!(UpdateCaseStatusList.indexOf(this.caseDetail.status) > -1)) {
          //   this.router.navigate(['/unauthorise']);
          // }
          if (this.caseDetail.dateOfSurgery) {
            this.oldDate = new Date(this.caseDetail.actualDateOfSurgery);
            this.actualDateOfSurgeryDate = new Date(this.caseDetail.dateOfSurgery);
            this.actualDateOfSurgeryTimeDropDown = this.datePipe.transform(this.actualDateOfSurgeryDate, DatePickerConstant.timeFormat);
            this.actualDateOfSurgeryTimeAM_PMDropDown = this.datePipe.transform(
              this.actualDateOfSurgeryDate,
              DatePickerConstant.amPmFormat
            );
          }
          this.dateOfAdmission = this.caseDetail.dateOfAdmission
            ? new Date(this.caseDetail.dateOfAdmission)
            : new Date(this.caseDetail.dateOfSurgery);
          this.sharedService.removeLoader();
          if (this.caseDetail.anmCasePatientInsurances === null) {
            this.caseDetail.anmCasePatientInsurances = [];
          }
          this.caseInsurance(true);
          this.remainingPatientInsuranceList = this.caseDetail.patient.patientInsurances.filter(
            tempPatientInsurance =>
              !this.caseDetail.anmCasePatientInsurances.find(
                selectedInsurance => tempPatientInsurance.id === selectedInsurance.patientInsuranceDTO.id
              )
          );
          this.success = true;
        },
        () => {
          this.sharedService.removeLoader();
        }
      )
    );
  }

  // on case insurance assign carriertype and carrier list
  caseInsurance(isPatientInsurance) {
    if (this.caseDetail.anmCasePatientInsurances && this.caseDetail.anmCasePatientInsurances.length) {
      this.caseDetail.anmCasePatientInsurances.forEach(insurance => {
        if (isPatientInsurance) {
          insurance.patientInsuranceDTO.primary = insurance.type === 'PRIMARY' && insurance.sequence === 1 ? true : false;
        }
        insurance.patientInsuranceDTO.payorTypeList = [];
        this.subscriptionManager.add(
          this.caseService
            .getInsuranceTypeByInsuranceCarrierGroupId(insurance.patientInsuranceDTO.insuranceCarrierGroupId)
            .subscribe((res1: InsuranceTypeList[]) => {
              insurance.patientInsuranceDTO.payorTypeList = res1;
            })
        );
        insurance.patientInsuranceDTO.carrierList = [];
        this.subscriptionManager.add(
          this.caseService
            .getInsuranceCarrierByInsruanceTypeId(
              insurance.patientInsuranceDTO.insuranceCarrierGroupId,
              insurance.patientInsuranceDTO.insuranceTypeId
            )
            .subscribe((res2: InsuranceCarrier[]) => {
              insurance.patientInsuranceDTO.carrierList = res2;
            })
        );
      });
    }
  }

  async addCase(form) {
    this.caseDetail.patient.dateOfBirth = this.datePipe.transform(this.caseDetail.patient.dateOfBirth, DatePickerConstant.dateFormate);
    if (this.caseDetail.responsiblePartyId === 2 && this.caseDetail.anmCasePatientInsurances.length) {
      this.caseDetail.anmCasePatientInsurances.forEach((insurance, i) => {
        if (insurance.patientInsuranceDTO.primary) {
          insurance.sequence = 1;
          insurance.type = 'PRIMARY';
        } else {
          insurance.sequence = i + 1;
          insurance.type = 'SECONDARY';
        }
        if (insurance.patientInsuranceDTO.id) {
          this.caseDetail.patient.patientInsurances[
            this.caseDetail.patient.patientInsurances.findIndex(
              patientInsurance => patientInsurance.id === insurance.patientInsuranceDTO.id
            )
          ] = insurance.patientInsuranceDTO;
        }
      });
    } else {
      this.caseDetail.anmCasePatientInsurances = [];
    }

    const date =
      moment(this.actualDateOfSurgeryDate).format(YYYYMMDD) +
      'T' +
      moment(this.actualDateOfSurgeryTimeDropDown + ' ' + this.actualDateOfSurgeryTimeAM_PMDropDown, ['h:mm A']).format('HH:mm:ss');

    this.caseDetail.dateOfSurgeryString = date;

    this.caseDetail.surgeryDate = moment(this.actualDateOfSurgeryDate).format(YYYYMMDD);
    this.caseDetail.surgeryTime = this.actualDateOfSurgeryTimeDropDown;
    this.caseDetail.surgeryAmpm = this.actualDateOfSurgeryTimeAM_PMDropDown;
    this.caseDetail.dateOfSurgery = this.utillService.timeConvertIntoDate(
      this.actualDateOfSurgeryDate,
      this.actualDateOfSurgeryTimeDropDown,
      this.actualDateOfSurgeryTimeAM_PMDropDown
    );
    this.caseDetail.actualDateOfSurgery = this.utillService.timeConvertIntoDate(
      this.actualDateOfSurgeryDate,
      this.actualDateOfSurgeryTimeDropDown,
      this.actualDateOfSurgeryTimeAM_PMDropDown
    );

    if (this.updateCase) {
      this.sharedService.showLoader();
      this.subscriptionManager.add(
        this.caseService.updateCases(this.caseDetail).subscribe(
          () => {
            this.sharedService.removeLoader();
            this.sharedService.successToaster(SuccessMsg.caseUpdate);
            this.location.back();
          },
          () => {
            this.sharedService.removeLoader();
          }
        )
      );
    } else {
      this.sharedService.showModal('confirmCaseInformationModal');
    }
  }

  createCase() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.createCases(this.caseDetail).subscribe(
        res => {
          this.sharedService.removeLoader();
          this.caseDetail = res;
          this.uploadFile();
        },
        () => {
          this.sharedService.removeLoader();
        }
      )
    );
  }

  checkPatient() {
    this.caseDetail.patient.firstName = this.caseDetail.patient.firstName.trim();
    this.caseDetail.patient.lastName = this.caseDetail.patient.lastName.trim();
    if (
      this.utillService.checkPatientName(
        this.caseDetail.patient.firstName,
        this.caseDetail.patient.lastName,
        this.patientFirstName,
        this.patientLastName
      )
    ) {
      this.patientFirstName = _.clone(this.caseDetail.patient.firstName.toLowerCase());
      this.patientLastName = _.clone(this.caseDetail.patient.lastName.toLowerCase());
      this.sharedService.showLoader();
      this.subscriptionManager.add(
        this.caseService.checkPatientByFirstNameAndLastName(this.patientFirstName, this.patientLastName).subscribe(
          res => {
            this.sharedService.removeLoader();
            this.foundPatientList = [];
            this.foundPatientList = res;
            if (this.foundPatientList.length) {
              this.foundedSelectedPatient = new Patient();
              this.sharedService.showModal('foundPatientShowModal');
            }
          },
          () => {
            this.sharedService.removeLoader();
          }
        )
      );
    }
  }

  // On select patient inforamtion add in case
  selectFoundPatient() {
    this.caseDetail.patient = new Patient();
    this.caseDetail.patient = this.foundedSelectedPatient;
    this.caseDetail.anmCasePatientInsurances = [];
    this.remainingPatientInsuranceList = this.caseDetail.patient.patientInsurances.filter(
      tempPatientInsurance =>
        !this.caseDetail.anmCasePatientInsurances.find(
          selectedInsurance => tempPatientInsurance.id === selectedInsurance.patientInsuranceDTO.id
        )
    );
  }

  unknownPatientCheck() {
    if (!this.unKnownPatientBoolean) {
      if (this.unknownPatient) {
        this.caseDetail.patient = this.unknownPatient;
      } else {
        this.caseDetail.patient = new Patient();
      }
      this.unKnownPatientBoolean = false;
    } else {
      this.unknownPatient = _.cloneDeep(this.caseDetail.patient);
      this.caseDetail.patient = null;
      this.unKnownPatientBoolean = true;
    }
  }

  getPatientEmploymentStatusList() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getPatientEmploymentStatusList().subscribe(
        res => {
          this.sharedService.removeLoader();
          this.patientEmploymentStatusList = res;
        },
        () => {
          this.sharedService.removeLoader();
        }
      )
    );
  }

  notifyAfterAddUpdateFile(event) {
    event.map(file => {
      if (file.tagId !== OtherTagId) {
        const tempExistingFile = this.caseFileUploadList.find(existingFile => existingFile.tagId === file.tagId);
        if (tempExistingFile) {
          this.caseFileUploadList[this.caseFileUploadList.indexOf(tempExistingFile)] = file;
          event.splice(event.indexOf(file, 1));
        }
      }
    });
    this.caseFileUploadList = this.caseFileUploadList.concat(event);
  }

  notityAfterRemoveFile(event) {
    this.caseFileUploadList.splice(event, 1);
  }

  uploadFile() {
    if (this.caseFileUploadList.length > 0) {
      this.caseFileUploadList.forEach(attachment => {
        this.formData.append('multipartFiles', attachment.multipartFiles, attachment.fileName);
        this.tagIdList.push(attachment.tagId.toString());
      });
      this.sharedService.showLoader();
      this.formData.append('tagIdList', JSON.stringify(this.tagIdList));
      if (!this.sharedService.hasDuplicates(this.tagIdList)) {
        this.caseService.uploadFiles(this.formData, this.caseDetail.id).subscribe(
          res => {
            this.sharedService.removeLoader();
            this.goToCaseListPage();
          },
          error => {
            this.sharedService.removeLoader();
            this.sharedService.dangerToaster(this.caseDetail.caseNo + ' ' + SuccessMsg.caseCreateFileFailed);
            this.goToCaseListPage();
          }
        );
      } else {
        this.sharedService.dangerToaster(SuccessMsg.multipleTag);
      }
    } else {
      this.goToCaseListPage();
    }
  }

  goToCaseListPage() {
    this.sharedService.successToaster(this.caseDetail.caseNo + ' ' + SuccessMsg.caseCreate);
    if (!this.isCreateAnotherCase) {
      this.goBack();
    } else {
      this.router.navigate(['/case-create']);
    }
  }

  goBack() {
    this.location.back();
  }

  @HostListener('document:keydown', ['$event'])
  datepickerHide(event: KeyboardEvent) {
    if (event.keyCode === 9) {
      this.datepicker.hide();
      this.dp.hide();
    }
  }

  onSelectPractice(event) {
    this.caseDetail.practiceId = event.id;
    this.caseDetail.practiceName = event.name;
    this.getLocationsByPracticeId(event.id);
  }

  getLocationsByPracticeId(id) {
    this.subscriptionManager.add(
      this.caseService.getAllLocationsByPracticeId(id).subscribe(res => {
        this.locationList = res;
        if (this.locationList.length === 1) {
          this.caseDetail.locationId = this.locationList[0].id;
        }
      })
    );
  }

  onDeselectPractice() {
    this.removePractice();
  }

  removePractice() {
    this.caseDetail.practiceId = null;
    this.caseDetail.practiceName = null;
  }

  getPractices() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getPractices().subscribe(
        res => {
          this.sharedService.removeLoader();
          this.practiceList = res;
          if (this.caseDetail.practiceId !== null && !this.practiceList.some(practice => practice.id === this.caseDetail.practiceId)) {
            this.practiceList.push(new SearchDTO(this.caseDetail.practiceId, this.caseDetail.practiceName));
          } else {
            if (this.practiceList != null && this.practiceList.length === 1) {
              this.caseDetail.practiceId = this.practiceList[0].id;
              this.caseDetail.practiceName = this.practiceList[0].name;
            } else {
              this.removePractice();
            }
          }
        },
        () => {
          this.sharedService.removeLoader();
        }
      )
    );
  }

  // On Responsibel Party change Add New Inusrance
  onResponsiblePartyChange() {
    if (this.caseDetail.responsiblePartyId === 2 && !this.caseDetail.anmCasePatientInsurances.length) {
      this.addNewInsurance();
    } else {
      this.caseDetail.anmCasePatientInsurances = [];
    }
  }

  // On TBD change time set 07:30 AM
  onTBDChange(event) {
    if (event === true) {
      this.actualDateOfSurgeryTimeDropDown = intervalTime[26].time;
      this.actualDateOfSurgeryTimeAM_PMDropDown = AMPMDropDown[0].value;
    }
  }

  // New Inusance object add in Table
  addNewInsurance() {
    const insurance = new AnmCasePatientInsurance();
    insurance.patientInsuranceDTO = new Insurance();
    this.carrierInsuranceTypeList[this.caseDetail.anmCasePatientInsurances.length] = {};
    this.carrierCompanyList[this.caseDetail.anmCasePatientInsurances.length] = {};
    this.caseDetail.anmCasePatientInsurances.push(insurance);
  }

  getInsuranceCarrierGroup() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getAllInsuanceCarrierGroup().subscribe(res => {
        this.sharedService.removeLoader();
        this.carrierCategoryList = res;
      })
    );
  }

  getInsuredRelationship() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getInsuredRelationship().subscribe(res => {
        this.sharedService.removeLoader();
        this.insuredRelationshipList = res;
      })
    );
  }

  onSelectCarrierCategory(event, obj, index) {
    obj.patientInsuranceDTO.insuranceTypeId = null;
    obj.patientInsuranceDTO.insuranceTypeName = null;
    obj.patientInsuranceDTO.insuranceCarrierGroupId = event.id;
    obj.patientInsuranceDTO.insuranceCarrierGroupName = event.name;
    this.getInsuranceTypeByInsuranceCarrierGroupId(obj.patientInsuranceDTO.insuranceCarrierGroupId, obj, index);
  }

  onDeSelectCarrierCategory(obj) {
    const items = [
      'insuranceCarrierGroupId',
      'insuranceCarrierGroupName',
      'insuranceTypeId',
      'insuranceTypeName',
      'insuranceCarrierId',
      'insuranceCarrierName'
    ];
    this.removeItems(obj, items);
  }

  onSelectInsuranceType(event, obj, index) {
    obj.patientInsuranceDTO.insuranceCarrierId = null;
    obj.patientInsuranceDTO.insuranceCarrierName = null;
    obj.patientInsuranceDTO.insuranceTypeId = event.id;
    obj.patientInsuranceDTO.insuranceTypeName = event.name;
    this.getInsuranceCarrierByInsuranceCarrierGroupIdAndInsruanceTypeId(
      obj.patientInsuranceDTO.insuranceCarrierGroupId,
      obj.patientInsuranceDTO.insuranceTypeId,
      obj,
      index
    );
  }

  onDeSelectInsuranceType(obj) {
    const items = ['insuranceTypeId', 'insuranceTypeName', 'insuranceCarrierId', 'insuranceCarrierName'];
    this.removeItems(obj, items);
  }

  onSelectInsuranceCarrier(event, obj) {
    obj.patientInsuranceDTO.insuranceCarrierId = event.id;
    obj.patientInsuranceDTO.insuranceCarrierName = event.insuranceName;
  }

  removeInsuranceCarrier(obj) {
    this.removeItems(obj, ['insuranceCarrierId', 'insuranceCarrierName']);
  }

  getInsuranceTypeByInsuranceCarrierGroupId(insuranceCarrierGroupId, obj, index) {
    if (insuranceCarrierGroupId !== null) {
      this.subscriptionManager.add(
        this.caseService.getInsuranceTypeByInsuranceCarrierGroupId(insuranceCarrierGroupId).subscribe(res => {
          this.insuranceTypeList = res;
          this.carrierInsuranceTypeList[index][insuranceCarrierGroupId] = this.insuranceTypeList;
          if (this.insuranceTypeList.length === 1) {
            obj.patientInsuranceDTO.insuranceTypeId = this.insuranceTypeList[0].id;
            obj.patientInsuranceDTO.insuranceTypeName = this.insuranceTypeList[0].name;
            this.getInsuranceCarrierByInsuranceCarrierGroupIdAndInsruanceTypeId(
              obj.patientInsuranceDTO.insuranceCarrierGroupId,
              obj.patientInsuranceDTO.insuranceTypeId,
              obj,
              index
            );
          }
        })
      );
    }
  }

  getInsuranceCarrierByInsuranceCarrierGroupIdAndInsruanceTypeId(insuranceCarrierGroupId, insuranceTyepId, obj, index) {
    this.subscriptionManager.add(
      this.caseService.getInsuranceCarrierByInsruanceTypeId(insuranceCarrierGroupId, insuranceTyepId).subscribe(res => {
        this.companyList = res;
        this.carrierCompanyList[index][insuranceCarrierGroupId] = this.companyList;
        if (this.companyList.length === 1) {
          obj.patientInsuranceDTO.insuranceCarrierId = this.companyList[0].id;
          obj.patientInsuranceDTO.insuranceCarrierName = this.companyList[0].insuranceName;
        }
      })
    );
  }

  removeItems(obj, items) {
    for (let i of items) {
      obj[i] = null;
    }
  }

  getCarrierInsuranceTypeList(index, id) {
    if (this.carrierInsuranceTypeList[index] && this.carrierInsuranceTypeList[index][id]) {
      return this.carrierInsuranceTypeList[index][id];
    }
    return [];
  }

  getCarrierCompanyList(index, id) {
    if (this.carrierCompanyList[index] && this.carrierCompanyList[index][id]) {
      return this.carrierCompanyList[index][id];
    }
    return [];
  }

  trackByFunction(index: number, element) {
    return element ? index : null;
  }

  async removeInsurance(index) {
    if (this.caseDetail.anmCasePatientInsurances.length === 1) {
      await this.utillService.commonWarningMessageAlert(AlertWarningMessage.oneInsuranceRequired);
    } else {
      if (await this.utillService.deleteAlert()) {
        this.caseDetail.anmCasePatientInsurances.splice(index, 1);
        delete this.carrierInsuranceTypeList[index];
        let tempObj = {};
        let newVal: number;
        for (let i in this.carrierInsuranceTypeList) {
          newVal = parseInt(i);
          if (newVal > index) {
            const key = (newVal - 1).toString();
            tempObj[key] = this.carrierInsuranceTypeList[i];
          } else {
            tempObj[i] = this.carrierInsuranceTypeList[i];
          }
        }
        this.carrierInsuranceTypeList = tempObj;
        tempObj = {};
        delete this.carrierCompanyList[index];
        for (let i in this.carrierCompanyList) {
          newVal = parseInt(i);
          if (newVal > index) {
            const key = (newVal - 1).toString();
            tempObj[key] = this.carrierCompanyList[i];
          } else {
            tempObj[i] = this.carrierCompanyList[i];
          }
        }
        this.carrierCompanyList = tempObj;
      }
    }
  }

  getResponsibleParty() {
    this.subscriptionManager.add(
      this.caseService.getResponsibleParty().subscribe(res => {
        this.responsiblePartyList = res;
      })
    );
  }

  getProcedureTypes() {
    this.subscriptionManager.add(
      this.caseService.getProcedureTypes().subscribe(res => {
        this.procedures = res;
      })
    );
  }

  // Open/close Insurance Carrier Create panel
  toggleInsuranceCarrierPanel() {
    if (this.isInsuranceCarrierPanel) {
      this.isInsuranceCarrierPanel = false;
    } else {
      this.isInsuranceCarrierPanel = true;
    }
  }

  // New Insurance Carrier update Insurance carrier catrgory list
  insuranceCarrier(event) {
    this.getInsuranceCarrierGroup();
    this.isInsuranceCarrierPanel = false;
    this.sharedService.closeFileModal('patientInsuranceCarrierPanel');
  }

  // Select only one primary
  onInsuranceTypeChange(insurance, index) {
    this.caseDetail.anmCasePatientInsurances.forEach((obj, i) => {
      if (i !== index) {
        obj.patientInsuranceDTO.primary = false;
      }
    });
    insurance.patientInsuranceDTO.primary = true;
  }

  onSelectRemainingPatientInsurance(event) {
    const insurance = new AnmCasePatientInsurance();
    insurance.patientInsuranceDTO = event;
    if (
      this.caseDetail.anmCasePatientInsurances.every(insuranceObj => {
        return !(
          insuranceObj.patientInsuranceDTO.insuranceCarrierGroupId &&
          insuranceObj.patientInsuranceDTO.insuranceTypeId &&
          insuranceObj.patientInsuranceDTO.insuranceCarrierId
        );
      })
    ) {
      this.caseDetail.anmCasePatientInsurances = [];
    }
    this.caseDetail.anmCasePatientInsurances.push(insurance);
    this.caseInsurance(true);
    this.remainingPatientInsuranceList.splice(this.remainingPatientInsuranceList.indexOf(event), 1);
    this.tempPatientInsuranceList = [];
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
