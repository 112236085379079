import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs/Subscription';
import { monthDate, MultipleInsuranceSettings, PatientInsuranceMessage } from '../../shared/constants/common.constant';
import { Button } from '../../shared/constants/message.constants';
import { AlertWarningMessage, SuccessMsg } from '../../shared/constants/success.constants';
import { SharedService } from '../../shared/services/shared.service';
import { UtillService } from '../../shared/services/utill.service';
import {
  Attorney,
  CarrierCategory,
  Insurance,
  InsuranceCarrierGroup,
  InsuranceTypeList,
  InsuredRelationship,
  State
} from '../case-management.model';
import { CaseManagementService } from '../case-management.service';

@Component({
  selector: 'app-patient-insurance-list',
  templateUrl: './patient-insurance-list.component.html'
})
export class PatientInsuranceListComponent implements OnInit, OnDestroy {
  private _patientInsurances: Insurance[] = [];
  @Input() selectedInsuranceList: Insurance[];
  @Input() stateList: State[];
  @Input() isDragEnable: Boolean;
  addPatientInsurance = Button.addPatientInsurance;
  multipleInsuranceSettings = MultipleInsuranceSettings;
  attorneyList: Attorney[] = [];
  insuredRelationshipList: InsuredRelationship[] = [];
  insuranceTypeList: InsuranceTypeList[] = [];
  openAddUpdatePatientInformationModal = false;
  addUpdatePatientInsuranceDetail: Insurance;
  index: number;
  isAdd: Boolean;
  monthDateAndHour = monthDate;
  remainingPatientInsuranceList: Insurance[] = [];
  tempPatientInsuranceList: Insurance[] = [];
  patientInsuranceMessage = PatientInsuranceMessage;
  insuranceCarrierGroupList: InsuranceCarrierGroup[] = [];
  carrierCategoryList: CarrierCategory[] = [];
  subscriptionManager = new Subscription();

  constructor(private sharedService: SharedService, private utillService: UtillService, private caseService: CaseManagementService) {}

  ngOnInit() {
    this.getInsuranceType();
    this.getAttorney();
    this.getInsuredRelationship();
    this.getInsuranceCarrierGroup();
    this.getAllCarrierCategory();
  }

  getInsuranceType() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getInsuranceType().subscribe(res => {
        this.sharedService.removeLoader();
        this.insuranceTypeList = res;
      })
    );
  }

  getAttorney() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getAllAttorney().subscribe(res => {
        this.sharedService.removeLoader();
        this.attorneyList = res;
      })
    );
  }

  getInsuredRelationship() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getInsuredRelationship().subscribe(res => {
        this.sharedService.removeLoader();
        this.insuredRelationshipList = res;
      })
    );
  }

  getInsuranceCarrierGroup() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getAllInsuanceCarrierGroup().subscribe(res => {
        this.sharedService.removeLoader();
        this.insuranceCarrierGroupList = res;
      })
    );
  }

  getAllCarrierCategory() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getCarrierCategory().subscribe(res => {
        this.sharedService.removeLoader();
        this.carrierCategoryList = res;
      })
    );
  }

  async openAddUpdatePatientInformationDetailModal(insuranceDetail, index) {
    if (insuranceDetail != null && insuranceDetail.id) {
      await this.checkCaseCountByInsuranceId(insuranceDetail.id)
        .then(() => this.openAddUpdatePatientInformationDetailModalWithData(insuranceDetail, index))
        .catch(async () => {
          if (
            await this.utillService.commonWarningAlert(
              AlertWarningMessage.updateInsuranceWarningMessage,
              AlertWarningMessage.updateWarningText,
              AlertWarningMessage.updateWarningButton
            )
          ) {
            this.openAddUpdatePatientInformationDetailModalWithData(insuranceDetail, index);
          } else {
            this.openAddUpdatePatientInformationModal = false;
          }
        });
    } else {
      this.openAddUpdatePatientInformationDetailModalWithData(insuranceDetail, index);
    }
  }

  openAddUpdatePatientInformationDetailModalWithData(insuranceDetail, index) {
    this.addUpdatePatientInsuranceDetail = new Insurance();
    if (insuranceDetail != null) {
      this.addUpdatePatientInsuranceDetail = _.clone(insuranceDetail);
      this.index = index;
      this.isAdd = false;
    } else {
      this.index = null;
      this.isAdd = true;
    }
    this.openAddUpdatePatientInformationModal = true;
  }

  closeModal() {
    this.openAddUpdatePatientInformationModal = false;
  }

  async removeSelectedInsurance(insurance, index) {
    if (insurance.id !== null && !this.isDragEnable) {
      await this.checkCaseCountByInsuranceId(insurance.id)
        .then(() => this.removeInsuranceFromList(insurance, index))
        .catch(() => this.sharedService.dangerToaster(SuccessMsg.patientInsuranceDelete));
    } else {
      this.removeInsuranceFromList(insurance, index);
    }
  }

  async removeInsuranceFromList(insurance, index) {
    if (await this.utillService.deleteAlert()) {
      if (this.isDragEnable) {
        this.selectedInsuranceList.splice(index, 1);
        if (insurance.id !== null) {
          this.remainingPatientInsuranceList.push(insurance);
        } else {
          this.patientInsurances.splice(this.patientInsurances.indexOf(insurance), 1);
        }
      } else {
        this.patientInsurances.splice(index, 1);
      }
    }
  }

  notifyAfterPatientInsuranceAddUpdate(event) {
    if (this.index != null) {
      if (this.isDragEnable) {
        this.patientInsurances[
          this.patientInsurances.findIndex(
            patientInsurance => patientInsurance.policyNumber === this.selectedInsuranceList[this.index].policyNumber
          )
        ] = event;
        this.selectedInsuranceList[this.index] = event;
      } else {
        this.patientInsurances[this.index] = event;
      }
    } else {
      this.patientInsurances.push(event);
      if (this.isDragEnable) {
        this.selectedInsuranceList.push(event);
      }
    }
    this.closeModal();
  }

  checkCaseCountByInsuranceId(insuranceId) {
    this.sharedService.showLoader();
    return new Promise((resolve, reject) => {
      this.subscriptionManager.add(
        this.caseService.checkCaseCountByInsuranceId(insuranceId).subscribe(res => {
          this.sharedService.removeLoader();
          if (res != null && ((this.isDragEnable && res > 1) || (!this.isDragEnable && res > 0))) {
            return reject();
          }
          return resolve();
        })
      );
    });
  }

  onSelectRemainingPatientInsurance(event) {
    this.selectedInsuranceList.push(event);
    this.remainingPatientInsuranceList.splice(this.remainingPatientInsuranceList.indexOf(event), 1);
    this.tempPatientInsuranceList = [];
  }

  @Input()
  set patientInsurances(patientInsurance: Insurance[]) {
    this._patientInsurances = patientInsurance;
    if (this.isDragEnable) {
      this.remainingPatientInsuranceList = this.patientInsurances.filter(
        tempPatientInsurance => !this.selectedInsuranceList.find(selectedInsurance => tempPatientInsurance.id === selectedInsurance.id)
      );
    }
  }

  get patientInsurances() {
    return this._patientInsurances;
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
