import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.component.html'
})
export class TutorialComponent implements OnInit {

  @Input() isReset: boolean;
  @Output() closeModal: EventEmitter<boolean> = new EventEmitter<boolean>();

  tutorialData: Tutorial[] = [
    { heading: 'Improved Dashboard', description: 'The new & improved dashboard lets you view current & future scheduled cases, quickly update or reschedule cases, schedule new cases, and view past cases.', img: '../../../assets/img/slider/step_1.jpg' },
    { heading: 'Improved Dashboard', description: 'Change the date range to see past cases.', img: '../../../assets/img/slider/step_1_1rev.jpg' },
    { heading: 'Improved Dashboard', description: 'Update case details or change the scheduled date/time.', img: '../../../assets/img/slider/step_1_3rev1.jpg' },
    { heading: 'Improved Dashboard', description: 'Add new cases at the click of a button', img: '../../../assets/img/slider/step_1_3rev.jpg' },
    { heading: 'Upgraded Case Editor', description: 'The case editor provides clear indications of required fields, the flexibility to override name requirements for unknown patient names, and the ability to enter a patient\'s insurance information.', img: '../../../assets/img/slider/step_2.jpg' },
    { heading: 'Easy Uploads', description: 'Use the "add attachment" button to upload a booking sheet or insurance information.', img: '../../../assets/img/slider/add-attachment-slide.jpg' },
    { heading: 'Expert Support', description: 'If you have any questions or need assistance please call us at (614) 664-7674 or send an Email to <a href="mailto:support@thesunflowerlab.com">support@thesunflowerlab.com</a>', img: '../../../assets/img/slider/step_1.jpg' }
  ];

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
  }

  closeTutorialModal() {
    if (this.isReset) {
      this.router.navigate(['/login']);
    } else {
      this.closeModal.next();
    }
  }

}

class Tutorial {
  heading: string;
  description: string;
  img: string;
}

