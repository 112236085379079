import { Pipe, PipeTransform } from '@angular/core';
import { InsuranceCarrier } from '../../case-management/case-management.model';

@Pipe({
  name: 'insuranceCarrierAddress'
})
export class InsuranceCarrierAddressPipe implements PipeTransform {

  transform(insuranceCarrier: InsuranceCarrier): string {
    let tempString = '';
    if (insuranceCarrier) {
      if (insuranceCarrier.insuranceName) {
        tempString += insuranceCarrier.insuranceName;
      }
      if (insuranceCarrier.address1) {
        tempString += tempString ? '-' + insuranceCarrier.address1 : '';
      }
      if (insuranceCarrier.postalCode) {
        tempString += tempString ? '-' + insuranceCarrier.postalCode : '';
      }
      if (insuranceCarrier.stateName) {
        tempString += tempString ? '-' + insuranceCarrier.stateName : '';
      }
    }
    return tempString;
  }

}
