<div class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title text-navy">
        <strong>Update Password</strong>
      </h4>
      <button type="button" class="close" data-toggle="modal" data-dismiss="modal" (click)="callParentOpenModal()">&times;</button>
    </div>
    <form class="form-horizontal" #updatePasswordForm="ngForm">
      <div class="modal-body">
        <div class="form-group">
          <div class="row">
            <label class="col-sm-3 col-form-label text-right">
              Old Password
              <small class="mandatory"></small>
            </label>
            <div class="col-sm-8">
              <input
                type="password"
                class="form-control"
                id="oPassword"
                #oPassword="ngModel"
                class="form-control"
                name="oPassword"
                [(ngModel)]="oldPassword"
                required
              />
              <app-err-msg [control]="oPassword" fieldName="Password"></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-sm-3 col-form-label text-right">
              Password
              <small class="mandatory"></small>
            </label>
            <div class="col-sm-8">
              <input
                type="password"
                class="form-control"
                id="iPassword"
                #iPassword="ngModel"
                class="form-control"
                name="iPassword"
                [(ngModel)]="password"
                required
                appPasswordValidate
              />
              <app-err-msg [control]="iPassword" fieldName="Password"></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-sm-3 col-form-label text-right">
              Confirm Password
              <small class="mandatory"></small>
            </label>
            <div class="col-sm-8">
              <input
                type="password"
                class="form-control"
                id="cPassword"
                #cPassword="ngModel"
                class="form-control"
                name="cPassword"
                [(ngModel)]="confirmPassword"
                appEqualvalidate="iPassword"
                required
                appPasswordValidate
              />
              <app-err-msg [control]="cPassword" fieldName="Password"></app-err-msg>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          [disabled]="updatePasswordForm.form.invalid"
          class="btn btn-success"
          data-toggle="modal"
          data-dismiss="modal"
          (click)="updateUserPassword()"
        >
          {{ saveButton }}
        </button>
        <button type="button" class="btn btn-default" data-toggle="modal" (click)="callParentOpenModal()">{{ cancelButton }}</button>
      </div>
    </form>
  </div>
</div>
