import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssn'
})
export class SsnPipe implements PipeTransform {

  i = 0 ;

  transform(val, args) {
    // val = val.charAt(0) !== 0 ? '0' + val : '' + val;
    let newStr = '';

    for ( this.i = 0 ; this.i < (Math.floor(val.length / 3) - 1); this.i++) {
       newStr = newStr + val.substr( this.i * 3 , 3) + '-';
    }
    return newStr + val.substr( this.i * 3 );
}

}
