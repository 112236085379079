<nav class="navbar navbar-static-top navbar-expand-md" role="navigation">
  <div class="navbar-header">
    <a class="navbar-brand anmLogo" routerLink="/case-list">
      <img src="../../../assets/img/logo/Zinniax-Logo.png" alt="ZinniaX Logo" />
    </a>
    <button
      aria-controls="navbar"
      aria-expanded="false"
      data-target="#navbar"
      data-toggle="collapse"
      class="navbar-toggler collapsed"
      type="button"
    >
      <em class="fa fa-reorder"></em>
    </button>
  </div>
  <div class="navbar-collapse collapse" id="navbar">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <a aria-expanded="false" role="button" class="nav-link" [routerLink]="['/case-list']" routerLinkActive="active">Cases </a>
      </li>
      <li class="nav-item">
        <a aria-expanded="false" role="button" class="nav-link" [routerLink]="['/case-create']" routerLinkActive="active">Add Case </a>
      </li>
      <li class="nav-item">
        <a aria-expanded="false" role="button" class="nav-link" [routerLink]="['/schedule/practice']" routerLinkActive="active"
          >Practice Schedule
        </a>
      </li>
      <li class="nav-item dropdown d-md-none">
        <a data-toggle="modal" data-target="#notificationSidePanel" class="count-info rightTopNav">
          <em class="fa fa-bell"></em><span class="label label-primary">{{ notificationCount }}</span>
        </a>
      </li>
      <li class="nav-item dropdown pl-2 d-md-none">
        <a class="nav-link proPic" id="system" data-toggle="dropdown" aria-expanded="false">
          <div class="profile-button" *ngIf="profileDetail">
            {{ profileDetail?.firstName.substring(0, 1) | uppercase }}{{ profileDetail?.lastName.substring(0, 1) | uppercase }}
          </div>
          <div class="profile-button" *ngIf="!profileDetail">
            ??
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="system" style="left: inherit; right: 0px;">
          <a class="dropdown-item" *ngIf="profileDetail">{{ profileDetail?.firstName }} {{ profileDetail?.lastName }} </a>
          <a
            class="dropdown-item"
            data-toggle="modal"
            data-target="#updatePassword"
            data-backdrop="static"
            (click)="openUpdatePasswordModal()"
            >Update Password</a
          >
          <div class="divider"></div>
          <a class="dropdown-item" title="Logout" (click)="logout()"><em class="fa fa-sign-out"></em>Log out </a>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav d-sm-none d-md-flex align-items-center ml-auto">
      <li class="nav-item dropdown">
        <a data-toggle="modal" data-target="#notificationSidePanel" class="count-info rightTopNav">
          <em class="fa fa-bell"></em><span class="label label-primary">{{ notificationCount }}</span>
        </a>
      </li>
      <li class="nav-item dropdown pl-2">
        <a class="nav-link proPic" id="system" data-toggle="dropdown" aria-expanded="false">
          <div class="profile-button" *ngIf="profileDetail">
            {{ profileDetail?.firstName.substring(0, 1) | uppercase }}{{ profileDetail?.lastName.substring(0, 1) | uppercase }}
          </div>
          <div class="profile-button" *ngIf="!profileDetail">
            ??
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="system" style="left: inherit; right: 0px;">
          <a class="dropdown-item" *ngIf="profileDetail">{{ profileDetail?.firstName }} {{ profileDetail?.lastName }} </a>
          <a
            class="dropdown-item"
            data-toggle="modal"
            data-target="#updatePassword"
            data-backdrop="static"
            (click)="openUpdatePasswordModal()"
            >Update Password</a
          >
          <div class="divider"></div>
          <a class="dropdown-item" title="Logout" (click)="logout()"><em class="fa fa-sign-out"></em>Log out </a>
        </div>
      </li>
    </ul>
  </div>
</nav>
<!-- Update Password modal -->
<div class="modal fade" id="updatePassword" role="dialog" *ngIf="updatePasswordModal">
  <app-change-password (openUpdatePasswordModal)="openUpdatePasswordModal()"></app-change-password>
</div>

<div class="modal fade" id="tutorialModal" role="dialog" *ngIf="isTutorialModal">
  <app-tutorial (closeModal)="openTutorialModal()" [isReset]="false"></app-tutorial>
</div>

<div class="mini-side-panel">
  <div class="modal right fade" id="notificationSidePanel" tabindex="-1" role="dialog">
    <app-notification-sidepanel (notificationCount)="updateNotificationCount($event)"></app-notification-sidepanel>
  </div>
</div>
