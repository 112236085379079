import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-err-msg',
  template: `<div class="input-error" *ngIf="errorMessage !== null">{{ errorMessage }}</div>`,
  styleUrls: ['./err-msg.component.css']
})
export class ErrMsgComponent {
  @Input() control: FormControl;
  @Input() fieldName: string;
  @Input() isFormSubmitted: boolean;

  constructor() {}

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && (this.control.touched || this.isFormSubmitted)) {
        if (this.isFormSubmitted) {
          this.setTouchedControl(this.control);
        }
        return this.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
      }
    }
    return null;
  }

  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: this.fieldName + ' is required',
      appPhoneValidate: this.fieldName + ' is invalid',
      appEmailValidate: this.fieldName + ' is invalid',
      appPasswordValidate: this.fieldName + ' must containt 8 characters, capital letters, lowercase, numbers and special character.',
      minlength: `minnimum length ${validatorValue.requiredLength}`,
      min: `minumum value ${validatorValue.min}`,
      max: `maximum value ${validatorValue.max}`,
      matchPassword: this.fieldName + ' is mismatched',
      appEqualvalidate: this.fieldName + ' is mismatched',
      appWebValidate: this.fieldName + ' is invalid'
    };

    return config[validatorName];
  }

  setTouchedControl(control) {
    control.control.markAsTouched();
  }
}
