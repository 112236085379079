import { environment } from '../../../environments/environment';

const API_URL = environment.HOST_URL;
const WEB_SOCKET_URL = environment.WEB_SOCKET_URL;

export const CONSTANTS = {
  ENDPOINTS: {
    MAINLOGINURL: window.location.origin + '#/login',
    LOGIN: API_URL + 'authenticate',
    RESETPASSREQ: API_URL + 'reset-password/init',
    RESETPASSFINISH: API_URL + 'reset-password/finish',
    USERS: API_URL + 'users',
    USERGROUPS: API_URL + 'authorities',
    USERLISTBYUSERGROUP: API_URL + 'authority',
    ISUSERGROUPEXIST: API_URL + 'authorities/availability',
    STATES: API_URL + 'states',
    CALL_REGIONS: API_URL + 'callRegions',
    PARENTREGIONS: API_URL + 'parentRegions',
    REGION: API_URL + 'regions',
    TENANTS: API_URL + 'tenant',
    COMPETENCIES: API_URL + 'competences',
    ENTITIES: API_URL + 'entities',
    LEGAL_ENTITIES: API_URL + 'legalEntities',
    SYSTEMS: API_URL + 'systems',
    TEAMS: API_URL + 'teams',
    USERLISTBYNAME: API_URL + 'users',
    USERTEAMS: API_URL + 'user-teams',
    HOSPITALS: API_URL + 'hospitals',
    OPERATINGROOMS: API_URL + 'operatingRooms',
    HOSPITALCHECKLISTS: API_URL + 'hospitalCheckLists',
    VENDORS: API_URL + 'vendors',
    PARTS: API_URL + 'parts',
    PRACTICES: API_URL + 'practices',
    DOCTORS: API_URL + 'doctors',
    LOCATIONS: API_URL + 'locations',
    EXTERNAL_SCHEDULARS: API_URL + 'location-schedulers',
    PATIENTS: API_URL + 'patients',
    PATIENTSINSURANCETYPE: API_URL + 'patientsInsuranceType',
    INSURANCECARRIERS: API_URL + 'insuranceCarriers',
    ATTORNEY: API_URL + 'attorneys',
    HOSPITALSCHEDULER: API_URL + 'hospitalSchedulers',
    CASES: API_URL + 'cases',
    MODALITIE: API_URL + 'modalities',
    TECH_CALL: API_URL + 'techCallSchedules',
    TAGS: API_URL + 'tags',
    ATTACHMENTS: API_URL + 'attachments',
    PROCEDURETYPES: API_URL + 'procedureTypes',
    CHANGEDMODALITIES: API_URL + 'changedModalities',
    USER_CREDENTIALS: API_URL + 'userCredentials',
    MUSCLES: API_URL + 'muscles',
    BILLINGS: API_URL + 'billings',
    DIAGNOSISCODE: API_URL + 'diagnosisCodes',
    USERNOTIFICATION: API_URL + 'userNotifications',
    PURCHASEORDER: API_URL + 'purchaseOrders',
    INVOICEDETAILS: API_URL + 'invoiceDetails',
    CONTRACTS: API_URL + 'contracts',
    INSURANCETYPE: API_URL + 'insuranceTypes',
    TEAM_LEAD_SCHEDULE: API_URL + 'teamLeadSchedules',
    BillingDashboard: API_URL + 'cases/status/techCompleted',
    BillingStatusUpdate: API_URL + 'cases/',
    EXTERNALSCHEDULERCASE: API_URL + 'externalSchedulerCase',
    USERLPROFILE: API_URL + 'userDetails/',
    PATIENTEMPLOYMENTSTATUS: API_URL + 'patientEmploymentStatuses',
    INSURANCECARRIERGROUPS: API_URL + 'insuranceCarrierGroups',
    INSUREDRELATIONSHIP: API_URL + 'insuredRelationships',
    CARRIERCATEGORY: API_URL + 'carrierCategories',
    WEBSOCKETNOTIFICATION: WEB_SOCKET_URL,
    TIMEZONE: API_URL + 'time-zone',
    RESPONSIBLEPARTY: API_URL + 'responsibleParty',
    ACTIVEPROCEDURETYPES: API_URL + 'competences/activeCompetences',
    SCHEDULE: API_URL + 'schedule',
    SCHEDULESTATUSCHANGE: API_URL + 'event/change-status',
    SCHEDULEDELETE: API_URL + 'event/delete',
    SCHEDULEFILTER: API_URL + 'event/all-events'
  }
};
