import { AfterContentInit, Directive, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appItemsPerPage]'
})
export class ItemsPerPageDirective implements OnInit, AfterContentInit {
  @Input() itemsPerPageOptions: any[];
  @Output() itemsPerPageChanged: EventEmitter<number> = new EventEmitter();
  eventEmitted = false;

  constructor(private elem: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {}

  ngAfterContentInit() {
    this.eventEmitted = false;
    if (this.itemsPerPageOptions && this.itemsPerPageOptions.length > 0) {
      const inputElem = this.elem.nativeElement;
      const parentElem = inputElem.parentElement;
      const parentElemRow = parentElem.parentElement;

      const itemsPerPageParentRow = this.renderer.createElement('div');
      // add these classes col-sm-12 col-md-3 offset-6 col-lg-3 results-page-wrapper
      this.renderer.addClass(itemsPerPageParentRow, 'col');
      this.renderer.addClass(itemsPerPageParentRow, 'offset-2');
      this.renderer.addClass(itemsPerPageParentRow, 'results-page-wrapper');

      const mainDiv = this.renderer.createElement('div');
      this.renderer.addClass(mainDiv, 'row');

      const itemsPerPageResultsLabel = this.renderer.createElement('div');
      this.renderer.addClass(itemsPerPageResultsLabel, 'col-sm-5');
      this.renderer.addClass(itemsPerPageResultsLabel, 'col-md-7');
      this.renderer.addClass(itemsPerPageResultsLabel, 'results-label');
      this.renderer.addClass(itemsPerPageResultsLabel, 'text-right');
      this.renderer.addClass(itemsPerPageResultsLabel, 'p-r-0');
      const itemsPerPageResultsLabelSpan = this.renderer.createElement('span');
      const text = this.renderer.createText('Display ');
      this.renderer.appendChild(itemsPerPageResultsLabelSpan, text);

      const itemSelectDiv = this.renderer.createElement('div');
      this.renderer.addClass(itemSelectDiv, 'col-sm-6');
      this.renderer.addClass(itemSelectDiv, 'col-md-5');

      const itemSelect = this.renderer.createElement('select');
      this.renderer.addClass(itemSelect, 'form-control');
      this.renderer.addClass(itemSelect, 'items-per-page-option');
      this.renderer.addClass(itemSelect, 'form-control-sm');

      this.itemsPerPageOptions.forEach(itemsPerPageOption => {
        const itemOption = this.renderer.createElement('option');
        const itemOptionValue = this.renderer.createText(itemsPerPageOption);
        this.renderer.appendChild(itemOption, itemOptionValue);
        itemOption.setAttribute('value', itemsPerPageOption);
        this.renderer.appendChild(itemSelect, itemOption);
      });

      this.renderer.listen(itemSelect, 'change', evt => {
        if (evt && evt.target && evt.target.value) {
          this.itemsPerPageChanged.next(Number(evt.target.value));
        } else {
          this.itemsPerPageChanged.next(this.itemsPerPageOptions[0]);
        }
        this.eventEmitted = true;
      });

      this.renderer.appendChild(itemsPerPageResultsLabel, itemsPerPageResultsLabelSpan);
      this.renderer.appendChild(itemSelectDiv, itemSelect);

      this.renderer.appendChild(mainDiv, itemsPerPageResultsLabel);
      this.renderer.appendChild(mainDiv, itemSelectDiv);
      this.renderer.appendChild(itemsPerPageParentRow, mainDiv);
      this.renderer.insertBefore(parentElemRow, itemsPerPageParentRow, parentElem);
      if (!this.eventEmitted) {
        this.itemsPerPageChanged.next(this.itemsPerPageOptions[0]);
      }
    }
  }
}
