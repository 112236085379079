import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'separateWithComa'
})
export class SeparateWithComaPipe implements PipeTransform {

  transform(value: any, property: any): any {
    return value.map(object => object[property]).join(', ');
  }

}


@Pipe({ name: 'truncate' })
export class Truncate implements PipeTransform {
  transform(value: string, args: string): string {
    const limit = args ? parseInt(args, 10) : 10;
    const trail = '...';

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
