import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AttorneyLayoutComponent } from './layouts/attorney-layout/attorney-layout.component';
import { OutsideLayoutComponent } from './layouts/outside-layout/outside-layout.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';
import { UnauthoriseWallComponent } from './shared/components/unauthorise-wall/unauthorise-wall.component';
import { AuthGuardService } from './shared/services/auth-guard.service';

const routes: Routes = [
  {
    path: 'welcome',
    component: WelcomeLayoutComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'unauthorise',
    component: UnauthoriseWallComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    component: OutsideLayoutComponent,
    loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'schedule',
    component: AttorneyLayoutComponent,
    canActivate: [AuthGuardService],
    loadChildren: () => import('./schedule/schedule.module').then(m => m.ScheduleModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
