export class PageTitle {
  public static company_info = 'Company Information';

  // Company Name...
  public static company_name = ' - ANM';

  // Error...
  public static error_404 = '404 Error';

  // Authentication...
  public static login = 'Login';
  public static register = 'Register';
  public static forgot_password = 'Forgot Password';
  public static reset_password = 'Reset Password';

  // User-management...
  public static user_list = 'USERS';
  public static user_create = 'NEW USER';
  public static user_update = 'UPDATE USER';
  public static user_detail = 'USER INFORMATION';
  public static user_group_list = 'USER GROUPS';
  public static user_group_detail = 'USER GROUP DETAILS';
  public static team_list = 'TEAMS';
  public static team_detail = 'TEAM DETAIL';

  // Region-management...
  public static region_list = 'REGIONS';
  public static region_detail = 'REGION DETAIL';
  public static call_region_list = 'CALL REGIONS';
  public static callRegion_detail = 'CALL REGION DETAIL';

  // Company-management
  public static company_profile = 'COMPANY INFORMATION';
  public static company_update = 'UPDATE COMPANY INFORMATION';
  public static entity_list = 'ENTITIES';

  // System-management
  public static system_list = 'HOSPITAL SYSTEMS';
  public static system_detail = 'HOSPITAL SYSTEM DETAIL';
  public static hospital_list = 'HOSPITALS';
  public static hospital_add = 'NEW HOSPITAL';
  public static hospital_update = 'UPDATE HOSPITAL';
  public static operating_room_list = 'OPERATING ROOMS';
  public static hospital_detail = 'HOSPITAL DETAIL';
  public static practice_list = 'SURGEON PRACTICES';
  public static practice_detail = 'SURGEON PRACTICE DETAIL';
  public static doctor_list = 'SURGEONS';
  public static location_list = 'LOCATIONS';
  public static patient_list = 'PATIENTS';
  public static create_patient = 'NEW PATIENT';
  public static update_patient = 'UPDATE PATIENT';
  public static patient_detail = 'PATIENT DETAIL';

  // Vendor-management
  public static vendor_list = 'VENDORS';
  public static part_list = 'PARTS';

  // Case-management
  public static case_list = 'CASES';
  public static case_create = 'NEW CASES';
  public static case_update = 'UPDATE CASES';
  public static case_detail = 'CASE DETAIL';
  public static tech_report = 'TECH REPORT';
  public static billing_detail = 'BILLING DETAIL';
  public static tentative_case = 'TENTATIVE CASE SCHEDULE';

  // Attorney-management
  public static attorney_list = 'ATTORNEY';

  // dashboards
  public static schedular_dashboard = 'SCHEDULER DASHBOARD';
  public static billing_dashboard = 'BILLING DASHBOARD';

  // TechWidget-management
  public static techWidget = 'TechWidget';

  //schedule
  public static practiceSchedule = 'Practice Schedule';
}

export class Button {
  public static please_wait = 'Please wait';

  // Authentication...
  public static sign_in = 'Sign In';
  public static sign_up = 'Sign Up';
  public static forgot_password = 'Forgot Password?';
  public static reset = 'Reset';
  public static back_to_login = 'Back to Login';

  // Extra...
  public static next = 'Next';
  public static add = 'Add';
  public static remove = 'Remove';
  public static schedule = 'Schedule';
  public static assign = 'Assign Ticket';
  public static addtolist = 'Add to list';
  public static submit = 'Submit';
  public static save = 'Save';
  public static saveAndEmail = 'Save & Send Email';
  public static update = 'Update';
  public static continue = 'Continue';
  public static savecontinue = 'Save & Continue';
  public static refresh = 'Refresh';
  public static addaddCheckList = 'Add CheckList';
  public static addExternalScheduler = 'Add External Scheduler';
  public static search = 'Search';
  public static select = 'Select';
  public static skip = 'Skip';
  public static confirm = 'Confirm';
  public static edit = 'Edit';

  // Cancel & Close...
  public static cancel = 'Cancel';
  public static close = 'Close';

  // Retry...
  public static retry = 'Retry';

  // User-management
  public static addUser = 'Add User';
  public static addUserGroup = 'Add User Group';
  public static updateUserGroup = 'Update User Group';
  public static addTeam = 'Add Team';

  // Region-management
  public static addRegion = 'Add Region';
  public static addCallRegion = 'Add Call Region';

  // Company-management
  public static addEntity = 'Add Entity';

  // System-management
  public static addSystem = 'Add Hospital System';
  public static addHospital = 'Add Hospital';
  public static assignHospital = 'Assign Hospital';
  public static addOperatingRoom = 'Add Operating Room';
  public static addDoctor = 'Add Surgeon';
  public static updateDoctor = 'Update Surgeon';
  public static addLocation = 'Add Location';
  public static addExternalSchedular = 'Add External Schedular';
  public static addPatient = 'Add Patient';
  public static addPrimaryInsurance = 'Add Primary Insurance';
  public static addSecondaryInsurance = 'Add Secondary Insurance';
  public static addPatientInsurance = 'Add Patient Insurance';
  public static addPurchaseOrder = 'Add Purchase Order';
  public static updatePurchaseOrder = 'Update Purchase Order';
  public static addInvoice = 'Add Invoice';
  public static updateInvoice = 'Update Invoice';
  public static addContract = 'Add Contract';
  public static updateContract = 'Update Contract';

  // Vendor-management
  public static addVendor = 'Add Vendor';
  public static addPart = 'Add Part';
  public static addPractice = 'Add Surgeon Practice';

  // Attorney-management
  public static addAttorney = 'Add Attorney';

  // Case-management
  public static addCase = 'Add Case';
  public static listView = 'List View';
  public static calendarView = 'Calendar View';
  public static defaultCalendarView = 'Default';
  public static surgeonCalendarView = 'By Surgeon';
  public static techCalendarView = 'By Tech';

  public static updatePatient = 'Update Patient';

  public static patientInTime = 'Inovice Start Time';
  public static patientOutTime = 'Invoice Stop Time';
  public static patientInOutTime = 'Invoice Start/Stop Time';

  public static iomStartTime = 'Data Start Time';
  public static iomStopTime = 'Data Stop Time';
  public static iomStartStopTime = 'Data Start/Stop Time';

  public static procedureInfo = 'Procedure Info';
  public static techCompleted = 'Tech Completed';
  public static techSummary = 'Tech Complete';
  public static changesAlertModality = 'Changes/Alert Modality';
  public static billingDetails = 'Modalities Details';
  public static uploadFiles = 'Upload Files';
  public static monitoringSupplies = 'Monitoring Supplies';
  public static diagnosisCodeInfo = 'Diagnosis Code';

  public static pendingCase = 'Pending';
  public static confirmCase = 'Confirm';
  public static cancelCase = 'Cancel';

  public static techWidget = 'Tech Widget';
  public static assignTech = 'Assign';

  public static insuranceDetailTab = 'Insurance Detail';
  public static monitoringSuppliesTab = 'Monitoring Supplies';
  public static teamLeadComplete = 'Teamlead Complete';

  public static loadMore = 'Load More';
}

export class Message {
  public static add_user = 'You have successfully created new user.';
  public static email_send = 'Email sent successfully.';
  public static password_update = 'Password updated successfully.';
  public static assignTeamLead = 'You Can Assign Team Lead Here Once You Add Tech In This Region.';
  public static assignTeamLeadDanger = 'You have Not Assign Team Lead Please Go To Update Region And Assign Team Lead.';
  public static vendorAlreadyExist = 'Vendor Name Already Exists.';
  public static spamEmailMessage = 'reset email may go to spam and you should set your email to allow do-not-reply@accurateiom.com';
}

export class DatePickerConstant {
  public static maxYear = 1990;
  public static dateFormate = 'MM/dd/yyyy';
  public static dateFormate2 = 'mm-dd-yy';
  public static dateFormat3 = 'M/d/yy';
  public static dayFormat = 'EEEE';
  public static friday = 'Friday';
  public static timeFormat = 'hh:mm';
  public static amPmFormat = 'a';
  public static bsConfig = Object.assign(
    {},
    { containerClass: 'theme-blue', dateInputFormat: 'MM/DD/YYYY', showWeekNumbers: false, adaptivePosition: true }
  );
  public static bsRangeConfig = Object.assign({}, { containerClass: 'theme-blue', rangeInputFormat: 'MM/DD/YYYY', showWeekNumbers: false });
}

export class InsuranceTypeConst {
  public static primary = 'PRIMARY';
  public static secondary = 'SECONDARY';
}

export class InsuredRelationshipType {
  public static insuredRelationshipTypeList = [{ name: 'Self' }, { name: 'Spouse' }, { name: 'Child' }, { name: 'Other' }];
}

export class ModalParamSaveUpdate {
  public static save = 'save';
  public static update = 'update';
}

export class MultipleDropDownConst {
  public static selectAll = 'Select All';
  public static unSelectAll = 'UnSelect All';
  public static selectHospital = 'Select Hospital';
  public static selectSurgeon = 'Select Surgeon';
  public static selectProcedureType = 'Select Procedure Type';
  public static selectModalitie = 'Select Modalitie';
  public static selectCompany = 'Select Company';
  public static selectState = 'Select State';
  public static selectInsuranceType = 'Select InsuranceType';
  public static selectRegion = 'Select Region';
  public static selectReader = 'Select Reader';
  public static selectTech = 'Select Tech';
  public static selectMonitoringSupplies = 'Select Monitoring Supplies';
  public static selectCompetence = 'Select Competence';
  public static selectLeftMuscles = 'Select Left Muscles';
  public static selectRightMuscles = 'Select Right Muscles';
  public static selectbaselineObtainedMuscles = 'Select Baseline Obtained Muscles';
  public static selectEmgCranialNervesSettingss = 'Select Emg Carnial Nerves';
  public static selectEmgExtremitiesSettingss = 'Select Emg Extremities';
  public static selectOtherMusclesSettingss = 'Select Other Muscles';
  public static selectPedicalScrewTestSettingss = 'Select Pedical screw';
  public static selectAttorney = 'Select Attorney';
  public static selectPrimaryInsurance = 'Select Primary Insurance';
  public static selectSecondaryInsurance = 'Select Secondary Insurance';
  public static selectDiagnosisCode = 'Select Diagnosis Code';
  public static selectOperatingRoom = 'Select Operating Room';
  public static selectExistingInsurance = 'Select Existing Insurance';
  public static selectCarrierCategory = 'Select Financial Class';
  public static selectInsuredRelationship = 'Select Insured Relationship';
  public static selectInsuranceCarrierGroup = 'Select Carrier Name';
  public static selectPractice = 'Select Practice';
}

export class MultipleDropDownSettings {
  public static getSingleSettings(text: String, enableSearchFilter: Boolean) {
    const settings = {
      singleSelection: true,
      text: text,
      enableSearchFilter: enableSearchFilter,
      primarykey: 'id'
    };
    return settings;
  }
  public static getMultipleSettings(
    text: String,
    selectAllText: String,
    unSelectAllText: String,
    enableSearchFilter: Boolean,
    primaryKey?: String
  ) {
    const settings = {
      singleSelection: false,
      text: text,
      selectAllText: selectAllText,
      unSelectAllText: unSelectAllText,
      enableSearchFilter: enableSearchFilter,
      primarykey: primaryKey ? primaryKey : 'id'
    };
    return settings;
  }
}
