import { Pipe, PipeTransform } from '@angular/core';
import { GetCaseStatusColorByStatus } from '../constants/common.constant';

@Pipe({
  name: 'caseStatusColor'
})
export class CaseStatusColorPipe implements PipeTransform {

  transform(value: string): any {
    return GetCaseStatusColorByStatus[value];
  }

}
