import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppSpinnerComponent } from './components/app-spinner/app-spinner.component';
import { BlankWallComponent } from './components/blank-wall/blank-wall.component';
import { ErrMsgComponent } from './components/err-msg/err-msg.component';
import { UnauthoriseWallComponent } from './components/unauthorise-wall/unauthorise-wall.component';
import { DataExtract } from './constants/helper';
import { ItemsPerPageDirective } from './directives/items-per-page.directive';
import {
  AlphaNumericValidator,
  EmailValidator,
  EqualValidator,
  InputRestrictionDirective,
  IsNumberDirective,
  PasswordValidator,
  PhoneValidator,
  WebValidator
} from './directives/validation.directive';
import { CaseStatusColorPipe } from './pipes/case-status-color.pipe';
import { ConfirmedStatusPipe } from './pipes/confirmed-status.pipe';
import { CopyrightPipe } from './pipes/copyright.pipe';
import { FindByIdPipe } from './pipes/find-by-id.pipe';
import { GetDurationOfProcedurePipe } from './pipes/get-duration-of-procedure.pipe';
import { InsuranceCarrierAddressPipe } from './pipes/insurance-carrier-address.pipe';
import { LocationPipe } from './pipes/location.pipe';
import { MakeDurationOfProcedurePipe } from './pipes/make-duration-of-procedure.pipe';
import { NotAssignedPipe } from './pipes/not-assigned.pipe';
import { PatientInsurancePipe } from './pipes/patient-insurance.pipe';
import { SeparateWithComaPipe, Truncate } from './pipes/separate-with-coma.pipe';
import { ShowCaseStatusPipe } from './pipes/show-case-status.pipe';
import { SsnPipe } from './pipes/ssn.pipe';
import { TbdTimePipe } from './pipes/tbd-time.pipe';
import { AdminGuardService, AuthGuardService, NonAuthGuardService } from './services/auth-guard.service';
import { DataService } from './services/data.service';
import { SharedService } from './services/shared.service';
import { StorageService } from './services/storage.service';
import { UtillService } from './services/utill.service';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  providers: [
    StorageService,
    SharedService,
    DataService,
    DatePipe,
    UtillService,
    AuthGuardService,
    NonAuthGuardService,
    DataExtract,
    AdminGuardService
  ],
  declarations: [
    BlankWallComponent,
    ErrMsgComponent,
    IsNumberDirective,
    EmailValidator,
    PhoneValidator,
    PasswordValidator,
    EqualValidator,
    WebValidator,
    AlphaNumericValidator,
    InputRestrictionDirective,
    SsnPipe,
    UnauthoriseWallComponent,
    GetDurationOfProcedurePipe,
    MakeDurationOfProcedurePipe,
    SeparateWithComaPipe,
    Truncate,
    FindByIdPipe,
    CaseStatusColorPipe,
    NotAssignedPipe,
    AppSpinnerComponent,
    ConfirmedStatusPipe,
    PatientInsurancePipe,
    InsuranceCarrierAddressPipe,
    ShowCaseStatusPipe,
    TbdTimePipe,
    CopyrightPipe,
    ItemsPerPageDirective,
    LocationPipe
  ],
  exports: [
    ErrMsgComponent,
    IsNumberDirective,
    EmailValidator,
    PhoneValidator,
    PasswordValidator,
    EqualValidator,
    WebValidator,
    AlphaNumericValidator,
    InputRestrictionDirective,
    SsnPipe,
    GetDurationOfProcedurePipe,
    SeparateWithComaPipe,
    Truncate,
    FindByIdPipe,
    CaseStatusColorPipe,
    NotAssignedPipe,
    AppSpinnerComponent,
    ConfirmedStatusPipe,
    PatientInsurancePipe,
    InsuranceCarrierAddressPipe,
    ShowCaseStatusPipe,
    TbdTimePipe,
    CopyrightPipe,
    ItemsPerPageDirective,
    LocationPipe
  ]
})
export class SharedModule {}
