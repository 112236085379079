import { MultipleDropDownConst } from './message.constants';

export const WeekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
export const employmentTypeList = [
  { name: 'employmentTypeList', value: 'FullTime', displayName: 'Full Time' },
  { name: 'employmentTypeList', value: 'PartTime', displayName: 'Part Time' }
];

export const CaseStatusList = [{ name: 'Pending' }, { name: 'Confirm' }, { name: 'Delay' }, { name: 'Cancel' }];

export const regionSettings = {
  singleSelection: false,
  text: 'Select Regions',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  enableSearchFilter: true,
  labelKey: 'name',
  badgeShowLimit: 1
};

export const techSettings = {
  singleSelection: false,
  text: 'Select Techs',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  enableSearchFilter: true,
  labelKey: 'name',
  badgeShowLimit: 2
};

export const readerSettings = {
  singleSelection: false,
  text: 'Select Readers',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  enableSearchFilter: true,
  labelKey: 'name',
  badgeShowLimit: 2
};

export const MultipleProcedureSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectProcedureType,
  enableSearchFilter: true,
  primaryKey: 'procedureTypeId',
  badgeShowLimit: 2,
  labelKey: 'name'
};

export const MultipleModalitieSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectModalitie,
  enableSearchFilter: true,
  primaryKey: 'modalitieId',
  badgeShowLimit: 2
};

export const MultipleSurgeonSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectSurgeon,
  enableSearchFilter: true,
  badgeShowLimit: 2
};

export const MultipleStateSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectState,
  enableSearchFilter: true,
  labelKey: 'name',
  badgeShowLimit: 2
};

export const SingleCompanySettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectCompany,
  enableSearchFilter: true
};

export const SingleHospitalSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectHospital,
  enableSearchFilter: true,
  disabled: true
};

export const ALL_HOSPITAL_SETTINGS = {
  singleSelection: true,
  text: MultipleDropDownConst.selectHospital,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const SingleSurgeonSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectSurgeon,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const SingleReaderSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectReader,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const SingleTechSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectTech,
  enableSearchFilter: true,
  labelKey: 'name',
  groupBy: 'regionName'
};

export const leftMuscleSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectLeftMuscles,
  enableSearchFilter: true,
  labelKey: 'name',
  badgeShowLimit: 5
};

export const rightMuscleSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectRightMuscles,
  enableSearchFilter: true,
  labelKey: 'name',
  badgeShowLimit: 5
};

export const leftMuscleSettingsForSsep = {
  singleSelection: true,
  text: MultipleDropDownConst.selectLeftMuscles,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const rightMuscleSettingsForSsep = {
  singleSelection: true,
  text: MultipleDropDownConst.selectRightMuscles,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const baselineObtainedMusclesSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectbaselineObtainedMuscles,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const pedicalScrewTestSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectPedicalScrewTestSettingss,
  enableSearchFilter: true,
  labelKey: 'name',
  badgeShowLimit: 5
};

export const shedularListSettings = {
  singleSelection: false,
  text: 'Select Schedulars',
  selectAllText: 'Select All',
  unSelectAllText: 'UnSelect All',
  enableSearchFilter: true
};

export const TechWidgetPatientWindowWidth = 1300;
export const DefaultTechWidgetWindowWidth = 300;
export const CommonTechWidgetWindowWidth = 800;
export const CommonTechWidgetWindowHeight = 650;
export const CalendarDayView = 'day';
export const CalendarWeekView = 'week';
export const CalendarMonthView = 'month';
export const TechWidgetWindowName = 'techWidgetWindow';
export const TechWidgetURL = 'http://dev.accuratemonitoring.com/#/admin/tech-widget-list/';
// export const TechWidgetURL = 'http://localhost:4200/#/admin/tech-widget-list/';
export const TechWidgetWindowFeature =
  'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,' +
  'status=yes,resizable=no,width=' +
  DefaultTechWidgetWindowWidth +
  ',height=' +
  CommonTechWidgetWindowHeight;

export const GetCaseStatusColorByStatus = {
  Pending: 'badge badge-warning',
  Confirmed: 'badge badge-success',
  Delay: 'badge badge-warning',
  Cancel: 'badge badge-danger',
  Completed: 'badge label-violet',
  Failed: 'badge badge-failed',
  Rejected: 'badge badge-rejected'
};

export const MonitoringSuppliesSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectMonitoringSupplies,
  enableSearchFilter: true,
  primaryKey: 'partId',
  badgeShowLimit: 2,
  labelKey: 'name'
};

export const PendingCaseStatus = 'Pending';
export const ConfirmCaseStatus = 'Confirmed';
export const CancelCaseStatus = 'Cancel';
export const DelayCaseStatus = 'Delayed';
export const RequestedCaseStatus = 'Requested';
export const TechCompleteCaseStatus = 'Tech Completed';
export const TechInCompleteCaseStatus = 'Tech Incompleted';
export const ReadyToTransferCaseStatus = 'Ready To Transfer';
export const FailedCaseStatus = 'Failed';
export const TransferredCaseStatus = 'Transferred';
export const CompletedStatus = 'Completed';

export const PrecertRequired = 'Precert Required';
export const PrecertApproved = 'Precert Approved';
export const PrecertRejected = 'Precert Rejected';

export const UpdateCaseStatusList = [RequestedCaseStatus, PendingCaseStatus];
export const ShowCaseStatusList = UpdateCaseStatusList.concat([ConfirmCaseStatus, CancelCaseStatus]);

export const SingleCompetenceSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectCompetence,
  enableSearchFilter: true,
  badgeShowLimit: 2,
  labelKey: 'name'
};

export const SeprateWithCommaPipeConst = {
  name: 'name',
  diagnosisCodeValue: 'diagnosisCodeValue'
};

export const FindByIdPipeConst = {
  insuranceName: 'insuranceName',
  name: 'name'
};

export const CalendarViewTechAssign = [{ name: 'Assign Tech' }, { name: 'Assign Trainee Tech' }];

export const proceduresDemo = [
  {
    id: 19,
    anmCaseId: 4,
    procedureTypeId: 1,
    name: 'Anterior'
  },
  {
    id: 20,
    anmCaseId: 4,
    procedureTypeId: 2,
    name: 'Min Invasive'
  },
  {
    id: 21,
    anmCaseId: 4,
    procedureTypeId: 3,
    name: 'Cortex'
  },
  {
    id: 22,
    anmCaseId: 4,
    procedureTypeId: 4,
    name: 'Posterior'
  },
  {
    id: 23,
    anmCaseId: 4,
    procedureTypeId: 5,
    name: 'SC Stimulator'
  }
];

export const partSource = [
  // { 'name': 'Select Source', 'value': null },
  { name: 'Hospital', value: 'HOSPITAL' },
  { name: 'ANM', value: 'ACCURATE_NEUROMONITORING' }
];

export const billingStatus = [
  { name: 'DEMO_VERIFY', value: 'DemoVerify', display: 'Demo Verified', valid: 'isDemoVerified' },
  { name: 'PROCEDURE_VERIFY', value: 'ProcedureVerify', display: 'Procedure Verified', valid: 'isProcedureVerified' },
  { name: 'TRANSFERED_TO_MD ', value: 'TransferedToMD', display: 'Transfered to M.D', valid: 'isTransferAdvancedMd' }
];

export const caseStatus = [{ name: 'Pending' }, { name: 'Confirmed' }, { name: 'Rejected' }, { name: 'Cancel' }, { name: 'Completed' }];

export const CalendarViewUnAssignTech = { id: 0, name: 'Unassigned', itemName: 'Unassigned' };

export const SingleInsuranceTypeSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectInsuranceType,
  enableSearchFilter: true,
  labelKey: 'name',
  disabled: false
};

export const SingleInsuranceTypeDisabledSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectInsuranceType,
  enableSearchFilter: true,
  labelKey: 'name',
  disabled: true
};

export const SingleStateSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectState,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const SingleAttorneySettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectAttorney,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const CheckInsuranceType = ['Auto', 'Workers Comp', 'Attorney'];

export const SinglePrimaryInsuranceSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectPrimaryInsurance,
  enableSearchFilter: true,
  labelKey: 'policyNumber',
  primaryKey: 'policyNumber'
};

export const MultipleSecondaryInsuranceSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectSecondaryInsurance,
  enableSearchFilter: true,
  labelKey: 'policyNumber',
  primaryKey: 'policyNumber',
  badgeShowLimit: 1
};

export const MultipleDiagnosisCodeSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectDiagnosisCode,
  enableSearchFilter: true,
  primaryKey: 'diagnosisCodeId',
  badgeShowLimit: 2,
  labelKey: 'diagnosisCodeValue'
};

export const SurgeonMultiSelect = {
  singleSelection: false,
  text: 'Select Surgeons',
  enableSearchFilter: true,
  badgeShowLimit: 2,
  labelKey: 'name'
};

export const ProcedureMultiSelect = {
  singleSelection: false,
  text: 'Select Procedures',
  enableSearchFilter: true,
  badgeShowLimit: 2,
  labelKey: 'name',
  primaryKey: 'id'
};

export const SingleOperatingRoomSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectOperatingRoom,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const NotificationWithPage = 'notificationWithPage';

export const BsDateConfig = Object.assign({}, { containerClass: 'theme-blue', dateInputFormat: 'MM/DD/YYYY' });

export const DisableSingleCompanySettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectCompany,
  enableSearchFilter: true,
  disabled: true
};

export const DisableSingleInsuranceTypeSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectInsuranceType,
  enableSearchFilter: true,
  labelKey: 'name',
  disabled: true
};

export const DisableSingleStateSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectState,
  enableSearchFilter: true,
  labelKey: 'name',
  disabled: true
};

export const DisableSingleAttorneySettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectAttorney,
  enableSearchFilter: true,
  labelKey: 'name',
  disabled: true
};

export const AMPMDropDown = [{ value: 'AM' }, { value: 'PM' }];

export const intervalTime = [
  { time: '01:00' },
  { time: '01:15' },
  { time: '01:30' },
  { time: '01:45' },
  { time: '02:00' },
  { time: '02:15' },
  { time: '02:30' },
  { time: '02:45' },
  { time: '03:00' },
  { time: '03:15' },
  { time: '03:30' },
  { time: '03:45' },
  { time: '04:00' },
  { time: '04:15' },
  { time: '04:30' },
  { time: '04:45' },
  { time: '05:00' },
  { time: '05:15' },
  { time: '05:30' },
  { time: '05:45' },
  { time: '06:00' },
  { time: '06:15' },
  { time: '06:30' },
  { time: '06:45' },
  { time: '07:00' },
  { time: '07:15' },
  { time: '07:30' },
  { time: '07:45' },
  { time: '08:00' },
  { time: '08:15' },
  { time: '08:30' },
  { time: '08:45' },
  { time: '09:00' },
  { time: '09:15' },
  { time: '09:30' },
  { time: '09:45' },
  { time: '10:00' },
  { time: '10:15' },
  { time: '10:30' },
  { time: '10:45' },
  { time: '11:00' },
  { time: '11:15' },
  { time: '11:30' },
  { time: '11:45' },
  { time: '12:00' },
  { time: '12:15' },
  { time: '12:30' },
  { time: '12:45' }
];

export const MultipleInsuranceSettings = {
  singleSelection: false,
  text: MultipleDropDownConst.selectExistingInsurance,
  enableSearchFilter: true,
  labelKey: 'policyNumber',
  primaryKey: 'policyNumber',
  badgeShowLimit: 1
};

export const PatientInsuranceMessage = {
  policyClaimNumber: 'Policy/Claim Number',
  payorType: 'Payer Type',
  carrierGroup: 'Carrier Name',
  payorName: 'Carrier Address',
  dateOfAccident: 'Date of Accident',
  stateOfAccedent: 'State of Accident',
  attorney: 'Attorney',
  insuredRelationship: 'Insured Relationship'
};

export const monthDate = 'MMM d, y';
export const monthDateAndHour = 'M/d/yy, h:mm a';

export const SMDFileExtension = 'smd';
export const IOMDATAFileExtension = 'iomdata';
export const SMDTagInformation = {
  id: 1,
  name: 'System File'
};
export const OtherTagId = 12;

export class PatientInsuranceTabConst {
  public static patientInsuranceTab = 'patientInsurance';
  public static addInsuranceCaeeierTab = 'addInsuranceCarrier';
  public static addPatientInsuranceInformation = 'Add Patient Insurance Information';
  public static updatePatientInsuranceInformation = 'Update Patient Insurance Information';
  public static addInsuranceCarrierInformation = 'Create Insurance Carrier';
  public static addPatientInsurance = 'Add Patient Insurance';
  public static updatePatientInsurance = 'Update Patient Insurance';
  public static updateInsuranceCarrierInformation = 'Update Insurance Carrier';
}

export const InsuranceCarrierMessage = {
  planName: 'Plan Name',
  carrierNumber: 'Carrier Number',
  address1: 'Address 1',
  address2: 'Address 2',
  city: 'City',
  postalCode: 'Postal Code',
  web: 'Web',
  fax: 'Fax',
  phone1: 'Phone 1',
  phone2: 'Phone 2',
  state: 'State',
  carrierCategory: 'Financial Class'
};

export const TypeAheadDropDownLimit = 5;

export const ZipCode = {
  length: 10,
  masking: 'AAAAA-AAAA'
};

export const SingleInsuranceCarrierGroupSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectInsuranceCarrierGroup,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const SingleInsuredRelationshipSettings = {
  singleSelection: true,
  text: MultipleDropDownConst.selectInsuredRelationship,
  enableSearchFilter: true,
  labelKey: 'name'
};

export const PatientInOutUnknown = ['IN', 'OUT', 'UNKNOWN'];

export const DefaultInsuredRelationship = {
  id: 1,
  name: 'Self'
};

export const DATE_OF_ADMISSION_TITLE = 'Date of Admission';
export const MMDDYYYY = 'MM/dd/yyyy';
export const ZEROTIME = 'T00:00:00';
export const YYYYMMDD = 'YYYY-MM-DD';
export const DEFAULT_TIMEZONE_AREA = 'America/New_York';

export const PracticeSelect = {
  singleSelection: true,
  text: 'Select Practice',
  enableSearchFilter: true,
  badgeShowLimit: 2,
  labelKey: 'name'
};
export const LocationSelect = {
  singleSelection: false,
  text: 'Select location',
  enableSearchFilter: true,
  badgeShowLimit: 2,
  labelKey: 'name'
};

export const MedicalQuestions = {
  yesOption: { display: 'Yes', value: 'yes' },
  noOption: { display: 'No', value: 'no' },
  noKnownAllergiesOption: { display: 'No Known allergies', value: 'No Known allergies' },
  medicalAllergyOption: { display: 'Medical allergies', value: 'Medical allergies' },
  latexAllergyOption: { display: 'Latex allergy/sensitivity', value: 'Latex allergy/sensitivity' }
};
