<div class="row border-bottom white-bg">
  <div class="innerNavHeader row">
    <div class="col-xs-12 no-padding-space">
      <p class="m-b-none m-t-xs">{{ headerTitle }}</p>
    </div>
  </div>
</div>
<div class="wrapper wrapper-content myWrapper">
  <div class="row">
    <div class="col-sm-12 innerBox">
      <div class="ibox float-e-margins m-l-xs m-r-xs" id="ibox">
        <div class="ibox-content">
          <app-spinner></app-spinner>
          <div class="form-horizontal" *ngIf="success">
            <form #newCaseForm="ngForm" (ngSubmit)="newCaseForm?.form?.valid && addCase(newCaseForm)">
              <div class="ibox-title sub-header">
                Procedure Information
              </div>
              <div class="ibox-content clearfix">
                <div class="row">
                  <div class="col-lg-6 col-md-6">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                          <label for="dateOfSurgery" class="col-form-label">Date of Surgery </label>
                        </div>
                        <div class="col-sm-8 row">
                          <div class="col-md-4 col-sm-5 col-5 p-r-5">
                            <input
                              type="text"
                              autofocus
                              class="form-control"
                              name="dateOfSurgery"
                              #dateOfSurgery="ngModel"
                              bsDatepicker
                              container=""
                              [(ngModel)]="actualDateOfSurgeryDate"
                              autocomplete="off"
                              [bsConfig]="bsConfig"
                            />
                          </div>
                          <div class="col-md-3 col-sm-3 col-3 p-l-0 p-r-5">
                            <select
                              class="form-control"
                              name="dateOfSurgeryTime"
                              #dateOfSurgeryTime="ngModel"
                              [(ngModel)]="actualDateOfSurgeryTimeDropDown"
                              [disabled]="caseDetail.isTBD"
                            >
                              <option *ngFor="let time of dateOfSurgeryList; trackBy: trackByFunction" [ngValue]="time?.time"
                                >{{ time?.time }}
                              </option>
                            </select>
                          </div>
                          <div class="col-md-3 col-sm-3 col-3 p-l-0">
                            <select
                              class="form-control"
                              name="dateOfSurgeryTimeAmPm"
                              #dateOfSurgeryTimeAmPm="ngModel"
                              [(ngModel)]="actualDateOfSurgeryTimeAM_PMDropDown"
                              [disabled]="caseDetail.isTBD"
                            >
                              <option *ngFor="let timeFormat of timeAMPMdropDown; trackBy: trackByFunction" [ngValue]="timeFormat?.value">
                                {{ timeFormat?.value }}</option
                              >
                            </select>
                          </div>
                          <div class="col-md-2 col-sm-1 col-1">
                            <div class="form-check form-check-inline col-form-label">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                [(ngModel)]="caseDetail.isTBD"
                                (change)="onTBDChange(caseDetail.isTBD)"
                                name="isTBD"
                              />
                              <label class="form-check-label p-l-0" for="isTBD">TBD</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                          <label class="col-form-label">Est. Duration Of Procedure </label>
                        </div>
                        <div class="col-sm-8">
                          <select
                            class="form-control w-100"
                            name="durationOfProcedure"
                            #durationOfProcedure="ngModel"
                            [(ngModel)]="caseDetail.durationOfProcedure"
                          >
                            <option *ngFor="let time of estTimeDurationList" [value]="time.time">{{ time?.time }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                          <label class="col-form-label">Practice<small class="mandatory"></small> </label>
                        </div>
                        <div class="col-sm-8">
                          <ng-select
                            [items]="practiceList"
                            bindLabel="name"
                            bindValue="id"
                            (change)="onSelectPractice($event)"
                            (clear)="onDeselectPractice()"
                            name="practice"
                            #practice="ngModel"
                            placeholder="{{ selectPractice }}"
                            [(ngModel)]="caseDetail.practiceId"
                            [disabled]="getPermission(surgeon_call)"
                            required
                          >
                          </ng-select>
                          <app-err-msg [isFormSubmitted]="newCaseForm?.submitted" [control]="practice" fieldName="Practice"></app-err-msg>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                          <label class="col-form-label">Location<small class="mandatory"></small> </label>
                        </div>
                        <div class="col-sm-8">
                          <ng-select
                            [items]="locationList"
                            bindLabel="address1"
                            bindValue="id"
                            name="location"
                            #location="ngModel"
                            placeholder="Select Location"
                            (clear)="caseDetail.locationId = null"
                            [(ngModel)]="caseDetail.locationId"
                            required
                          >
                            <ng-template ng-label-tmp let-item="item">
                              <span>{{ item | location }}</span>
                            </ng-template>

                            <ng-template ng-option-tmp let-item="item">
                              <span>{{ item | location }}</span>
                            </ng-template>
                          </ng-select>
                          <app-err-msg [isFormSubmitted]="newCaseForm?.submitted" [control]="location" fieldName="Location"></app-err-msg>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                          <label class="col-form-label">Procedure<small class="mandatory"></small> </label>
                        </div>
                        <div class="col-sm-8">
                          <ng-select
                            [items]="procedures"
                            bindLabel="name"
                            bindValue="id"
                            (clear)="caseDetail.procedure = null"
                            name="procedure"
                            #procedure="ngModel"
                            placeholder="Select Procedure Type"
                            [(ngModel)]="caseDetail.procedure"
                            required
                          >
                          </ng-select>
                          <app-err-msg
                            [isFormSubmitted]="newCaseForm?.submitted"
                            [control]="procedure"
                            fieldName="Procedure Type"
                          ></app-err-msg>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                          <label class="col-form-label">Procedure Info</label>
                        </div>
                        <div class="col-sm-8">
                          <textarea
                            type="text"
                            class="form-control"
                            rows="3"
                            name="procedureDescription"
                            [(ngModel)]="caseDetail.procedureDescription"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ibox-title sub-header">
                <div class="row">
                  <div class="col-12">
                    <div class="form-check form-check-inline">
                      <label class="col-form-label m-r-md">Patient Information </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div class="ibox-content clearfix">
                  <div class="row">
                    <div class="col-lg-6 col-md-6">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">First Name<small class="mandatory"></small> </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="patientFirstName"
                              #patientFirstName="ngModel"
                              appInputRestriction="noSpecialChars"
                              [(ngModel)]="caseDetail.patient.firstName"
                              (blur)="checkPatient()"
                              required
                            />
                            <app-err-msg
                              [isFormSubmitted]="newCaseForm?.submitted"
                              [control]="patientFirstName"
                              fieldName="Patient First Name"
                            ></app-err-msg>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Last Name<small class="mandatory"></small> </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="patientLastName"
                              #patientLastName="ngModel"
                              appInputRestriction="noSpecialChars"
                              (blur)="checkPatient()"
                              [(ngModel)]="caseDetail.patient.lastName"
                              required
                            />
                            <app-err-msg
                              [isFormSubmitted]="newCaseForm?.submitted"
                              [control]="patientLastName"
                              fieldName="Patient Last Name"
                            ></app-err-msg>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Email Address </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="patientEmail"
                              #patientEmail="ngModel"
                              [(ngModel)]="caseDetail.patient.email"
                              appEmailValidate
                            />
                            <app-err-msg
                              [isFormSubmitted]="newCaseForm?.submitted"
                              [control]="patientEmail"
                              fieldName="Email"
                            ></app-err-msg>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Phone </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="phone"
                              #phone="ngModel"
                              mask="(000) 000-0000"
                              [(ngModel)]="caseDetail.patient.phone"
                              maxLength="14"
                              appIsNumber
                            />
                            <app-err-msg [isFormSubmitted]="newCaseForm?.submitted" [control]="phone" fieldName="Phone"></app-err-msg>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Date of Birth </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              #dateOfBirth="ngModel"
                              autocomplete="off"
                              name="bsDatepicker"
                              #dp="bsDatepicker"
                              bsDatepicker
                              container=""
                              [(ngModel)]="caseDetail.patient.dateOfBirth"
                              [bsConfig]="bsConfig"
                              placeholder="Enter date of birth"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Address 1 </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="address1"
                              #address1="ngModel"
                              [(ngModel)]="caseDetail.patient.address1"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Address 2 </label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" name="address2" [(ngModel)]="caseDetail.patient.address2" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">City </label>
                          </div>
                          <div class="col-sm-8">
                            <input type="text" class="form-control" name="city" #city="ngModel" [(ngModel)]="caseDetail.patient.city" />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">State </label>
                          </div>
                          <div class="col-sm-8">
                            <select class="form-control" name="state" #stateInput="ngModel" [(ngModel)]="caseDetail.patient.stateId">
                              <option *ngFor="let state of stateList" [ngValue]="state.id">{{ state?.name }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5 col-md-5">
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Gender </label>
                          </div>
                          <div class="col-sm-8 mt-2">
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  [(ngModel)]="caseDetail.patient.gender"
                                  value="MALE"
                                  id="male"
                                  name="gender"
                                />Male
                              </label>
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input
                                  type="radio"
                                  class="form-check-input"
                                  [(ngModel)]="caseDetail.patient.gender"
                                  value="FEMALE"
                                  id="female"
                                  name="gender"
                                />Female
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Marital Status </label>
                          </div>
                          <div class="col-sm-8 mt-2">
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input
                                  type="radio"
                                  [(ngModel)]="caseDetail.patient.maritalStatus"
                                  value="SINGLE"
                                  id="single"
                                  name="materialStatus"
                                  class="form-check-input p-l-0"
                                />Single</label
                              >
                            </div>
                            <div class="form-check-inline">
                              <label class="form-check-label">
                                <input
                                  type="radio"
                                  [(ngModel)]="caseDetail.patient.maritalStatus"
                                  value="MARRIED"
                                  id="married"
                                  name="materialStatus"
                                  class="form-check-input p-l-0"
                                />Married</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Employment Status </label>
                          </div>
                          <div class="col-sm-8">
                            <select class="form-control" name="employmentStatus" [(ngModel)]="caseDetail.patient.patientEmploymentStatusId">
                              <option [ngValue]="null">Select Employment Status</option>
                              <option *ngFor="let employmentStatus of patientEmploymentStatusList" [ngValue]="employmentStatus.id">{{
                                employmentStatus?.name
                              }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Height </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="height"
                              #height="ngModel"
                              [(ngModel)]="caseDetail.patient.height"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Weight </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="weight"
                              #weight="ngModel"
                              [(ngModel)]="caseDetail.patient.weight"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Parent/Gurdian Name </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="parentName"
                              #parentName="ngModel"
                              [(ngModel)]="caseDetail.patient.guardianName"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="row">
                          <div class="col-sm-4 p-r-0 text-sm-right text-xs-left">
                            <label class="col-form-label">Parent/Gurdian Phone Number </label>
                          </div>
                          <div class="col-sm-8">
                            <input
                              type="text"
                              class="form-control"
                              name="parentNumber"
                              #parentNumber="ngModel"
                              mask="(000) 000-0000"
                              maxLength="14"
                              appIsNumber
                              [(ngModel)]="caseDetail.patient.guardianNumber"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ibox-title sub-header">
                <div class="col-sm-12 child-left p-l-0">
                  Patient Insurance Information
                </div>
              </div>
              <div class="ibox-content clearfix">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-sm-4 p-r-0 text-left">
                          <label class="col-form-label">Responsible Party<small class="mandatory"></small> </label>
                        </div>
                        <div class="col-sm-8">
                          <ng-select
                            [items]="responsiblePartyList"
                            bindLabel="name"
                            bindValue="id"
                            (clear)="caseDetail.responsiblePartyId = null"
                            name="responsibleParty"
                            placeholder="Select Responsible Party"
                            #responsibleParty="ngModel"
                            [(ngModel)]="caseDetail.responsiblePartyId"
                            (change)="onResponsiblePartyChange()"
                            required
                          >
                          </ng-select>
                          <app-err-msg
                            [isFormSubmitted]="newCaseForm?.submitted"
                            [control]="responsibleParty"
                            fieldName="Responsible Party"
                          ></app-err-msg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-4 offset-2 row" *ngIf="caseDetail?.responsiblePartyId === 2">
                    <div class="col">
                      <angular2-multiselect
                        [data]="remainingPatientInsuranceList"
                        name="selectedInsurance"
                        [(ngModel)]="tempPatientInsuranceList"
                        [settings]="multipleInsuranceSettings"
                        (onSelect)="onSelectRemainingPatientInsurance($event)"
                        *ngIf="remainingPatientInsuranceList?.length"
                      >
                        <c-badge>
                          <ng-template let-item="item">
                            <label>{{ item | patientInsurance }}</label>
                          </ng-template>
                        </c-badge>
                        <c-item>
                          <ng-template let-item="item">
                            <label class="custom-template-dropdown">{{ item | patientInsurance }}</label>
                          </ng-template>
                        </c-item>
                      </angular2-multiselect>
                    </div>
                    <div class="col-4">
                      <button class="btn btn-success btn-sm float-right mb-0" type="button" (click)="addNewInsurance()">
                        {{ addPatientInsurance }}
                      </button>
                    </div>
                  </div>
                  <div id="no-more-tables" class="col-12 m-t-md" *ngIf="caseDetail?.responsiblePartyId === 2">
                    <table
                      class="table table-bordered m-b-40"
                      aria-describedby="Patient Insurance List"
                      *ngIf="caseDetail?.anmCasePatientInsurances?.length"
                    >
                      <thead>
                        <tr>
                          <th scope="col" class="text-center">Primary</th>
                          <th scope="col">
                            Carrier Name<small class="mandatory"></small>
                            <a
                              class="float-right"
                              data-toggle="modal"
                              data-target="#patientInsuranceCarrierPanel"
                              data-backdrop="static"
                              type="button"
                              (click)="toggleInsuranceCarrierPanel()"
                            >
                              <em class="fa fa-plus-square"></em>
                            </a>
                          </th>
                          <th scope="col">Payor Type</th>
                          <th scope="col">Carrier Address</th>
                          <th scope="col">Policy Number</th>
                          <th scope="col">Insured Relationship</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let insurance of caseDetail?.anmCasePatientInsurances; let i = index; trackBy: trackByFunction">
                          <td data-title="Primary">
                            <div class="form-check text-center">
                              <input
                                type="checkbox"
                                class="form-check-input"
                                name="isPrimary{{ i }}"
                                [(ngModel)]="insurance.patientInsuranceDTO.primary"
                                (click)="onInsuranceTypeChange(insurance, i)"
                              />&nbsp;
                            </div>
                          </td>
                          <td data-title="Carrier Name">
                            <ng-select
                              [items]="carrierCategoryList"
                              bindLabel="name"
                              bindValue="id"
                              (change)="onSelectCarrierCategory($event, insurance, i)"
                              (clear)="onDeSelectCarrierCategory(insurance)"
                              name="carrierCategory{{ i }}"
                              placeholder="{{ selectInsuranceCarrierGroup }}"
                              #insuranceCarrierGroup="ngModel"
                              [(ngModel)]="insurance.patientInsuranceDTO.insuranceCarrierGroupId"
                              required
                            >
                            </ng-select>
                            <app-err-msg
                              [isFormSubmitted]="newCaseForm?.submitted"
                              [control]="insuranceCarrierGroup"
                              fieldName="{{ patientInsuranceMessage.carrierGroup }}"
                            ></app-err-msg>
                          </td>
                          <td data-title="Payor Type">
                            <ng-select
                              appendTo="body"
                              [items]="
                                getCarrierInsuranceTypeList(i, insurance?.patientInsuranceDTO?.insuranceCarrierGroupId)?.length
                                  ? getCarrierInsuranceTypeList(i, insurance?.patientInsuranceDTO?.insuranceCarrierGroupId)
                                  : insurance?.patientInsuranceDTO?.payorTypeList
                              "
                              bindLabel="name"
                              bindValue="id"
                              placeholder="{{ selectInsuranceType }}"
                              (change)="onSelectInsuranceType($event, insurance, i)"
                              (clear)="onDeSelectInsuranceType(insurance)"
                              name="insuranceType{{ i }}"
                              [(ngModel)]="insurance.patientInsuranceDTO.insuranceTypeId"
                            >
                            </ng-select>
                          </td>
                          <td data-title="Carrier Address">
                            <ng-select
                              appendTo="body"
                              [items]="
                                getCarrierCompanyList(i, insurance?.patientInsuranceDTO?.insuranceCarrierGroupId)?.length
                                  ? getCarrierCompanyList(i, insurance?.patientInsuranceDTO?.insuranceCarrierGroupId)
                                  : insurance?.patientInsuranceDTO?.carrierList
                              "
                              bindLabel="insuranceName"
                              bindValue="id"
                              placeholder="{{ selectInsuranceCarrier }}"
                              (change)="onSelectInsuranceCarrier($event, insurance, i)"
                              (clear)="removeInsuranceCarrier(insurance)"
                              name="companyList{{ i }}"
                              [(ngModel)]="insurance.patientInsuranceDTO.insuranceCarrierId"
                            >
                              <ng-template ng-label-tmp let-item="item">
                                <span>{{ item | insuranceCarrierAddress }}</span>
                              </ng-template>

                              <ng-template ng-option-tmp let-item="item">
                                <span>{{ item | insuranceCarrierAddress }}</span>
                              </ng-template>
                            </ng-select>
                          </td>
                          <td data-title="Policy Number">
                            <input
                              type="text"
                              class="form-control"
                              name="policyNumber{{ i }}"
                              placeholder="Enter Policy number"
                              [(ngModel)]="insurance.patientInsuranceDTO.policyNumber"
                              #policyNumber="ngModel"
                            />
                          </td>
                          <td data-title="Insured Relationship">
                            <ng-select
                              appendTo="body"
                              [items]="insuredRelationshipList"
                              bindLabel="name"
                              bindValue="id"
                              name="insuredRelationship{{ i }}"
                              placeholder="{{ selectInsuredRelationship }}"
                              (change)="
                                insurance.patientInsuranceDTO.insuredRelationshipId = $event.id;
                                insurance.patientInsuranceDTO.insuredRelationshipName = $event.name
                              "
                              (clear)="
                                insurance.patientInsuranceDTO.insuredRelationshipId = null;
                                insurance.patientInsuranceDTO.insuredRelationshipName = null
                              "
                              [(ngModel)]="insurance.patientInsuranceDTO.insuredRelationshipId"
                              #insuredRelationship="ngModel"
                              required
                            >
                            </ng-select>
                            <app-err-msg
                              [isFormSubmitted]="newCaseForm?.submitted"
                              [control]="insuredRelationship"
                              fieldName="{{ patientInsuranceMessage.insuredRelationship }}"
                            ></app-err-msg>
                          </td>
                          <td class="text-center" data-title="Action">
                            <button class="btn btn-danger btn-circle" type="button" (click)="removeInsurance(i)">
                              <em class="fa fa-trash-o"></em>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="text-center" *ngIf="!caseDetail?.anmCasePatientInsurances?.length">
                      <h3 class="m-b-xxs">No data found!</h3>
                      <small>No Patient Insurance here.</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ibox-title sub-header">
                Medical Information
              </div>
              <div class="ibox-content clearfix">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md">Born Premature?</label>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="bornPremature"
                              id="bornPremature1"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.isBornPremature"
                              [value]="medicalQuestions?.yesOption?.value"
                            />
                            <label class="form-check-label" for="bornPremature1">{{ medicalQuestions?.yesOption?.display }}</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="bornPremature"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.isBornPremature"
                              id="bornPremature2"
                              [value]="medicalQuestions?.noOption?.value"
                            />
                            <label class="form-check-label" for="bornPremature2">{{ medicalQuestions?.noOption?.display }}</label>
                          </div>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            placeholder="If yes, please explain"
                            name="bornPrematureExplanation"
                            #bornPrematureExplanation="ngModel"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.medicalQuestionResponses.bornPrematureExplanation"
                            [required]="caseDetail?.medicalQuestionResponses?.isBornPremature === medicalQuestions?.yesOption?.value"
                          ></textarea>
                          <app-err-msg
                            [isFormSubmitted]="newCaseForm?.submitted"
                            [control]="bornPrematureExplanation"
                            fieldName="Explanation"
                          ></app-err-msg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md">Are you currently taking any Medications? </label>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="takingMedication"
                              id="takingMedication1"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.isTakingMedication"
                              [value]="medicalQuestions?.yesOption?.value"
                            />
                            <label class="form-check-label" for="takingMedication1">{{ medicalQuestions?.yesOption?.display }}</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="takingMedication"
                              id="takingMedication2"
                              [value]="medicalQuestions?.noOption?.value"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.isTakingMedication"
                            />
                            <label class="form-check-label" for="takingMedication2">{{ medicalQuestions?.noOption?.display }}</label>
                          </div>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            placeholder="If yes, please list all medications and dosage down below"
                            name="takingMedicationExplanation"
                            #takingMedicationExplanationInput="ngModel"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.medicalQuestionResponses.takingMedicationExplanation"
                            [required]="caseDetail?.medicalQuestionResponses?.isTakingMedication === medicalQuestions?.yesOption?.value"
                          ></textarea>
                          <app-err-msg
                            [isFormSubmitted]="newCaseForm?.submitted"
                            [control]="takingMedicationExplanationInput"
                            fieldName="Explanation"
                          ></app-err-msg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md">Recent Cough/Cold/Fever? </label>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="havingCoughOrCold"
                              id="havingCoughOrCold1"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.isHavingCoughOrCold"
                              [value]="medicalQuestions?.yesOption?.value"
                            />
                            <label class="form-check-label" for="havingCoughOrCold1">{{ medicalQuestions?.yesOption?.display }}</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="havingCoughOrCold"
                              id="havingCoughOrCold2"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.isHavingCoughOrCold"
                              [value]="medicalQuestions?.noOption?.value"
                            />
                            <label class="form-check-label" for="havingCoughOrCold2">{{ medicalQuestions?.noOption?.display }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md">Allergies </label>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="havingAllergies"
                              id="havingAllergies1"
                              [value]="medicalQuestions?.noKnownAllergiesOption?.value"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.havingAllergies"
                            />
                            <label class="form-check-label" for="havingAllergies1">{{
                              medicalQuestions?.noKnownAllergiesOption?.value
                            }}</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="havingAllergies"
                              id="havingAllergies2"
                              [value]="medicalQuestions?.medicalAllergyOption?.value"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.havingAllergies"
                            />
                            <label class="form-check-label" for="havingAllergies2">{{
                              medicalQuestions?.medicalAllergyOption?.display
                            }}</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="havingAllergies"
                              id="havingAllergies3"
                              [value]="medicalQuestions?.latexAllergyOption?.value"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.havingAllergies"
                            />
                            <label class="form-check-label" for="havingAllergies3">{{
                              medicalQuestions?.latexAllergyOption?.display
                            }}</label>
                          </div>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            placeholder="Explain Allergies"
                            name="explanationForAllergy"
                            #allergyExplanation="ngModel"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.medicalQuestionResponses.explanationForAllergy"
                            [required]="
                              caseDetail?.medicalQuestionResponses?.havingAllergies &&
                              caseDetail?.medicalQuestionResponses?.havingAllergies !== medicalQuestions?.noKnownAllergiesOption?.value
                            "
                          ></textarea>
                          <app-err-msg
                            [isFormSubmitted]="newCaseForm?.submitted"
                            [control]="allergyExplanation"
                            fieldName="Explanation"
                          ></app-err-msg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md"
                            >Any previous surgical or non-invasive procedures requiring anesthesia or sedation?
                          </label>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="anyProcedure"
                              id="anyProcedure1"
                              [value]="medicalQuestions?.yesOption?.value"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.isAnyProcedure"
                            />
                            <label class="form-check-label" for="anyProcedure1">{{ medicalQuestions?.yesOption?.display }}</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="anyProcedure"
                              id="anyProcedure2"
                              [value]="medicalQuestions?.noOption?.value"
                              [(ngModel)]="caseDetail.medicalQuestionResponses.isAnyProcedure"
                            />
                            <label class="form-check-label" for="anyProcedure2">{{ medicalQuestions?.noOption?.display }}</label>
                          </div>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            placeholder="List Prior Procedures or Dates"
                            name="previousProcedure"
                            #procedureExplanation="ngModel"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.medicalQuestionResponses.procedureExplanation"
                            [required]="caseDetail?.medicalQuestionResponses?.isAnyProcedure === medicalQuestions?.yesOption?.value"
                          ></textarea>
                          <app-err-msg
                            [isFormSubmitted]="newCaseForm?.submitted"
                            [control]="procedureExplanation"
                            fieldName="Explanation"
                          ></app-err-msg>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            placeholder="Any complications or concern with prior anesthetics"
                            name="complications"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.medicalQuestionResponses.anyComplicationOrConcern"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ibox-title sub-header">
                Anesthesiologist Justification Form
              </div>
              <div class="ibox-content clearfix">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md"
                            >Clinical Diagnosis : 1) What is the patient's chief complaint? 2) What is the treatment plan?
                          </label>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            name="clinicalDiagnosis"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.justificationQuestions.clinicalDiagnosis"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md"
                            >Justification For Dental : Given the current situation with COVID-19, why is the surgery for this patient
                            considered an emergency?
                          </label>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            name="justificationForDental"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.justificationQuestions.justificationForDental"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md"
                            >Disposition Of Child : Have you had any issues with this patient previously when performing treatment or
                            routine examinations? Do you anticipate any issues with this patient for this surgery?
                          </label>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            name="dispositionOfChild"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.justificationQuestions.dispositionOfChild"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md"
                            >Justification For Anesthesia : Why is general anesthesia necessary in lieu of local anesthetic?
                          </label>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            name="justificationForAnesthesia"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.justificationQuestions.justificationForAnesthesia"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label m-r-md"
                            >Goal : If the surgery is completed, what is the impact to the patient? What is the impact to the greater
                            medical system if the surgery is not completed in the immediate future?
                          </label>
                        </div>
                        <div class="col-12 m-t-sm">
                          <textarea
                            class="form-control"
                            name="goal"
                            [rows]="rowLineNo"
                            [maxlength]="maxLength"
                            [(ngModel)]="caseDetail.justificationQuestions.goal"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <app-case-file-upload
                *ngIf="!updateCase"
                [existingFileList]="caseFileUploadList"
                (notifyAfterAddUpdateFile)="notifyAfterAddUpdateFile($event)"
                (notityAfterRemoveFile)="notityAfterRemoveFile($event)"
              ></app-case-file-upload>
              <div class="ibox-title sub-header m-b-7">
                Extra Information
              </div>
              <div class="ibox-content clearfix">
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-2 p-r-0 text-sm-right text-xs-left">
                      <label class="col-form-label">Note </label>
                    </div>
                    <div class="col-sm-10">
                      <textarea type="text" class="form-control" rows="6" name="note" [(ngModel)]="caseDetail.note"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ibox-content border-style-none">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 p-r-0">
                      <div class="float-right m-r">
                        <button class="btn btn-success" type="submit">
                          {{ updateCase ? updateButton : saveButton }}
                        </button>
                        <a class="btn btn-white m-l-xs" (click)="goBack()">{{ cancelButton }}</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <button
              id="openFoundPatientShowModalButton"
              [hidden]="true"
              data-toggle="modal"
              data-target="#foundPatientShowModal"
              data-backdrop="static"
            >
              Show Found Patient
            </button>
            <button
              id="openConfirmCaseInformationModalButton"
              [hidden]="true"
              data-toggle="modal"
              data-target="#confirmCaseInformationModal"
              data-backdrop="static"
              (click)="openConfirmCaseInformationModal = true"
            >
              Confirm Case information
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Confirm Case Information Modal -->
<div class="modal fade" id="confirmCaseInformationModal" role="dialog" *ngIf="!updateCase">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-navy">
          <strong>Review Required Fields</strong>
        </h4>
        <button type="button" class="close" data-toggle="modal" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-sm-12">
            <label class="col-md-4 col-sm-3 col-form-label text-left">Practice</label>
            <label class="col-form-label f-w-n col-md-8 col-sm-9">{{ caseDetail?.practiceName }}</label>
          </div>
          <div class="col-sm-12">
            <label class="col-md-4 col-sm-3 col-form-label text-left">Patient Name</label>
            <label class="col-form-label f-w-n col-md-8 col-sm-9"
              >{{ caseDetail?.patient?.firstName | notAssigned }}&nbsp;{{ caseDetail?.patient?.lastName }}</label
            >
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-success" data-toggle="modal" data-dismiss="modal" (click)="createCase()">
          {{ confirmButton }}
        </button>
        <button type="button" class="btn btn-default" data-toggle="modal" data-dismiss="modal">{{ editButton }}</button>
      </div>
    </div>
  </div>
</div>

<!-- Found Patient Show Modal -->
<div class="modal fade" id="foundPatientShowModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-navy">
          <strong>We Have Found {{ foundPatientList?.length }} Potential Matching Patient Based on The Information You Provided. </strong>
          <span class="btn badge-primary btn-circle2 m-r-xs"></span><small>Future Case</small>
        </h4>
        <button type="button" class="close" data-toggle="modal" data-dismiss="modal">&times;</button>
      </div>
      <form class="form-horizontal" #foundedPatientSelectForm="ngForm">
        <div class="modal-body">
          <div class="overflow-auto">
            <table
              class="table table-striped table-hover"
              [mfData]="foundPatientList"
              #mf="mfDataTable"
              aria-describedby="found patient list"
            >
              <thead class="tableHeader">
                <tr>
                  <th scope="col">
                    <a>
                      <span>Select</span>
                    </a>
                  </th>
                  <th scope="col">
                    <a>
                      <span>
                        <mfDefaultSorter by="patient.firstName">Name</mfDefaultSorter>
                      </span>
                    </a>
                  </th>
                  <th scope="col">
                    <a>
                      <span>
                        <mfDefaultSorter by="patient.phone">Phone</mfDefaultSorter>
                      </span>
                    </a>
                  </th>
                  <th scope="col">
                    <a>
                      <span>
                        <mfDefaultSorter by="patient.dateOfBirth">DOB</mfDefaultSorter>
                      </span>
                    </a>
                  </th>
                  <th scope="col">
                    <a>
                      <span>
                        <mfDefaultSorter by="patient.gender">Gender</mfDefaultSorter>
                      </span>
                    </a>
                  </th>
                  <th scope="col">
                    <a>
                      <span>Total Cases</span>
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let foundedPatient of mf?.data; trackBy: trackByFunction">
                  <tr>
                    <td>
                      <label class="radio-inline p-t-0">
                        <input
                          type="radio"
                          [(ngModel)]="foundedSelectedPatient"
                          [value]="foundedPatient?.patient"
                          id="foundedSelectedPatient"
                          name="foundedSelectedPatient"
                          required
                        />
                        Select
                      </label>
                    </td>
                    <td>{{ foundedPatient?.patient?.firstName }}&nbsp;{{ foundedPatient?.patient?.lastName }}</td>
                    <td>{{ foundedPatient?.patient?.phone | notAssigned }}</td>
                    <td>{{ foundedPatient?.patient?.dateOfBirth ? (foundedPatient?.patient?.dateOfBirth | date: dateFormat) : '' }}</td>
                    <td>{{ foundedPatient?.patient?.gender }}</td>
                    <td>{{ foundedPatient?.anmCases?.length }}</td>
                  </tr>
                  <tr *ngIf="foundedPatient?.anmCases && foundedPatient?.anmCases?.length > 0">
                    <td></td>
                    <td colspan="5" class="no-padding-space">
                      <div class="overflow-auto future-cases">
                        <table
                          class="table table-striped table-hover m-b-0"
                          [mfData]="foundedPatient?.anmCases"
                          #mf="mfDataTable"
                          aria-describedby="foundedPatient table"
                        >
                          <tbody>
                            <tr *ngFor="let anmCase of mf?.data; trackBy: trackByFunction">
                              <td>
                                <a data-toggle="modal" data-dismiss="modal">
                                  <u>{{ anmCase?.caseNo }}</u>
                                </a>
                              </td>
                              <td>
                                <span> {{ anmCase?.dateOfSurgery | tbdTime: anmCase?.isTBD }}</span>
                              </td>
                              <td>{{ anmCase?.practiceName | notAssigned }}</td>
                              <td>{{ anmCase | location }}</td>
                              <td>
                                <span [ngClass]="anmCase?.status | caseStatusColor">{{ anmCase?.status }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-success"
            data-toggle="modal"
            data-dismiss="modal"
            [disabled]="foundedPatientSelectForm?.form?.invalid"
            (click)="selectFoundPatient()"
          >
            Select
          </button>
          <button type="button" class="btn btn-default" data-toggle="modal" data-dismiss="modal">Skip</button>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal right fade" id="patientInsuranceCarrierPanel" role="dialog" *ngIf="isInsuranceCarrierPanel">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header white-bg">
        <h4 class="modal-title">
          <strong>Insurance create </strong>
        </h4>
        <button type="button" class="close" data-toggle="modal" data-dismiss="modal" (click)="toggleInsuranceCarrierPanel()">
          &times;
        </button>
      </div>
      <div class="modal-body white-bg">
        <app-insurance-carrier-create-modal
          [stateList]="stateList"
          [insuranceTypeList]="insuranceTypeList"
          [isRequiredInformation]="false"
          [insuranceCarrierGroupList]="carrierCategoryList"
          [addInsuranceCarrierDetail]="addInsuranceCarrierDetail"
          [carrierCategoryList]="carrierCategoryList"
          (closeModal)="toggleInsuranceCarrierPanel()"
          (notifyAfterAddUpdateInsuranceCarrier)="insuranceCarrier($event)"
        ></app-insurance-carrier-create-modal>
      </div>
    </div>
  </div>
</div>
