<div class="ibox-title sub-header">
  <div class="row">
    <div class="col-6">
      Attachments
    </div>
    <div class="col-6">
      <button
        class="btn btn-success btn-sm float-right mb-0"
        type="button"
        data-toggle="modal"
        data-target="#fileUploadsModal"
        data-backdrop="static"
        (click)="fileUploadsModal()"
      >
        Add Attachment
      </button>
    </div>
  </div>
</div>
<div class="ibox-content clearfix">
  <div class="row">
    <div class="col-sm-12">
      <ul class="list-group m-b-none" *ngIf="existingFileList?.length">
        <li class="list-group-item" *ngFor="let file of existingFileList; let i = index">
          <div class="row">
            <div class="col-sm-7 overflowContent" title="{{ file?.fileName }}">
              {{ file?.fileName }}
            </div>
            <div class="col-sm-3 child-left">
              <span class="label pull-right overflowContent w-xl" title="{{ file?.tagName }}">{{ file?.tagName }}</span>
            </div>
            <div class="col-sm-2 child-left">
              <span class="label label-danger pull-right cursor-pointer" (click)="deleteFile(i)">{{ deleteButton }}</span>
            </div>
          </div>
        </li>
      </ul>
      <div class="ibox-content no-borders text-center" *ngIf="existingFileList.length === 0">
        <h3 class="m-b-xxs">No data found!</h3>
        <small>No Files uploaded here.</small>
      </div>
    </div>
  </div>
</div>

<!-- File Upload Modal -->
<div class="modal fade" id="fileUploadsModal" role="dialog" *ngIf="fileUploadsModalValue">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title text-navy">
          <strong>Upload Files</strong>
        </h4>
        <button type="button" class="close" data-toggle="modal" data-dismiss="modal" (click)="fileUploadsModal()">&times;</button>
      </div>
      <form class="form-horizontal ibox-content" #fileUploadsForm="ngForm">
        <app-spinner></app-spinner>
        <div class="modal-body">
          <div class="form-group">
            <div class="row">
              <label class="col-md-3 col-form-label text-right">
                Attachments
                <small class="mandatory"></small>
              </label>
              <div class="col-md-7">
                <input
                  type="file"
                  accept=".pdf, .smd, .iomdata"
                  multiple
                  name="file"
                  #fileInput="ngModel"
                  [(ngModel)]="addAttachment.multipartFiles"
                  (change)="fileChange($event)"
                />
                <div class="watermark">
                  (PDF, SMD, IOM DATA)
                </div>
                <div *ngIf="fileInput.touched && fileInput.invalid">
                  <small class="form-text text-danger" *ngIf="fileInput?.errors.required">
                    Attachment is required.
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="selectedFilesInModal?.length">
            <table aria-describedby="File List" class="table">
              <thead>
                <th scope="col" class="no-borders">Files</th>
                <th scope="col" class="no-borders">Tags</th>
                <th scope="col" class="no-borders">Remove</th>
              </thead>
              <tbody>
                <tr *ngFor="let file of selectedFilesInModal; let i = index" class="no-borders">
                  <td class="no-borders">
                    <ul class="list-unstyled file-list">
                      <li title="{{ file?.fileName }}"><em class="fa fa-file"></em> {{ file?.fileName }}</li>
                    </ul>
                  </td>
                  <td class="no-borders">
                    <select
                      class="form-control m-b-xs"
                      (change)="onTagChange($event, i)"
                      name="fileTag+{{ file?.fileName + i }}"
                      [(ngModel)]="file.tagId"
                      [disabled]="file?.tagId === smdTagInformation?.id"
                      required
                    >
                      <option [value]="null" hidden>Select tag</option>
                      <option
                        *ngFor="let tag of alltags"
                        [value]="tag?.id"
                        [hidden]="
                          file?.fileName?.substr(file?.fileName?.lastIndexOf('.') + 1) !== smdFileExtension &&
                          tag?.id === smdTagInformation?.id
                        "
                        >{{ tag?.name }}</option
                      >
                    </select>
                  </td>
                  <td class="no-borders">
                    <button class="btn btn-danger btn-circle" type="button" (click)="removeSelectedFile(i)">
                      <em class="fa fa-trash-o"></em>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div class="modal-footer">
            <button
              type="button"
              [disabled]="fileUploadsForm.form.invalid || selectedFilesInModal.length === 0"
              class="btn btn-sm btn-success"
              data-toggle="modal"
              data-dismiss="modal"
              (click)="saveFile()"
            >
              {{ saveButton }}
            </button>
            <button type="button" class="btn btn-sm btn-default" data-toggle="modal" data-dismiss="modal" (click)="fileUploadsModal()">
              {{ cancelButton }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
