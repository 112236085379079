<body class="top-navigation pace-done">
  <div id="wrapper">
    <div id="page-wrapper" class="gray-bg welcomeHeight">
      <div class="border-bottom white-bg">
        <app-top-navbar></app-top-navbar>
      </div>

      <div class="pace-done middlePart" id="middle">
        <div id="wrapper">
          <div class="gray-bg dashbard-1 m-h-midiv p-w-xs">
            <router-outlet></router-outlet>
            <app-footer></app-footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</body>
