import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { CONSTANTS } from '../shared/constants/endpoints.constants';
import { DataExtract } from '../shared/constants/helper';
import { SearchDTO } from './case-management.model';

@Injectable()
export class CaseManagementService {
  constructor(private http: HttpClient, private dataExtract: DataExtract) {}

  checkPatientByFirstNameAndLastName(firstName, lastName) {
    return this.http
      .get(CONSTANTS.ENDPOINTS.PATIENTS + '/firstName/' + firstName + '/lastName/' + lastName)
      .map(this.dataExtract.extractData);
  }

  createCases(params: object) {
    return this.http.post(CONSTANTS.ENDPOINTS.CASES, params).map(this.dataExtract.extractData);
  }

  updateCases(params: object) {
    return this.http.put(CONSTANTS.ENDPOINTS.CASES, params).map(this.dataExtract.extractData);
  }

  getCaseById(caseId) {
    return this.http.get(CONSTANTS.ENDPOINTS.CASES + '/' + caseId).map(this.dataExtract.extractData);
  }

  getOperatingRoomById(hospitalId) {
    return this.http.get(CONSTANTS.ENDPOINTS.HOSPITALS + '/' + hospitalId + '/operatingRooms').map(this.dataExtract.extractData);
  }

  getAllModalitieByCase(caseId) {
    return this.http.get(CONSTANTS.ENDPOINTS.MODALITIE + '/cases/' + caseId).map(this.dataExtract.extractData);
  }

  getAllAttorney() {
    const url = CONSTANTS.ENDPOINTS.ATTORNEY;
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getAllCompetence() {
    return this.http.get(CONSTANTS.ENDPOINTS.COMPETENCIES).map(this.dataExtract.extractData);
  }

  getAllModalitieForCase() {
    return this.http.get(CONSTANTS.ENDPOINTS.MODALITIE + '/cases').map(this.dataExtract.extractData);
  }

  getAllDiagnosisCodeForCase() {
    return this.http.get(CONSTANTS.ENDPOINTS.DIAGNOSISCODE + '/cases').map(this.dataExtract.extractData);
  }

  getStates() {
    const url = CONSTANTS.ENDPOINTS.STATES;
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getInsuranceType() {
    const url = CONSTANTS.ENDPOINTS.PATIENTSINSURANCETYPE;
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getInsuranceCarrier() {
    const url = CONSTANTS.ENDPOINTS.INSURANCECARRIERS;
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getAllHospitalForCase() {
    return this.http.get(CONSTANTS.ENDPOINTS.HOSPITALS + '/cases').map(this.dataExtract.extractData);
  }

  getAllDoctors() {
    const url = CONSTANTS.ENDPOINTS.DOCTORS;
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getAllDoctorForCase() {
    return this.http.get(CONSTANTS.ENDPOINTS.DOCTORS + '/cases').map(this.dataExtract.extractData);
  }

  getAllProcedureTypeForCase() {
    return this.http.get(CONSTANTS.ENDPOINTS.PROCEDURETYPES + '/cases').map(this.dataExtract.extractData);
  }

  getAllCases() {
    return this.http.get(CONSTANTS.ENDPOINTS.CASES + '/hospitalScheduler').map(this.dataExtract.extractData);
  }

  findAllFilterCases(params: object, page, size, sort, sortOrder) {
    const httpParam = new HttpParams()
      .append('page', page)
      .append('size', size)
      .append('sort', sort + ',' + sortOrder);
    return this.http
      .post(CONSTANTS.ENDPOINTS.CASES + '/filter/externalScheduler', params, { params: httpParam })
      .map(this.dataExtract.extractData);
  }

  getHospitalByHospitalScheduler() {
    return this.http.get(CONSTANTS.ENDPOINTS.HOSPITALSCHEDULER + '/hospital').map(this.dataExtract.extractData);
  }

  getSurgeonByLocation() {
    return this.http.get(CONSTANTS.ENDPOINTS.DOCTORS + '/byLocationScheduler').map(this.dataExtract.extractData);
  }

  getHospitalByLocation() {
    return this.http.get(CONSTANTS.ENDPOINTS.HOSPITALS + '/byLocationScheduler').map(this.dataExtract.extractData);
  }

  getUserDetail() {
    const url = CONSTANTS.ENDPOINTS.USERLPROFILE;
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getDiagnosisCodeByCodeOrName(diagniosisCodeName) {
    const httpParam = new HttpParams().append('name', diagniosisCodeName);
    return this.http.get(CONSTANTS.ENDPOINTS.DIAGNOSISCODE + '/name', { params: httpParam }).map(this.dataExtract.extractData);
  }

  getPatientEmploymentStatusList() {
    return this.http.get(CONSTANTS.ENDPOINTS.PATIENTEMPLOYMENTSTATUS).map(this.dataExtract.extractData);
  }

  checkCaseCountByInsuranceId(insuranceId) {
    return this.http.get(CONSTANTS.ENDPOINTS.CASES + '/count/insurance/' + insuranceId).map(this.dataExtract.extractData);
  }

  getCarrierCategory() {
    const url = CONSTANTS.ENDPOINTS.CARRIERCATEGORY;
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getAllInsuanceCarrierGroup() {
    return this.http.get(CONSTANTS.ENDPOINTS.INSURANCECARRIERGROUPS).map(this.dataExtract.extractData);
  }

  getInsuredRelationship() {
    return this.http.get(CONSTANTS.ENDPOINTS.INSUREDRELATIONSHIP).map(this.dataExtract.extractData);
  }

  getAllRequiredTags() {
    return this.http.get(CONSTANTS.ENDPOINTS.TAGS + '/required').map(this.dataExtract.extractData);
  }

  createInsuranceCarrier(params: object) {
    const url = CONSTANTS.ENDPOINTS.INSURANCECARRIERS;
    return this.http.post(url, params).map(this.dataExtract.extractData);
  }

  updateInsuranceCarrier(params: object) {
    return this.http.put(CONSTANTS.ENDPOINTS.INSURANCECARRIERS, params).map(this.dataExtract.extractData);
  }

  getAllInsuranceCarrierGroupName() {
    const url = CONSTANTS.ENDPOINTS.INSURANCECARRIERGROUPS + '/insuranceCarrier/name';
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getInsuranceTypeByInsuranceCarrierGroupId(insuranceCarrierGroupId) {
    return this.http
      .get(CONSTANTS.ENDPOINTS.INSURANCETYPE + '/insuranceCarrierGroup/' + insuranceCarrierGroupId)
      .map(this.dataExtract.extractData);
  }

  getInsuranceCarrierByInsruanceTypeId(insuranceCarrierGroupId, insuranceTyepId) {
    return this.http
      .get(
        CONSTANTS.ENDPOINTS.INSURANCECARRIERS + '/insuranceCarrierGroup/' + insuranceCarrierGroupId + '/insuranceType/' + insuranceTyepId
      )
      .map(this.dataExtract.extractData);
  }

  uploadFiles(params: object, caseId) {
    return this.http.post(CONSTANTS.ENDPOINTS.ATTACHMENTS + '/cases/' + caseId, params).map(this.dataExtract.extractData);
  }

  getPracticesByDoctorIdForCases(doctorId: number) {
    return this.http.get(CONSTANTS.ENDPOINTS.PRACTICES + '/doctors/' + doctorId + '/cases').map(this.dataExtract.extractData);
  }

  getPractices() {
    return this.http.get(CONSTANTS.ENDPOINTS.PRACTICES).map(this.dataExtract.extractData);
  }

  getDoctorsByHospitalId(hospitalId) {
    return this.http.get(CONSTANTS.ENDPOINTS.DOCTORS + '/hospital/' + hospitalId).map(this.dataExtract.extractData);
  }

  getAllTimeZones(isDayLight: boolean) {
    let params = null;
    if (isDayLight !== null) {
      params = new HttpParams().append('isDayLight', isDayLight.toString());
    }
    return this.http.get(CONSTANTS.ENDPOINTS.TIMEZONE, { params: params }).map(this.dataExtract.extractData);
  }

  getAllLocationsByPracticeId(practiceId) {
    const url = CONSTANTS.ENDPOINTS.PRACTICES + '/' + practiceId + '/locations';
    return this.http.get(url).map(this.dataExtract.extractData);
  }

  getResponsibleParty() {
    return this.http.get(CONSTANTS.ENDPOINTS.RESPONSIBLEPARTY).map(this.dataExtract.extractData);
  }

  getProcedureTypes() {
    return this.http.get(CONSTANTS.ENDPOINTS.ACTIVEPROCEDURETYPES).map(this.dataExtract.extractData);
  }

  getPracticeByLocationScheduler() {
    return this.http.get<SearchDTO>(CONSTANTS.ENDPOINTS.PRACTICES + '/byLocationScheduler');
  }
}
