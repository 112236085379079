import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { IOMDATAFileExtension, SMDFileExtension, SMDTagInformation } from '../../shared/constants/common.constant';
import { Button } from '../../shared/constants/message.constants';
import { SuccessMsg } from '../../shared/constants/success.constants';
import { SharedService } from '../../shared/services/shared.service';
import { UtillService } from '../../shared/services/utill.service';
import { AddAttachement, FileAttachments, Tag } from '../case-management.model';
import { CaseManagementService } from '../case-management.service';

@Component({
  selector: 'app-case-file-upload',
  templateUrl: './case-file-upload.component.html'
})
export class CaseFileUploadComponent implements OnInit, OnDestroy {
  @Input() existingFileList: FileAttachments[];
  @Output() notifyAfterAddUpdateFile: EventEmitter<FileAttachments[]> = new EventEmitter<FileAttachments[]>();
  @Output() notityAfterRemoveFile: EventEmitter<number> = new EventEmitter<number>();
  selectedFilesInModal: FileAttachments[] = [];
  saveButton = Button.save;
  cancelButton = Button.cancel;
  deleteButton = Button.remove;
  fileUploadsPanel = true;
  fileUploadsModalValue = false;
  addAttachment: AddAttachement;
  alltags: Tag[] = [];
  tagIdList: number[] = [];
  addFileAttachments: FileAttachments;
  smdFileExtension = SMDFileExtension;
  smdTagInformation = SMDTagInformation;
  subscriptionManager = new Subscription();

  constructor(private utillService: UtillService, private caseService: CaseManagementService, private sharedService: SharedService) {}

  ngOnInit() {
    this.getAllTags();
  }

  fileUploadsModal() {
    if (this.fileUploadsModalValue) {
      this.fileUploadsModalValue = false;
    } else {
      this.selectedFilesInModal = [];
      this.addAttachment = new AddAttachement();
      this.fileUploadsModalValue = true;
    }
  }

  getAllTags() {
    this.subscriptionManager.add(
      this.caseService.getAllRequiredTags().subscribe(res => {
        this.alltags = res;
      })
    );
  }

  async onTagChange(event, index) {
    if (event.target.value !== null) {
      const tempIntTagId = parseInt(event.target.value, 10);
      if (this.checkDuplicatesTagInFiles(tempIntTagId)) {
        if (await this.utillService.overWriteAlert()) {
          this.addTagIdAndName(tempIntTagId, index, true);
        } else {
          this.selectedFilesInModal[index].tagId = null;
        }
      } else {
        this.addTagIdAndName(tempIntTagId, index, false);
      }
    }
  }

  addTagIdAndName(tagId, index, isUpdate) {
    this.selectedFilesInModal[index].tagId = Number(tagId);
    this.selectedFilesInModal[index].tagName = this.alltags.find(tag => tag.id === tagId).name;
    if (isUpdate) {
      this.selectedFilesInModal[index].anmCaseId = null;
    }
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        const file: File = fileList[i];
        this.addFileAttachments = new FileAttachments();
        this.addFileAttachments.fileName = file.name;
        this.addFileAttachments.multipartFiles = file;
        if (
          file.name.substr(file.name.lastIndexOf('.') + 1) === this.smdFileExtension ||
          file.name.substr(file.name.lastIndexOf('.') + 1) === IOMDATAFileExtension
        ) {
          this.addFileAttachments.tagId = this.smdTagInformation.id;
          this.addFileAttachments.tagName = this.smdTagInformation.name;
        } else {
          this.addFileAttachments.tagId = null;
        }
        this.selectedFilesInModal.push(this.addFileAttachments);
      }
    }
  }

  // save File
  saveFile() {
    this.tagIdList = [];
    this.selectedFilesInModal.forEach(obj => {
      this.tagIdList.push(Number(obj.tagId));
    });
    if (this.tagIdList.length === 1 && this.checkDuplicatesInFiles()) {
      if (this.utillService.overWriteAlert()) {
        this.uploadFile();
      }
    } else if (this.tagIdList.length >= 2 && !this.sharedService.hasDuplicates(this.tagIdList)) {
      if (this.checkDuplicatesInFiles()) {
        if (this.utillService.overWriteAlert()) {
          this.uploadFile();
        }
      } else {
        this.uploadFile();
      }
    } else {
      this.utillService.commonWarningMessageAlert(SuccessMsg.multipleTag);
    }
  }

  // upload file
  uploadFile() {
    this.notifyAfterAddUpdateFile.emit(this.selectedFilesInModal);
    this.fileUploadsModalValue = false;
    this.sharedService.closeFileModal('fileUploadsModal');
  }

  // check duplicate in file
  checkDuplicatesInFiles() {
    for (let i = 0; i < this.tagIdList.length; i++) {
      const result = this.existingFileList.find(obj => {
        if (this.tagIdList[i] !== 12) {
          return obj.tagId === this.tagIdList[i];
        }
      });
      if (result) {
        return result;
      }
    }
  }

  // remove file from list
  removeSelectedFile(index) {
    this.selectedFilesInModal.splice(index, 1);
  }

  checkDuplicatesTagInFiles(tagId) {
    if (tagId !== 12) {
      return this.selectedFilesInModal.find(file => file.tagId === tagId) ? true : false;
    }
    return false;
  }

  async deleteFile(index) {
    if (await this.utillService.deleteAlert()) {
      this.notityAfterRemoveFile.emit(index);
    }
  }

  ngOnDestroy() {
    this.subscriptionManager.unsubscribe();
  }
}
