export class NotificationList {
  constructor(
    public id?: number,
    public body?: string,
    public subject?: string,
    public url?: string,
    public userId?: number,
    public isRead?: boolean,
    public createdDate?: Date
  ) { }
}

export class NotificationPage {
  constructor(
    public notificationList: NotificationList[] = [],
    public pageNumber: number = 0,
    public size: number = 10,
    public isMoreNotification: boolean = true,
    public unReadCount: number = 0
  ) { }
}

