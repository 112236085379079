<div class="modal fade" id="patientInsuranceInformationModal" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content left-side-tab">
      <div class="modal-header">
        <h4 class="modal-title">
          <strong
            >{{
              tab === patientInsuranceTabConst
                ? isAdd
                  ? addPatientInsuranceInformationConst
                  : updatePatientInsuranceInformationConst
                : addInsuranceCarrierInformationConst
            }}
          </strong>
        </h4>
        <button type="button" class="close" data-toggle="modal" data-dismiss="modal" (click)="closePatientInsuranceModal()">&times;</button>
      </div>
      <div class="tabs-container">
        <div class="tabs-left">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                class="m-r-zero nav-link"
                data-toggle="tab"
                href="#addPatientInsurance"
                [class]="tab === patientInsuranceTabConst ? 'active' : ''"
                (click)="showTab(patientInsuranceTabConst)"
                >{{ isAdd ? addPatientInsuranceConst : updatePatientInsuranceConst }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="m-r-zero nav-link"
                data-toggle="tab"
                href="#addInsuranceCarrier"
                [class]="tab === addInsuranceCarrierTabConst ? 'active' : ''"
                (click)="showTab(addInsuranceCarrierTabConst)"
                >{{ addInsuranceCarrierInformationConst }}</a
              >
            </li>
          </ul>
          <div class="tab-content">
            <div id="addPatientInsurance" [class]="tab === patientInsuranceTabConst ? 'tab-pane active' : 'tab-pane'">
              <div class="card-body">
                <app-primary-insurance-create
                  [insuranceDetail]="insuranceDetail"
                  [existingInsuranceList]="existingInsuranceList"
                  [stateList]="stateList"
                  [insuranceCarrierGroupList]="insuranceCarrierGroupList"
                  [attorneyList]="attorneyList"
                  [insuredRelationshipList]="insuredRelationshipList"
                  (notifyAfterAddUpdate)="notifyAfterPatientInsuranceAddUpdate($event)"
                  (closeModal)="closePatientInsuranceModal()"
                ></app-primary-insurance-create>
              </div>
            </div>
          </div>
          <div class="tab-content">
            <div id="addInsuranceCarrier" [class]="tab === addInsuranceCarrierTabConst ? 'tab-pane active' : 'tab-pane'">
              <div class="card-body">
                <app-insurance-carrier-create-modal
                  [stateList]="stateList"
                  [insuranceTypeList]="insuranceTypeList"
                  [isRequiredInformation]="false"
                  [insuranceCarrierGroupList]="insuranceCarrierGroupList"
                  [addInsuranceCarrierDetail]="addInsuranceCarrierDetail"
                  [carrierCategoryList]="carrierCategoryList"
                  (closeModal)="closePatientInsuranceModal()"
                  (notifyAfterNewAddInsuranceGroup)="getAllInsuranceCarrierGroup($event)"
                ></app-insurance-carrier-create-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<button
  id="openPatientInsuranceUpdateModalButton"
  type="button"
  [hidden]="true"
  data-toggle="modal"
  data-target="#patientInsuranceInformationModal"
  data-backdrop="static"
>
  Update Patient Insurance
</button>
