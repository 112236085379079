export class SuccessMsg {
  public static entityCreate = 'Entity created successfully';
  public static entityDelete = 'Entity deleted successfully';
  public static entityUpdate = 'Entity updated successfully';

  public static companyDataUpdate = 'Company Information updated successfully';

  public static callRegCreate = 'Call region created successfully';
  public static callRegDelete = 'Call region deleted successfully';
  public static callRegUpdate = 'Call region updated successfully';

  public static regCreate = 'Region created successfully';
  public static regUpdate = 'Region updated successfully';

  public static hospitalCreate = 'Hospital created successfully';
  public static hospitalDelete = 'Hospital deleted successfully';
  public static hospitalUpdate = 'Hospital updated successfully';

  public static systemCreate = 'System created successfully';
  public static systemDelete = 'System deleted successfully';
  public static systemUpdate = 'System updated successfully';

  public static practiceCreate = 'Practice created successfully';
  public static practiceDelete = 'Practice deleted successfully';
  public static practiceUpdate = 'Practice updated successfully';

  public static userCreate = 'User created successfully';
  public static userUpdate = 'User updated successfully';
  public static userPasswordUpdate = 'User Password updated successfully';

  public static userGroupCreate = 'User group created successfully';
  public static userGroupUpdate = 'User group updated successfully';

  public static permissionUpdate = 'Permissions updated successfully';

  public static deleteHospitalFromCallReg = 'Hospital removed successfully from call-region';
  public static addHospitalToCallReg = 'Hospital added successfully in call-region';
  public static deleteUserFromCallReg = 'User removed successfully from call-region';
  public static addUserToCallReg = 'User added successfully in call-region';

  public static deleteHospitalFromReg = 'Hospital removed successfully from region';
  public static addHospitalToReg = 'Hospital added successfully in region';
  public static deleteTechFromReg = 'Tech removed successfully from region';
  public static addTechToReg = 'Tech added successfully in region';

  public static deleteHospitalFromSystem = 'Hospital removed successfully from system';
  public static addHospitalToSystem = 'Hospital added successfully in system';

  public static deleteUserFromGroup = 'User removed successfully from user-group';
  public static addUserToGroup = 'User added successfully in user-group';

  public static operatingRoomCreate = 'Operating Room created successfully';
  public static operatingRoomDelete = 'Operating Room deleted successfully';
  public static operatingRoomUpdate = 'Operating Room updated successfully';

  public static operatingRoomAddedInHospital = 'Operating Room Added successfully in Hospital';

  public static hospitalPrivilegedAdded = 'Privileged hospital successfully Added to user';
  public static hospitalPrivilegedDeleted = 'Privileged hospital successfully removed from user';

  public static hospitalExcludedAdded = 'Excluded hospital successfully Added to user';
  public static hospitalExcludeedDeleted = 'Excluded hospital successfully removed from user';

  public static SurgeonExcludedAdded = 'Excluded surgeon successfully Added to user';
  public static SurgeonExcludedDeleted = 'Excluded surgeon successfully removed from user';

  public static checkListTaskToHospital = 'CheckListTask Added successfully in Hospital';
  public static checkListTaskDeleteFromHospital = 'CheckListTask Deleted successfully From Hospital';
  public static checkListTaskUpdate = 'CheckListTask Updated Successfully';

  public static vendorCreate = 'Vendor created successfully';
  public static vendorDelete = 'Vendor deleted successfully';
  public static vendorUpdate = 'Vendor updated successfully';

  public static partCreate = 'Part created successfully';
  public static partDelete = 'Part deleted successfully';
  public static partUpdate = 'Part updated successfully';
  public static practiceAddToHospital = 'Practice added successfully to the Hospital';
  public static practiceRemoveToHospital = 'Practice removed successfully from the Hospital';

  public static doctorCreate = 'Doctor created successfully';
  public static doctorDelete = 'Doctor deleted successfully';
  public static doctorUpdate = 'Doctor updated successfully';

  public static doctorAddToPractice = 'Doctor added successfully to the Practice';
  public static doctorRemoveFromPractice = 'Doctor removed successfully from the Practice';

  public static locationCreate = 'Location created successfully';
  public static locationDelete = 'Location deleted successfully';
  public static locationUpdate = 'Location updated successfully';

  public static locationAddToPractice = 'Location added successfully to the Practice';
  public static locationRemoveFromPractice = 'Location removed successfully from the Practice';

  public static hospitalAddToPractice = 'Hospital added successfully to the Practice';
  public static hospitalRemoveFromPractice = 'Hospital removed successfully from the Practice';

  public static schedularCreate = 'Schedular created successfully';
  public static schedularDelete = 'Schedular deleted successfully';
  public static schedularUpdate = 'Schedular updated successfully';
  public static patientCreate = 'Patient Created successfully';
  public static patientDelete = 'Patient Deleted successfully';
  public static patientUpdate = 'Patient Updated successfully';

  public static insuranceCarrierCreate = 'Insurance Carrier Created successfully';
  public static insuranceCarrierUpdate = 'Insurance Carrier Updated successfully';

  public static attorneyCreate = 'Attorney created successfully';
  public static attorneyUpdate = 'Attorney updated successfully';

  public static hospitalSchedularCreate = 'Hospital Schedular created successfully';
  public static hospitalSchedularDelete = 'Hospital Schedular deleted successfully';
  public static hospitalSchedularUpdate = 'Hospital Schedular updated successfully';

  public static caseCreate = 'Case Created successfully';
  public static caseDelete = 'Case Deleted successfully';
  public static caseUpdate = 'Case Updated successfully';

  public static ptoUpdate = 'Pto Updated successfully';

  public static priorityUpdateOncall = 'Tech priority Updated successfully';

  public static caseProcedureUpdated = 'Case Procedure Information Updated Successfully';

  public static patientInTimeUpdated = 'Invoice Start Time Updated Successfully';
  public static patientOutTimeUpdated = 'Invoice Stop Time Updated Successfully';
  public static patientInOutTimeUpdated = 'Invoice Start/Stop Time Updated Successfully';

  public static iomStartTimeUpdated = 'Data Start Time Updated Successfully';
  public static iomStopTimeUpdated = 'Data Stop Time Updated Successfully';
  public static iomStartStopTimeUpdated = 'Data Start/Stop Time Updated Successfully';

  public static caseMonitoringSupplyUpdated = 'Case Monitoring Supplies Updated';
  public static caseMonitoringSupplyRemoved = 'Case Monitoring Supplies Removed';
  public static fileUploaded = 'Files uploaded Successfully';
  public static fileUploadedTagSelect = 'Please select all tags !';

  public static caseStatusUpdated = 'Case Status Updated Successfully';

  public static travelTimeUpdated = 'Travel Time Updated Successfully';
  public static delayTimeUpdated = 'Delay TimeUpdated Successfully';

  public static caseChangedModalityUpdated = 'Singificant Cahnge in Modality Information Updated Successfully';
  public static billingDetailSave = 'Billing detail saved Successfully';

  public static techAssign = 'Tech Assign Successfully';
  public static traineeTechAssign = 'Trainee Tech Assign Successfully';

  public static diagnosisCodeUpdated = 'Diagnosis Code Updated Successfully';

  public static reasonSubmitted = 'Document reasons Updated Successfully';
  public static purchaseOrderCreate = 'Purchase Order created successfully';
  public static purchaseOrderUpdate = 'Purchase Order updated successfully';

  public static invoiceDetailoUpdate = 'Invoice Details updated successfully';

  public static procedureInfoUpdate = 'Procedure Information updated successfully';
  public static contractDetailsCreate = 'Contract Details created successfully';
  public static contractDetailsUpdate = 'Contract Details updated successfully';

  public static teamLeadComplete = 'Team Lead completed successfully';

  public static patientInsuranceDelete = 'This insurance is Attached with another case. you can not delete this insurance';

  public static duplicatePolicyNumber = 'This Policy Number is Already assigned with patient insurance';
  public static multipleTag = `Can't upload multiple tags`;
  public static caseCreateFileFailed =
    'Case created successfully but error in file attached with case you can try manually in caseDetail page';

  public static firstTimeLogin = 'Your Password is Temporary. You must change the password now in order to log into the system';
}

export class AlertWarningMessage {
  public static updateInsuranceWarningMessage = 'This Insurance is attached with another case';
  public static updateWarningText = 'Are you sure you want to update it ?';
  public static updateWarningButton = 'Yes, Update it.';
  public static contactAnmTeam = 'Please <a href="mailto:support@thesunflowerlab.com">Contact ZinniaX Team.</a>';
  public static createCaseTodayDateAlert = "You can not create case for today's or past date." + AlertWarningMessage.contactAnmTeam;
  public static updateCaseTodayDateAlert = "You can not update date or time for today's case." + AlertWarningMessage.contactAnmTeam;
  public static unassignHospital = "You don't have any assigned hospitals. Please contact administrator for hospital assignment.";
  public static FillForm = 'Please fill all required fields.';
  public static oneInsuranceRequired = 'One insurance is required for case';
}
