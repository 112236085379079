import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-outside-layout',
  templateUrl: './outside-layout.component.html',
  styleUrls: ['./outside-layout.component.css']
})
export class OutsideLayoutComponent {

  today = Date.now();
  envText = environment.env;

}
