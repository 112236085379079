<body class="white-bg login_grid">
  <div class="login_section1 login__aside login_aside">
    <div class="grid__item">
      <a href="javascript:;" class="login__logo">
        <img src="../../../assets/img/logo/Zinniax-Logo-White.png" alt="">
      </a>
      <div class="env__title" *ngIf="envText !== 'prod'">
          <strong>{{envText}} Environment</strong>
        </div>
    </div>
    <div class="grid_item_title">
      <div class="grid__item--middle">
        <h3 class="login__title">Welcome to ZinniaX!</h3>
      </div>
    </div>
    <div class="copyright">
      © {{today|date:'yyyy'}} ZinniaX LLC
    </div>
  </div>
  <div class="login_section2">
    <div class="login__body">
      <router-outlet></router-outlet>
    </div>
  </div>
</body>