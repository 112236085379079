<form
  class="form-horizontal"
  #addInsuranceCarrierForm="ngForm"
  (ngSubmit)="addInsuranceCarrierForm?.form?.valid && addInsuranceCarrier(addInsuranceCarrierForm)"
>
  <div class="modal-body">
    <div class="col-12">
      <div class="">
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ patientInsuranceMessage?.carrierGroup }}
              <small class="mandatory"></small>
            </label>
            <div class="col-8 myAutoComplete">
              <input
                [(ngModel)]="addInsuranceCarrierDetail.insuranceCarrierGroupName"
                [typeahead]="insuranceCarrierGroupNameList"
                [typeaheadScrollable]="true"
                [typeaheadOptionsInScrollableView]="typeAheadDropDownLimit"
                autocomplete="off"
                name="insuranceCarrierGroup"
                #insuranceCarrierGroup="ngModel"
                class="form-control"
                required
              />
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="insuranceCarrierGroup"
                fieldName="Carrier Group"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ insuranceCarrierMessage?.planName }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="insuranceName"
                #insuranceName="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.insuranceName"
                [required]="isRequiredInformation"
              />
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="insuranceName"
                fieldName="{{ insuranceCarrierMessage?.planName }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ insuranceCarrierMessage?.carrierNumber }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="carrierNumber"
                #carrierNumber="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.carrierNumber"
                [required]="isRequiredInformation"
              />
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="carrierNumber"
                fieldName="{{ insuranceCarrierMessage?.carrierNumber }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ insuranceCarrierMessage?.phone1 }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="phoneNumber1"
                #phoneNumber1="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.phone1"
                mask="(000) 000-0000"
                maxLength="14"
                [required]="isRequiredInformation"
                appIsNumber
              />
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="phoneNumber1"
                fieldName="{{ insuranceCarrierMessage?.phone1 }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right">{{ insuranceCarrierMessage?.phone2 }} </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="phoneNumber2"
                #phoneNumber2="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.phone2"
                mask="(000) 000-0000"
                maxLength="14"
                appIsNumber
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right">{{ insuranceCarrierMessage?.fax }} </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="fax"
                #fax="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.fax"
                mask="(000) 000-0000"
                maxLength="14"
                appIsNumber
              />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right">{{ insuranceCarrierMessage?.web }} </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="vendorWeb"
                #vendorWeb="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.web"
                appWebValidate
              />
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="vendorWeb"
                fieldName="{{ insuranceCarrierMessage?.web }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ insuranceCarrierMessage?.address1 }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="address1"
                #address1="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.address1"
                [required]="isRequiredInformation"
              />
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="address1"
                fieldName="{{ insuranceCarrierMessage?.address1 }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right">{{ insuranceCarrierMessage?.address2 }}</label>
            <div class="col-8">
              <input type="text" class="form-control" name="address2" [(ngModel)]="addInsuranceCarrierDetail.address2" />
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ insuranceCarrierMessage?.city }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="city"
                #city="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.city"
                [required]="isRequiredInformation"
              />
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="city"
                fieldName="{{ insuranceCarrierMessage?.city }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ insuranceCarrierMessage?.state }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <ng-select
                [items]="stateList"
                bindLabel="name"
                bindValue="id"
                (change)="onSelectState($event)"
                (clear)="onDeSelectState()"
                name="state"
                #state="ngModel"
                placeholder="{{ selectState }}"
                [(ngModel)]="addInsuranceCarrierDetail.stateId"
                [required]="isRequiredInformation"
              >
              </ng-select>
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="state"
                fieldName="{{ insuranceCarrierMessage?.state }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ insuranceCarrierMessage?.postalCode }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <input
                type="text"
                class="form-control"
                name="postal"
                #postalInput="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.postalCode"
                mask="{{ zipCode.masking }}"
                maxlength="zipCode.length"
                [required]="isRequiredInformation"
                appIsNumber
              />
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="postalInput"
                fieldName="{{ insuranceCarrierMessage?.postalCode }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ patientInsuranceMessage?.payorType }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <ng-select
                [items]="insuranceTypeList"
                bindLabel="name"
                bindValue="id"
                (change)="onSelectInsuranceType($event)"
                (clear)="onDeSelectInsuranceType()"
                name="insuranceType"
                placeholder="{{ selectInsuranceType }}"
                #insuranceType="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.insuranceTypeId"
                [required]="isRequiredInformation"
              >
              </ng-select>
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="insuranceType"
                fieldName="{{ patientInsuranceMessage?.payorType }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="row">
            <label class="col-3 col-form-label text-right"
              >{{ insuranceCarrierMessage?.carrierCategory }}
              <small class="mandatory" *ngIf="isRequiredInformation"></small>
            </label>
            <div class="col-8">
              <ng-select
                [items]="carrierCategoryList"
                bindLabel="name"
                bindValue="id"
                (change)="onSelectCarrierCategory($event)"
                (clear)="onDeSelectCarrierCategory()"
                name="carrierCategory"
                placeholder="{{ selectCarrierCategory }}"
                #carrierCategory="ngModel"
                [(ngModel)]="addInsuranceCarrierDetail.carrierCategoryId"
                [required]="isRequiredInformation"
              >
              </ng-select>
              <app-err-msg
                [isFormSubmitted]="addInsuranceCarrierForm?.submitted"
                [control]="carrierCategory"
                fieldName="{{ insuranceCarrierMessage?.carrierCategory }}"
              ></app-err-msg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-success">{{ saveButton }}</button>
    <button
      type="button"
      id="cancelButton"
      class="btn btn-default"
      data-toggle="modal"
      data-dismiss="modal"
      (click)="closeAddInsuranceCarrierModal()"
    >
      {{ cancelButton }}
    </button>
  </div>
</form>
