<div class="modal-dialog" role="sidebar">
  <div class="modal-content modal-bg-white">
    <div class="modal-header">
      <h4 class="modal-title text-navy">
        <strong>Notifications </strong><span class="badge label-primary">{{ notificationPage?.unReadCount }}</span>
      </h4>
      <div *ngIf="notificationPage?.notificationList?.length" class="float-right ml-auto mt-1">
        <a (click)="markAllUserNotificationAsRead()" data-toggle="tooltip" data-placement="bottom" title="Mark all as read">
          <img src="../../../assets/img/markAsRead.svg" alt="" />
        </a>
      </div>
      <button type="button" class="close ml-0" data-toggle="modal" data-dismiss="modal">&times;</button>
    </div>
    <div class="modal-body no-padding-space">
      <div class="wrapper wrapper-content myWrapper">
        <div class="ibox float-e-margins m-b-0" id="notificationIbox">
          <div class="ibox-content no-padding-space">
            <app-spinner></app-spinner>
            <div class="list">
              <div
                class="white-bg p-t-10 p-b-10 list-item"
                *ngFor="let notification of notificationPage?.notificationList"
                [ngClass]="{ 'notification-left-border': !notification.isRead }"
              >
                <a
                  (click)="updateUserNotificationAsRead(notification)"
                  [ngClass]="{ 'unread-notification': !notification?.isRead }"
                  data-toggle="modal"
                  data-dismiss="modal"
                  class="col-sm-12 p-l-10 p-r-8"
                >
                  <div class="row">
                    <div class="col-lg-8 col-sm-8 no-padding-space">
                      <div class="list-header active">{{ notification?.subject }}</div>
                    </div>
                    <div class="col-lg-4 col-sm-4 p-r-0">
                      <em
                        ><div class="float-right list-sub-header2 flex-v-align-center text-muted small font-normal">
                          {{ notification?.createdDate | timeAgo }}
                        </div>
                      </em>
                    </div>
                    <div class="col-sm-12 p-l-0">
                      <div class="list-sub-header active">{{ notification?.body }}</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              *ngIf="
                notificationPage?.isMoreNotification && notificationPage?.notificationList && notificationPage?.notificationList?.length
              "
              class="text-center p-5 m-t-md"
            >
              <button class="t-c btn btn-sm btn-gray w-100" (click)="getNotifications($event)">{{ loadMoreButton }}</button>
            </div>
            <div *ngIf="!notificationPage?.notificationList?.length" class="text-center">
              <a>
                <div>No Notification Found.</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
