export class Permission {
  public static add_user = 'Add User';
  public static update_user = 'Update User';
  public static delete_user = 'Delete User';
  public static view_user = 'View Users';
  public static view_userDetail = 'View User Details';

  public static add_privilege_hospitals = 'Add Privilege Hospitals';
  public static add_exclude_hospitals = 'Add Exclude Hospitals';
  public static add_exclude_surgeons = 'Add Exclude Surgeons';
  public static delete_privilege_hospitals = 'Delete Privilege Hospital';
  public static delete_exclude_hospitals = 'Delete Exclude Hospital';
  public static delete_exclude_surgeons = 'Delete Exclude Surgeon';

  public static add_userGroup = 'Add UserGroup';
  public static update_userGroup = 'Update UserGroup';
  public static delete_user_from_userGroup = 'Delete User From UserGroup';
  public static update_permission = 'Update Permission';
  public static view_userGroup = 'View UserGroups';
  public static view_userGroupDetail = 'View UserGroup Details';

  public static add_vendor = 'Add Vendor';
  public static update_vendor = 'Update Vendor';
  public static delete_vendor = 'Delete Vendor';
  public static view_vendor = 'View Vendors';

  public static add_part = 'Add Part';
  public static update_part = 'Update Part';
  public static delete_part = 'Delete Part';
  public static view_part = 'View Parts';

  public static add_system = 'Add System';
  public static update_system = 'Update System';
  public static delete_system = 'Delete System';
  public static view_system = 'View Systems';
  public static add_hospital_to_system = 'Add Hospital To System';
  public static delete_hospital_from_system = 'Delete Hospital From System';
  public static view_systemDetail = 'View System Details';

  public static add_hospital = 'Add Hospital';
  public static update_hospital = 'Update Hospital';
  public static delete_hospital = 'Delete Hospital';
  public static view_hospital = 'View Hospitals';
  public static add_operating_room_to_hospital = 'Add Operation Room To Hospital';
  public static add_checklist_to_hospital = 'Add Checklist To Hospital';
  public static add_external_schedular_to_hospital = 'Add External Scheduler To Hospital';
  public static add_practice_to_hospital = 'Add Practice To Hospital';
  public static delete_operating_room_from_hospital = 'Delete Operating Room From Hospital';
  public static delete_checklist_from_hospital = 'Delete Checklist From Hospital';
  public static delete_external_schedular_from_hospital = 'Delete External Scheduler From Hospital';
  public static delete_practice_from_hospital = 'Delete Practice From Hospital';
  public static view_hospitalDetail = 'View Hospital Details';

  public static update_operating_room = 'Update Operating Room';
  public static update_checklist = 'Update CheckList';
  public static update_hospital_schedular = 'Update Hospital Scheduler';

  public static add_practice = 'Add Practice';
  public static update_practice = 'Update Practice';
  public static view_practice = 'View Practices';
  public static add_location_to_practice = 'Add Location To Practice';
  public static add_surgeon_to_practice = 'Add Surgeon To Practice';
  public static add_external_schedular_to_practice = 'Add External Scheduler To Practice';
  public static add_hospital_to_practice = 'Add Hospital To Practice';
  public static view_practiceDetail = 'View Practice Details';

  public static update_external_schedular = 'Update External Scheduler';

  public static add_surgeon = 'Add Surgeon';
  public static update_surgeon = 'Update Surgeon';
  public static delete_surgeon = 'Delete Surgeon';
  public static view_surgeon = 'View Surgeons';

  public static add_patient = 'Add Patient';
  public static update_patient = 'Update Patient';
  public static delete_patient = 'Delete Patient';
  public static view_patient = 'View Patients';
  public static view_patientDetail = 'View Patient Details';

  public static add_region = 'Add Region';
  public static update_region = 'Update Region';
  public static view_region = 'View Regions';
  public static view_regionDetail = 'View Region Details';
  public static delete_hospital_from_region = 'Delete Hospital From Region';
  public static delete_tech_from_region = 'Delete Tech From Region';

  public static add_callRegion = 'Add CallRegion';
  public static update_callRegion = 'Update CallRegion';
  public static delete_callRegion = 'Delete CallRegion';
  public static view_callRegion = 'View CallRegions';
  public static view_callRegionDetail = 'View CallRegion Details';
  public static add_hospital_to_callRegion = 'Add Hospital To CallRegion';
  public static add_tech_to_callRegion = 'Add Tech To CallRegion';
  public static delete_hospital_from_callRegion = 'Delete Hospital From CallRegion';
  public static delete_tech_from_callRegion = 'Delete Tech From CallRegion';

  public static update_companyInfo = 'Update Company Details';
  public static view_companyInfo = 'View Company Details';

  public static add_entity = 'Add Entity';
  public static view_entity = 'View Entities';

  public static add_case = 'Add Case';
  public static update_case = 'Update Case';
  public static delete_case = 'Delete Case';
  public static view_case = 'View Cases';
  public static view_caseDetail = 'View Case Details';

  public static add_attorney = 'Add Attorney';
  public static update_attorney = 'Update Attorney';
  public static view_attorney = 'View Attorneys';

  public static view_schedularDashboard = 'View Scheduler Dashboard';
  public static view_billingDashboard = 'View Billing Dashboard';

  public static view_tech_report = 'View Tech Report';
  public static add_billing_detail = 'Add Billing Detail';
  public static tentative_case_schedule = 'Tentative Case Scheduler';

  public static tech_complete = 'Tech Complete';
  public static surgeon_call = 'Surgeon Call';
  public static hospital_call = 'Hospital Call';
}
