import { CommonModule, DatePipe } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTableModule } from '@pascalhonegger/ng-datatable';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { DpDatePickerModule } from 'ng2-date-picker';
import { DndModule } from 'ng2-dnd';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TagInputModule } from 'ngx-chips';
import { NgxMaskModule } from 'ngx-mask';
import { GetDurationOfProcedurePipe } from '../shared/pipes/get-duration-of-procedure.pipe';
import { MakeDurationOfProcedurePipe } from '../shared/pipes/make-duration-of-procedure.pipe';
import { SharedModule } from '../shared/shared.module';
import { CaseCreateComponent } from './case-create/case-create.component';
import { CaseFileUploadComponent } from './case-file-upload/case-file-upload.component';
import { CaseListComponent } from './case-list/case-list.component';
import { CaseManagementRoutingModule } from './case-management.route';
import { CaseManagementService } from './case-management.service';
import { DiagnosisCodeComponent } from './diagnosis-code/diagnosis-code.component';
import { InsuranceCarrierCreateModalComponent } from './insurance-carrier-create-modal/insurance-carrier-create-modal.component';
import { PatientInsuranceCarrierTabComponent } from './patient-insurance-carrier-tab/patient-insurance-carrier-tab.component';
import { PatientInsuranceListComponent } from './patient-insurance-list/patient-insurance-list.component';
import { PrimaryInsuranceCreateComponent } from './primary-insurance-create/primary-insurance-create.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DataTableModule,
    ReactiveFormsModule,
    CaseManagementRoutingModule,
    SharedModule,
    NgxMaskModule.forRoot(),
    BsDatepickerModule,
    AngularMultiSelectModule,
    TagInputModule,
    BrowserAnimationsModule,
    DpDatePickerModule,
    PaginationModule.forRoot(),
    NgSelectModule,
    TypeaheadModule.forRoot(),
    DndModule.forRoot()
  ],
  declarations: [
    CaseListComponent,
    CaseCreateComponent,
    DiagnosisCodeComponent,
    PatientInsuranceListComponent,
    CaseFileUploadComponent,
    PatientInsuranceCarrierTabComponent,
    PrimaryInsuranceCreateComponent,
    InsuranceCarrierCreateModalComponent
  ],
  providers: [DatePipe, CaseManagementService, GetDurationOfProcedurePipe, MakeDurationOfProcedurePipe],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CaseManagementModule {}
