import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { enGbLocale } from 'ngx-bootstrap/locale';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ToastrModule } from 'ngx-toastr';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChangePasswordComponent } from './authentication/change-password/change-password.component';
import { TutorialComponent } from './authentication/tutorial/tutorial.component';
import { CaseManagementModule } from './case-management/case-management.module';
import { AttorneyHeaderComponent } from './layouts/attorney-layout/attorney-header.component';
import { AttorneyLayoutComponent } from './layouts/attorney-layout/attorney-layout.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { NotificationSidepanelComponent } from './layouts/notification-sidepanel-layout/notification-sidepanel.component';
import { OutsideLayoutComponent } from './layouts/outside-layout/outside-layout.component';
import { TopNavbarComponent } from './layouts/top-navbar/top-navbar.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';
import { AppInterceptor } from './shared/app-intercepter';
import { TimeAgoPipe } from './shared/pipes/time-ago.pipe';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    TopNavbarComponent,
    WelcomeLayoutComponent,
    AttorneyLayoutComponent,
    AttorneyHeaderComponent,
    OutsideLayoutComponent,
    ChangePasswordComponent,
    TutorialComponent,
    NotificationSidepanelComponent,
    TimeAgoPipe
  ],
  imports: [
    BrowserModule,
    FormsModule,
    NgxWebstorageModule.forRoot(),
    BrowserAnimationsModule,
    CarouselModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      progressBar: true
    }),
    BsDatepickerModule.forRoot(),
    DpDatePickerModule,
    PopoverModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    CaseManagementModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private localeService: BsLocaleService) {
    enGbLocale.week.dow = 1;
    defineLocale('en-gb', enGbLocale);
    this.localeService.use('en-gb');
  }
}
