import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs/Subscription';
import { CancelCaseStatus, caseStatus, LocationSelect, PracticeSelect, ProcedureMultiSelect } from '../../shared/constants/common.constant';
import { Button, DatePickerConstant } from '../../shared/constants/message.constants';
import { TableConfig } from '../../shared/constants/pagination.constants';
import { Permission } from '../../shared/constants/permission.constant';
import { SharedService } from '../../shared/services/shared.service';
import { UtillService } from '../../shared/services/utill.service';
import { AnmCaseList, AnmCaseProcedureTypes, CaseHospitalDoctor, SearchDTO, SearchFilter } from '../case-management.model';
import { CaseManagementService } from '../case-management.service';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.css']
})
export class CaseListComponent implements OnInit, OnDestroy {
  add_case = Permission.add_case;
  update_case = Permission.update_case;
  headerTitle: string;
  addButton = Button.addCase;
  editButton = Button.update;
  saveButton = Button.save;
  cancelButton = Button.cancel;
  searchButton = Button.search;
  resetButton = Button.reset;
  sortBy = TableConfig.sort_by_dateOfSurgery;
  sortOrder = TableConfig.default_sort_order;
  cases: AnmCaseList[] = [];
  filterstatus: string;
  surgeons: CaseHospitalDoctor[] = [];
  selectedPractice: SearchDTO = new SearchDTO();
  selectedLocation: SearchDTO[] = [];
  procedures: AnmCaseProcedureTypes[] = [];
  selectedProcedures: AnmCaseProcedureTypes[] = [];
  caseStatus = caseStatus;
  surgeon_call = Permission.surgeon_call;
  hospital_call = Permission.hospital_call;
  searchFilter: SearchFilter;
  bsRangeValue: Date[] = [];
  bsConfig: Partial<BsDatepickerConfig> = DatePickerConstant.bsRangeConfig;
  practiceSelect = PracticeSelect;
  locationSelect = LocationSelect;
  procedureMultiSelect = ProcedureMultiSelect;
  subscriptionManager = new Subscription();
  totalItems: number;
  totalPages: number;
  searchText: string;
  fromPage: number;
  toPage: number;
  defaultPage = TableConfig.activePage;
  activePage = TableConfig.activePage;
  itemPerPageOptions: number[] = TableConfig.pageSizeOptions;
  itemsPerPage = this.itemPerPageOptions ? this.itemPerPageOptions[0] : TableConfig.items_per_page;
  showFiltersSection = true;
  practiceList: SearchDTO[] = [];
  practiceId: number;
  locationList = [];

  constructor(private utillService: UtillService, private sharedService: SharedService, private caseService: CaseManagementService) {}

  ngOnInit() {
    this.headerTitle = this.utillService.getHeaderTitle();
    this.searchFilter = new SearchFilter();
    this.searchCaseFilter(this.activePage);
    this.getAllProcedureTypeForCase();
    this.getPractices();
    if (this.getPermission(this.surgeon_call)) {
      this.getPracticeByLocationScheduler();
    }
  }

  getPermission(permission) {
    return this.utillService.getPermissions(permission);
  }

  // Get Practice detail By logged in schduler
  getPracticeByLocationScheduler() {
    this.subscriptionManager.add(
      this.caseService.getPracticeByLocationScheduler().subscribe((res: SearchDTO) => {
        this.practiceId = res.id;
        this.searchFilter.practiceId = +res.id;
        this.getLocationsByPracticeId(res.id);
      })
    );
  }

  // get all Practices
  getPractices() {
    this.sharedService.showLoader();
    this.subscriptionManager.add(
      this.caseService.getPractices().subscribe(res => {
        this.sharedService.removeLoader();
        this.practiceList = res;
      })
    );
  }

  // Get Locations by Practice Id
  getLocationsByPracticeId(id) {
    this.subscriptionManager.add(
      this.caseService.getAllLocationsByPracticeId(id).subscribe(res => {
        this.locationList = res;
      })
    );
  }

  getAllProcedureTypeForCase() {
    this.subscriptionManager.add(
      this.caseService.getAllCompetence().subscribe(res => {
        this.procedures = res;
      })
    );
  }

  searchCaseFilter(activePage) {
    this.sharedService.showLoader();
    this.searchFilter.procedureIds = [];
    this.searchFilter.locationIds = [];
    this.searchFilter.status = [];
    if (this.selectedProcedures.length) {
      this.selectedProcedures.forEach(procedure => {
        this.searchFilter.procedureIds.push(procedure.id);
      });
    }
    if (this.selectedLocation.length) {
      this.selectedLocation.forEach(surgeon => {
        this.searchFilter.locationIds.push(surgeon.id);
      });
    }
    if (this.bsRangeValue && this.bsRangeValue.length) {
      this.searchFilter.fromDate = this.sharedService.getFormatedDate(this.bsRangeValue[0]);
      this.searchFilter.toDate = this.sharedService.getFormatedDate(this.bsRangeValue[1]);
    } else {
      this.searchFilter.fromDate = '';
      this.searchFilter.toDate = '';
    }
    if (this.filterstatus) {
      this.searchFilter.status.push(this.filterstatus);
    }
    this.subscriptionManager.add(
      this.caseService.findAllFilterCases(this.searchFilter, activePage - 1, this.itemsPerPage, this.sortBy, this.sortOrder).subscribe(
        res => {
          this.sharedService.removeLoader();
          this.cases = res.content;
          this.totalItems = res.totalElements;
          this.activePage = activePage;
          this.totalPages = res.totalPages;
          this.pageDataCount(this.activePage, this.itemsPerPage);
        },
        () => {
          this.sharedService.removeLoader();
        }
      )
    );
  }

  resetFilter() {
    this.searchFilter = new SearchFilter();
    this.bsRangeValue = null;
    this.selectedProcedures = [];
    this.searchFilter.procedureIds = [];
    this.selectedLocation = [];
    this.searchFilter.locationIds = [];
    this.filterstatus = '';
    this.searchFilter.practiceId = this.practiceId;
    this.searchCaseFilter(this.activePage);
  }

  handlePaginationEvent(event) {
    if (this.activePage !== event.page) {
      this.searchCaseFilter(event.page);
    }
  }

  pageDataCount(activePage, rowsOnPage) {
    const counts = this.sharedService.dataCount(activePage, rowsOnPage, this.totalItems);
    this.fromPage = counts.from;
    this.toPage = counts.to;
  }

  onSortOrder(event) {
    this.sortOrder = event;
    this.activePage = this.defaultPage;
    this.searchCaseFilter(this.activePage);
  }

  handlePageOptionsChange(itemsPerPage: number) {
    if (itemsPerPage !== this.itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.searchCaseFilter(this.defaultPage);
    }
  }

  closeFiltersSection() {
    this.showFiltersSection = false;
  }

  // Procedure name by procedure id
  getProcedureName(procedureId: number) {
    if (this.procedures && this.procedures.length && procedureId) {
      const competence = this.procedures.find(procedure => procedure.id === Number(procedureId));
      return competence ? competence.name : '';
    }
    return '';
  }

  // Toggle Filter section
  toggleFilters() {
    this.showFiltersSection = !this.showFiltersSection;
  }

  // show cancel case status and cancel case substatus
  getCaseStatus(caseObj) {
    if (caseObj.cancelSubStatus && caseObj.status === CancelCaseStatus) {
      return CancelCaseStatus + '-' + caseObj.cancelSubStatus;
    }
    return caseObj.status;
  }

  ngOnDestroy() {
    if (this.subscriptionManager) {
      this.subscriptionManager.unsubscribe();
    }
  }
}
