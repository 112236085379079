import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from './storage.service';

declare var jQuery: any;

@Injectable()
export class SharedService {
  constructor(private storageService: StorageService, private toastr: ToastrService) {}

  public getUserData(key: string) {
    return this.storageService.getObject('user') ? this.storageService.getObject('user')[key] : null;
  }
  public setUserData(data: object) {
    this.storageService.setObject('user', data);
  }
  public getUserSession(key: string) {
    return this.storageService.getSession('user') ? this.storageService.getSession('user')[key] : null;
  }
  public setUserSession(data: object) {
    this.storageService.setSession('user', data);
  }
  public isLoggedIn() {
    const token = this.getUserData('id_token');
    if (token != null) {
      return true;
    }
    return false;
  }
  public logout() {
    this.storageService.removeObject('user');
    this.storageService.destroySession('user');
  }
  public showLoader() {
    jQuery('#ibox').find('.ibox-content').addClass('sk-loading');
    jQuery('#ibox').find('.ibox-content app-spinner .sk-spinner').addClass('custom-loader');
  }

  public removeLoader() {
    jQuery('#ibox').find('.ibox-content').removeClass('sk-loading');
    jQuery('#ibox').find('.ibox-content app-spinner .sk-spinner').removeClass('custom-loader');
  }

  public closeFileModal(id) {
    jQuery('#' + id).modal('hide');
  }

  public getActiveWizard(eleId) {
    jQuery('#billing-wizard .disableWizardBtn').removeClass('active');
    jQuery('#' + eleId).addClass('active');
  }

  public removeMonitorableClass() {
    jQuery('.monitorableBtn').removeClass('active');
  }

  public addActiveClass(ele, className) {
    jQuery(ele).addClass(className);
  }

  public removeActiveClass(ele, className) {
    jQuery(ele).removeClass(className);
  }

  public showIndividualLoader(id) {
    jQuery('#' + id)
      .find('.ibox-content app-spinner .sk-spinner')
      .addClass('custom-loader');
    jQuery('#' + id)
      .find('.ibox-content')
      .addClass('sk-loading');
  }

  public removeIndividualLoader(id) {
    jQuery('#' + id)
      .find('.ibox-content')
      .removeClass('sk-loading');
    jQuery('#' + id)
      .find('.ibox-content app-spinner .sk-spinner')
      .removeClass('custom-loader');
  }

  public getFormatedDate(date) {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    const yyyy = date.getFullYear().toString();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return mm + '/' + dd + '/' + yyyy;
  }

  public successToaster(msg) {
    this.toastr.success(msg);
  }

  public dangerToaster(msg) {
    this.toastr.error(msg);
  }

  public dangerToasterWithCustomTime(msg, time) {
    this.toastr.error(msg, '', { timeOut: time });
  }

  public dataCount(activePage, rowsOnPage, totalRows) {
    const obj = {
      from: activePage * rowsOnPage - (rowsOnPage - 1),
      to: null
    };
    if (totalRows > activePage * rowsOnPage) {
      obj.to = activePage * rowsOnPage;
      return obj;
    } else {
      obj.to = totalRows;
      return obj;
    }
  }

  public hasDuplicates(array) {
    const valuesSoFar = Object.create(null);
    for (let i = 0; i < array.length; ++i) {
      const value = array[i];
      if (value !== '12') {
        if (value in valuesSoFar) {
          return true;
        }
        valuesSoFar[value] = true;
      }
    }
    return false;
  }

  public showModal(id) {
    jQuery('#' + id).modal({ backdrop: 'static', keyboard: false });
  }
}
