import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { DatePickerConstant } from '../constants/message.constants';
import { monthDateAndHour } from '../constants/common.constant';

@Pipe({
  name: 'tbdTime'
})
export class TbdTimePipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe
  ) { }

  transform(value: Date, isTBD: boolean): any {
    return isTBD ? this.datePipe.transform(value, DatePickerConstant.dateFormat3).concat(', TBD') : this.datePipe.transform(value, monthDateAndHour);
  }

}
