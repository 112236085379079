<body class="white-bg">
  <div class="logo margintop-20 img-responsive middle-box text-center inline-block animated fadeInDown">
    <img src="../../../../assets/img/logo/Zinniax-Logo.png" />
  </div>
  <div class="middle-box text-center loginscreen animated fadeInDown no-padding-space customPage">
    <h2 class="font-size-15em">
      <strong>401</strong>
    </h2>
    <h2>
      <p>
        You are not authorized to view this page.
      </p>
      <p>
        Please contact administrator.
      </p>
    </h2>
    <button type="submit" class="btn btn-success margintop-30" (click)="goToHomePage()">Home</button>
  </div>
</body>
