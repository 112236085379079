import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unauthorise-wall',
  templateUrl: './unauthorise-wall.component.html'
})
export class UnauthoriseWallComponent {
  constructor(public router: Router) {}

  goToHomePage() {
    this.router.navigate(['/case-list']);
  }
}
