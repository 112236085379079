import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'copyright'
})
export class CopyrightPipe implements PipeTransform {

  transform(value: any): any {
    const currentYear = new Date().getFullYear();
    return value === currentYear ? currentYear : value + '-' + currentYear;
  }

}
