import { DatePipe, Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DatePickerConstant } from '../constants/message.constants';
import { StorageService } from './storage.service';

declare var jQuery: any;

@Injectable()
export class UtillService {
  constructor(private location: Location, private router: Router, private datePipe: DatePipe, private storageService: StorageService) {}

  public toggleSidebar() {
    jQuery('#middle').toggleClass('mini-navbar');
    if (!jQuery('#middle').hasClass('mini-navbar') || jQuery('#middle').hasClass('body-small')) {
      jQuery('#side-menu').hide();
      setTimeout(function () {
        jQuery('#side-menu').fadeIn(400);
      }, 200);
    } else if (jQuery('#middle').hasClass('fixed-sidebar')) {
      jQuery('#side-menu').hide();
      setTimeout(function () {
        jQuery('#side-menu').fadeIn(400);
      }, 100);
    } else {
      jQuery('#side-menu').removeAttr('style');
    }
  }

  public activeRoute(routename: string): boolean {
    const routes = routename.split(',');
    let check = false;
    for (let i = 0; i < routes.length; i++) {
      if (this.location.path().indexOf(routes[i]) >= 0) {
        check = true;
        break;
      } else {
        check = false;
        continue;
      }
    }
    return check;
  }

  public getHeaderTitle() {
    let root = this.router.routerState.snapshot.root;
    while (root) {
      if (root.children && root.children.length) {
        root = root.children[0];
      } else if (root.data && root.data['header']) {
        return root.data['header'];
      } else {
        return;
      }
    }
  }

  public getPermissions(permission) {
    const allPermissions = this.storageService.getObject('permissions');
    if (allPermissions) {
      const isPermission = allPermissions.find(permission1 => permission1.name === permission);
      if (isPermission) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public checkCalendarViewSurgeonForDay(doctorId, dateOfSurgery, currentDate) {
    if (
      doctorId !== null &&
      this.datePipe.transform(dateOfSurgery, DatePickerConstant.dateFormate) ===
        this.datePipe.transform(currentDate, DatePickerConstant.dateFormate)
    ) {
      return true;
    } else {
      return false;
    }
  }

  public checkCalendarViewSurgeonForWeek(doctorId, dateOfSurgery, currentDate) {
    if (
      doctorId !== null &&
      this.datePipe.transform(dateOfSurgery, DatePickerConstant.dateFormate) >=
        this.datePipe.transform(
          new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())),
          DatePickerConstant.dateFormate
        ) &&
      this.datePipe.transform(dateOfSurgery, DatePickerConstant.dateFormate) <=
        this.datePipe.transform(
          new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 6)),
          DatePickerConstant.dateFormate
        )
    ) {
      return true;
    } else {
      return false;
    }
  }

  public checkCalendarViewSurgeonForMonth(doctorId, dateOfSurgery, currentDate) {
    if (doctorId !== null && new Date(dateOfSurgery).getMonth() === new Date(currentDate).getMonth()) {
      return true;
    } else {
      return false;
    }
  }

  public async commonWarningAlert(warningMsg, text, confirmMsg) {
    return Swal.fire({
      title: warningMsg,
      icon: 'warning',
      text: text,
      width: 550,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmMsg
    }).then(result => {
      if (result.value) {
        return true;
      } else {
        return false;
      }
    });
  }

  public async overWriteAlert() {
    return Swal.fire({
      title: 'We found the file with same tag. Are you sure you want to update existing file?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, over write it!'
    }).then(result => {
      if (result.value) {
        return true;
      } else {
        return false;
      }
    });
  }

  public async deleteAlert() {
    return Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(result => {
      if (result.value) {
        return true;
      } else {
        return false;
      }
    });
  }

  public commonWarningMessageAlert(message) {
    return Swal.fire({
      title: message,
      icon: 'warning'
    }).then(result => {
      if (result.value) {
        return true;
      }
      return false;
    });
  }

  public timeConvertIntoDate(date, time, aMpM) {
    if (aMpM === null) {
      return new Date(this.datePipe.transform(date, DatePickerConstant.dateFormate) + ' ' + time);
    } else {
      return new Date(this.datePipe.transform(date, DatePickerConstant.dateFormate) + ' ' + time + ' ' + aMpM);
    }
  }

  public reuserRoute(route) {
    route.onSameUrlNavigation = 'reload';
    route.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  checkDateAndTime(date, oldCaseDate) {
    const newDate = new Date(date);
    const currentDate = new Date();
    let oldDate = null;
    if (oldCaseDate) {
      oldDate = new Date(oldCaseDate);
    }

    if (oldDate) {
      if (oldDate.getDate() === currentDate.getDate()) {
        return oldDate.getTime() !== newDate.getTime();
      }
      return (oldDate.getDate() === newDate.getDate() && currentDate.getDate() === newDate.getDate()) || newDate < currentDate;
    }
    return newDate.getDate() === currentDate.getDate() || newDate < currentDate;
  }

  public checkPatientName(firstName: string, lastName: string, backupFirstName: string, backupLastName: string): boolean {
    if (firstName && lastName && (firstName.toLowerCase() !== backupFirstName || lastName.toLocaleLowerCase() !== backupLastName)) {
      return true;
    } else {
      return false;
    }
  }

  public commonMessageAlert(text: string, msgTitle?: string) {
    Swal.fire({
      title: msgTitle,
      text: text
    });
  }
}
