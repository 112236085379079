import { Component, OnInit, Input } from '@angular/core';
import { UtillService } from '../../shared/services/utill.service';
declare var jQuery: any;

@Component({
  selector: 'app-attorney-header',
  templateUrl: './attorney-header.component.html'
})
export class AttorneyHeaderComponent implements OnInit {

  @Input() headerTitle: string;

  constructor(
    private utillService: UtillService
  ) { }

  ngOnInit() {
  }

  toggleSidebar(): void {
    this.utillService.toggleSidebar();
  }

}
